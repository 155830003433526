import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Modal from 'components/shared/slice-modal';
import { useClosings, useGenerateScheduleMutation } from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { remove } from 'utilities/lists';

import styles from './styles.module.scss';

const DeleteModal = ({
  activeClosingId,
  deletingRecord,
  setActiveClosingId,
  setDeletingRecord,
  shopId,
  shopTimezone,
}) => {
  const api = useApi();

  const { trackDeleteClosing } = useAnalytics();

  const { closingsData, invalidateOpenForTodayQueries, setClosingsQueryData } =
    useClosings(shopId);

  const removeFromClosingsQueryData = (id) =>
    setClosingsQueryData((cachedClosingsData = []) =>
      remove(cachedClosingsData, 'id', id),
    );

  const { mutate: generateSchedule } = useGenerateScheduleMutation({
    closings: closingsData,
    shopId,
    shopTimezone,
  });

  const { mutate: handleDeleteSubmit, isLoading: isDeleteInProgress } =
    useMutation(() => api.deleteClosing(shopId, deletingRecord.id), {
      onSuccess: () => {
        removeFromClosingsQueryData(deletingRecord.id);
        trackDeleteClosing(shopId, deletingRecord, true);
        if (activeClosingId === deletingRecord.id) {
          setActiveClosingId(null);
        }
        setDeletingRecord(null);
        // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
        // such as next_open_at which may be impacted by closing updates
        invalidateOpenForTodayQueries();
        generateSchedule();
      },
      onError: () => {
        trackDeleteClosing(shopId, deletingRecord, false);
        setDeletingRecord(null);
        toast.error(
          'An error occurred while deleting this record. Try refreshing!',
        );
      },
    });

  return (
    <Modal
      header="Are you sure?"
      isOpen={deletingRecord ? true : false}
      isYesButtonDisabled={isDeleteInProgress}
      onClickNo={() => setDeletingRecord(null)}
      onClickYes={handleDeleteSubmit}
      onRequestClose={() => setDeletingRecord(null)}
      yesButtonText="Delete"
    >
      <div className={styles.modalContent}>
        {`You are about to delete your closing between ${deletingRecord?.startDate} and
        ${deletingRecord?.endDate}. This action cannot be undone.`}
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  activeClosingId: PropTypes.number,
  deletingRecord: PropTypes.object,
  setActiveClosingId: PropTypes.func.isRequired,
  setDeletingRecord: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

DeleteModal.defaultProps = {
  activeClosingId: null,
  deletingRecord: {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DeleteModal;
