import { CalendarDate } from '@internationalized/date';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ColumnSort } from '@tanstack/react-table';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import {
  RegisterOrderFilterValue,
  RegisterOrdersRequestParams,
  RegisterOrdersResponse,
} from 'types/register-orders';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { toSnakeCase } from 'utilities/strings';

type Props = {
  dates: RangeValue<CalendarDate>;
  page: number;
  perPage: number;
  shopId: string;
  sort: ColumnSort;
  shopTimezone: string;
  orderStatus?: RegisterOrderFilterValue;
};

export const getOrdersQueryKey = (
  shopId: string,
  params: RegisterOrdersRequestParams,
): QueryKey => [shopId, 'register-orders', params];

const parseSort = (sortParams: ColumnSort) => {
  const { id, desc } = sortParams;
  const direction = desc ? 'desc' : 'asc';

  if (id === 'status') {
    return { sortBy: 'payment_status', sortDirection: direction };
  }

  if (id === 'customerName') {
    return { sortBy: 'first_name', sortDirection: direction };
  }

  return { sortBy: toSnakeCase(id), sortDirection: direction };
};

export const useRegisterOrdersQuery = ({
  dates,
  page,
  perPage,
  shopId,
  shopTimezone,
  sort,
  orderStatus,
}: Props): UseQueryResult<RegisterOrdersResponse> => {
  const api = useApi();

  const params = {
    start: toStartOfDateAbsoluteString(dates.start, shopTimezone),
    finish: toEndOfDateAbsoluteString(dates.end, shopTimezone),
    orderStatus:
      orderStatus === RegisterOrderFilterValue.All ? undefined : orderStatus,
    page,
    perPage,
    ...parseSort(sort),
  };

  return useQuery({
    queryKey: getOrdersQueryKey(shopId, params),
    queryFn: () => api.getRegisterOrders(shopId, params),
  });
};
