import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerList,
  CustomerSort,
  CustomersResponse,
  CustomerType,
  DateRange,
} from 'types/customers';
import { getInterval } from 'utilities/customers';

type UseTop10CustomersQueryOptions = Omit<
  UseQueryOptions<CustomersResponse>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
  options?: UseTop10CustomersQueryOptions;
  page: number;
  perPage: number;
  sort: CustomerSort;
  customerUuids?: string[];
  userNameSearch?: string;
};

export const getCustomersQueryKey = ({
  customerList,
  customerType,
  dateRange,
  page,
  perPage,
  shopId,
  sort,
  customerUuids,
  userNameSearch,
}: Omit<Props, 'timezone' | 'options'>): QueryKey => [
  shopId,
  'customers-v1',
  customerList,
  dateRange,
  customerType,
  page,
  perPage,
  sort,
  customerUuids,
  userNameSearch,
];

const useCustomersQuery = ({
  customerList,
  customerType,
  customerUuids = [],
  dateRange,
  page,
  perPage,
  shopId,
  timezone,
  options,
  sort,
  userNameSearch,
}: Props): UseQueryResult<CustomersResponse> => {
  const api = useApi();

  return useQuery({
    queryKey: getCustomersQueryKey({
      customerList,
      customerType,
      dateRange,
      page,
      perPage,
      shopId,
      sort,
      userNameSearch,
    }),
    queryFn: () =>
      api.getCustomers(
        shopId,
        page,
        perPage,
        sort,
        getInterval(dateRange, timezone),
        customerType,
        customerList,
        customerUuids,
        userNameSearch,
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomersQuery;
