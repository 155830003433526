/* eslint-disable simple-import-sort/imports -- These imports have side-effects
 * and need to execute first. */

// Make sure vendor CSS is bunldled first so it has lower priority.
import 'react-toastify/dist/ReactToastify.css';
import '@szhsin/react-menu/dist/index.css';

// Our global CSS should also have lower precedence than component styles.
import './index.css';

/* eslint-enable simple-import-sort/imports */

import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { setLogLevel } from '@optimizely/react-sdk';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { QueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import { App, AppHarness } from 'components/app';
import ConsoleAnalyticsAdapter from 'providers/analytics/console-analytics-adapter';
import { NetworkError } from 'providers/api/helpers';

const {
  MODE,
  PROD,
  VITE_AUTH0_AUDIENCE,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_DOMAIN,
  VITE_DATADOG_LOG_TOKEN,
  VITE_DATADOG_RUM_ID,
  VITE_DATADOG_RUM_TOKEN,
  VITE_DATADOG_SERVICE,
  VITE_DATADOG_SITE,
  VITE_DATADOG_VERSION,
  VITE_RESTAURANT_API_HOST,
  VITE_SEGMENT_API_TOKEN,
  VITE_STRIPE_PUBLISHABLE_KEY,
} = import.meta.env;

let analyticsAdapter;

if (PROD) {
  datadogLogs.init({
    clientToken: VITE_DATADOG_LOG_TOKEN,
    env: MODE,
    forwardErrorsToLogs: true,
    service: VITE_DATADOG_SERVICE,
    sessionSampleRate: 100,
    site: VITE_DATADOG_SITE,
    version: VITE_DATADOG_VERSION,
  });

  datadogRum.init({
    applicationId: VITE_DATADOG_RUM_ID,
    clientToken: VITE_DATADOG_RUM_TOKEN,
    env: MODE,
    service: VITE_DATADOG_SERVICE,
    site: VITE_DATADOG_SITE,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    version: VITE_DATADOG_VERSION,
  });

  setLogLevel('error');
  analyticsAdapter = AnalyticsBrowser.load({
    writeKey: VITE_SEGMENT_API_TOKEN,
  });
} else {
  setLogLevel('warn');
  analyticsAdapter = new ConsoleAnalyticsAdapter();
}

moment.tz.setDefault('Etc/UTC');

const appRoot = document.getElementById('root');

// Allow react-modal to add aria-hidden="true" to the app content while a modal
// a modal is open.
// https://reactcommunity.org/react-modal/accessibility/#app-element
ReactModal.setAppElement('#root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Only retry if there is a network error. Retrying when the result is a
      // 4xx or 5xx is probably never going to change the outcome.
      retry: (failureCount, error) =>
        error instanceof NetworkError && failureCount < 3,
    },
  },
});

if (appRoot) {
  ReactDOM.render(
    <AppHarness
      analyticsAdapter={analyticsAdapter}
      apiHostname={VITE_RESTAURANT_API_HOST}
      auth0Audience={VITE_AUTH0_AUDIENCE}
      auth0ClientId={VITE_AUTH0_CLIENT_ID}
      auth0Domain={VITE_AUTH0_DOMAIN}
      queryClient={queryClient}
      stripeKey={VITE_STRIPE_PUBLISHABLE_KEY}
    >
      <App />
    </AppHarness>,
    appRoot,
  );
}
