import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  MenuProductResponseBody,
  MenuProductsResponseBody,
  MenuResponseBody,
} from 'types/menu/api';
import { MenuProduct, MenuProductType } from 'types/menu/product';
import { Shop } from 'types/shops/shop';
import { getFutureUtcDateTime } from 'utilities/date-time';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';
import { getProductQueryKey } from './use-product-query';

const updateCachedProduct = (
  current: MenuProductResponseBody,
  data: MenuProductResponseBody,
) => {
  const next = structuredClone(current);

  next.product.unavailable = data.product.unavailable;
  next.product.unavailableUntil = data.product.unavailableUntil;

  if (data.relationships.productTypes) {
    for (const type of data.relationships.productTypes) {
      const match = next.relationships.productTypes?.find(
        (it) => it.id === type.id,
      );

      if (match) {
        match.unavailable = type.unavailable;
        match.unavailableUntil = type.unavailableUntil;
      }
    }
  }

  return next;
};

const updateCachedMenu = (
  current: MenuProductsResponseBody,
  data: MenuProductResponseBody,
) => {
  const next = structuredClone(current);
  const product = next.products.find((it) => it.id === data.product.id);

  if (product) {
    product.unavailable = data.product.unavailable;
    product.unavailableUntil = data.product.unavailableUntil;

    for (const type of product.types) {
      const match = data.relationships.productTypes?.find(
        (it) => it.id === type.id,
      );

      if (match) {
        type.unavailable = match.unavailable;
        type.unavailableUntil = match.unavailableUntil;
      }
    }
  }

  return next;
};

const updateLegacyCachedMenu = (
  current: MenuResponseBody,
  data: MenuProductResponseBody,
) => {
  const next = structuredClone(current);
  const product = next.relationships.products?.find(
    (it) => it.id === data.product.id,
  );
  const productTypes = next.relationships.productTypes || [];

  if (product) {
    product.unavailable = data.product.unavailable;
    product.unavailableUntil = data.product.unavailableUntil;

    for (const productType of productTypes) {
      const match = data.relationships.productTypes?.find(
        (it) => it.id === productType.id,
      );

      if (match) {
        productType.unavailable = match.unavailable;
        productType.unavailableUntil = match.unavailableUntil;
      }
    }
  }

  return next;
};

const getAvailability = (duration: number) => ({
  unavailable: duration > 0,
  unavailableUntil:
    duration === 0 || duration === Infinity
      ? null
      : getFutureUtcDateTime(duration).format(),
});

type MutationProps = {
  duration: number;
  productId: MenuProduct['id'];
  productTypeId?: MenuProductType['id'];
  shopId: Shop['shopId'];
};

export const useUpdateProductStock = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      duration,
      productId,
      productTypeId,
      shopId,
    }: MutationProps) => {
      const availability = getAvailability(duration);

      return productTypeId == null
        ? api.putProductStock(shopId, productId, availability)
        : api.putProductTypeStock(
            shopId,
            productId,
            productTypeId,
            availability,
          );
    },
    onSuccess: (data, { productId, shopId }) => {
      queryClient.setQueryData(
        getProductQueryKey(shopId, productId),
        (current?: MenuProductResponseBody) => {
          if (current) {
            return updateCachedProduct(current, data);
          }
        },
      );

      queryClient.setQueryData(
        getMenuProductsQueryKey(shopId),
        (current?: MenuProductsResponseBody) => {
          if (current) {
            return updateCachedMenu(current, data);
          }
        },
      );

      queryClient.setQueryData(
        getMenuQueryKey(shopId),
        (current?: MenuResponseBody) => {
          if (current) {
            return updateLegacyCachedMenu(current, data);
          }
        },
      );
    },
  });
};
