import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CampaignManagerSummary } from 'types/campaign-manager';
import { Shop } from 'types/shops';

type UseCampaignManagerSummaryQueryOptions = Omit<
  UseQueryOptions<CampaignManagerSummary>,
  'queryKey' | 'queryFn'
>;

export const getCampaignManagerSummaryQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'campaign-manager-summary'];

const useCampaignManagerSummaryQuery = (
  shopId: Shop['shopId'],
  options?: UseCampaignManagerSummaryQueryOptions,
): UseQueryResult<CampaignManagerSummary> => {
  const api = useApi();

  return useQuery({
    queryKey: getCampaignManagerSummaryQueryKey(shopId),
    queryFn: () => api.getCampaignManagerSummary(shopId),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCampaignManagerSummaryQuery;
