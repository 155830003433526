import { GoogleBusinessProfile } from 'components/google-business-profile';
import Page from 'components/shared/page';

export const GoogleBusinessProfilePage = () => {
  return (
    <Page name="Google Business Profile" title="Google Business Profile">
      <GoogleBusinessProfile />
    </Page>
  );
};
