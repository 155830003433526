import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { AssetLibraryAsset } from 'types/asset-library';

export type UseAssetLibraryResponse = {
  assets: AssetLibraryAsset[];
  isLoading: boolean;
  serviceNames: string[];
};

export const useAssetLibrary = (): UseAssetLibraryResponse => {
  const api = useApi();
  const { data: categoryData, isLoading: isCategoriesLoading } = useQuery(
    ['asset-library-categories'],
    () => api.getAssetLibraryCategories(),
  );
  const { data: assetData, isLoading: isAssetsLoading } = useQuery(
    ['asset-library-assets'],
    () => api.getAssetLibraryAssets(),
  );
  const isLoading = isCategoriesLoading || isAssetsLoading;

  let serviceNames: string[] = [];
  const categoryIdToNameMap: Record<string, string> = {};

  if (!isCategoriesLoading) {
    const socialCategory = categoryData?.find(
      (category) => category.name === 'Social',
    );

    if (socialCategory) {
      serviceNames = socialCategory.children.map((subCategory) => {
        categoryIdToNameMap[subCategory.id] = subCategory.name;
        return subCategory.name;
      });
    }
  }

  const assets: AssetLibraryAsset[] = [];

  if (!isLoading && assetData) {
    for (const asset of assetData) {
      const categoryName = categoryIdToNameMap[asset.categoryId];

      if (categoryName) {
        assets.push({
          ...asset,
          categoryName,
        });
      }
    }
  }

  return {
    assets,
    isLoading,
    serviceNames,
  };
};
