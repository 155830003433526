import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  GetShopStatusHistoryRequestParams,
  GetShopStatusHistoryResponse,
} from 'types/hours-analytics';
import { Shop } from 'types/shops';

export const getShopStatusHistoryQueryKey = (
  shopId: Shop['shopId'],
  params: GetShopStatusHistoryRequestParams,
): QueryKey => [shopId, 'shop-status-history', params];

type Props = {
  shopId: Shop['shopId'];
  params: GetShopStatusHistoryRequestParams;
};

const useShopStatusHistoryQuery = ({
  shopId,
  params,
}: Props): UseQueryResult<GetShopStatusHistoryResponse> => {
  const api = useApi();

  return useQuery({
    queryKey: getShopStatusHistoryQueryKey(shopId, params),
    queryFn: () => api.getShopStatusHistory(shopId, params),
    keepPreviousData: true,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useShopStatusHistoryQuery;
