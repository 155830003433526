import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import { GetPayoutsRequestParams, PayoutsResponse } from 'types/payouts';
import { Shop } from 'types/shops';
import { toSnakeCase } from 'utilities/strings';

export const getPayoutsQueryKey = (
  shopId: Shop['shopId'],
  params: GetPayoutsRequestParams,
): QueryKey => [shopId, 'payouts', params];

export const usePayoutsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
): UseQueryResult<PayoutsResponse> => {
  const api = useApi();

  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: `${sorting.desc ? '-' : '+'}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getPayoutsQueryKey(shopId, params),
    queryFn: () => api.getPayouts(shopId, params),
    keepPreviousData: true,
  });
};
