import { useCallback, useEffect, useState } from 'react';

type Dimension = {
  height?: number;
  offsetTop?: number;
  width?: number;
  top?: number;
};

type UseDimensionsHook = [
  (node: HTMLDivElement) => void,
  Dimension,
  HTMLDivElement | null,
];

const getDimensionObject = (node: HTMLDivElement) => {
  return {
    width: node.clientWidth,
    height: node.clientHeight,
    top: node.getBoundingClientRect().top,
    offsetTop: node.offsetTop,
  };
};

// Adapted from Storefront logic:
// https://github.com/slicelife/storefront/blob/master/src/hooks/useDimensions.ts
// Storefront logic was in turn based on:
// https://github.com/Swizec/useDimensions/blob/master/src/index.ts

const useDimensions = (): UseDimensionsHook => {
  const [dimensions, setDimensions] = useState<Dimension>({});
  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node)),
        );
      measure();

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, [node]);

  return [ref, dimensions, node];
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useDimensions;
