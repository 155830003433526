import {
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  ModalOverlayProps as AriaModalOverlayProps,
} from 'react-aria-components';

import { Dialog } from '../dialog/dialog';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './tray.css';

const getTrayClassName = getCrustClassName.bind(null, 'tray');

export type TrayProps = AriaModalOverlayProps & {
  kind?: 'dialog' | 'menu';
};

export const Tray = ({
  UNSTABLE_portalContainer,
  children,
  className,
  defaultOpen,
  isDismissable,
  isEntering,
  isExiting,
  isKeyboardDismissDisabled,
  isOpen,
  kind = 'dialog',
  onOpenChange,
  ...props
}: TrayProps) => (
  <AriaModalOverlay
    UNSTABLE_portalContainer={UNSTABLE_portalContainer}
    className={getTrayClassName('overlay')}
    defaultOpen={defaultOpen}
    isDismissable={isDismissable}
    isEntering={isEntering}
    isExiting={isExiting}
    isKeyboardDismissDisabled={isKeyboardDismissDisabled}
    isOpen={isOpen}
    onOpenChange={onOpenChange}
  >
    <AriaModal
      className={mergeAriaClassName(getTrayClassName('modal'), className)}
      {...props}
    >
      {kind === 'dialog' ? (
        <Dialog className={getTrayClassName('dialog')}>{children}</Dialog>
      ) : (
        children
      )}
    </AriaModal>
  </AriaModalOverlay>
);
