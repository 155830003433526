import { CalendarDate } from '@internationalized/date';
import { useQuery } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

type UseTipsAndEarningsData = {
  tips: string;
  earnings: string;
  voidedEarnings: string;
  totalOrders: string;
  averageOrder: number;
};

type UseTipsAndEarnings = {
  isLoading: boolean;
  data: UseTipsAndEarningsData;
};

type Props = {
  dates: RangeValue<CalendarDate>;
  shopId: string;
  shopTimezone: string;
};

const useTipsAndEarnings = ({
  dates,
  shopId,
  shopTimezone,
}: Props): UseTipsAndEarnings => {
  const api = useApi();

  // Send the dates as datetimes in UTC relative to shop timezone.
  const params = {
    startDate: toStartOfDateAbsoluteString(dates.start, shopTimezone),
    endDate: toEndOfDateAbsoluteString(dates.end, shopTimezone),
  };

  const { data, isLoading } = useQuery(
    [shopId, 'tips-and-earnings', dates],
    () => api.getTipsAndEarnings(Number(shopId), params),
  );

  return {
    data:
      isLoading || !data
        ? {
            tips: '0',
            earnings: '0',
            voidedEarnings: '0',
            totalOrders: '0',
            averageOrder: 0,
          }
        : {
            tips: data?.tips,
            earnings: data?.earnings,
            voidedEarnings: data?.voidedEarnings,
            totalOrders: data?.totalOrders,
            averageOrder:
              parseInt(data?.totalOrders) > 0
                ? Number(data?.earnings) / Number(data?.totalOrders)
                : 0,
          },
    isLoading,
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useTipsAndEarnings;
