import { CalendarDate } from '@internationalized/date';
import { RangeValue } from '@react-types/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

import { getSalesMixReportsQueryKey } from '.';

type FormValues = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateSalesMixReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: FormValues) =>
      api.createSalesMixReport(shopId, {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSalesMixReportsQueryKey(shopId));
      },
    },
  );
};
