import { CalendarDate } from '@internationalized/date';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';

import { getOrderActivityReportsQueryKey } from './use-order-activity-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
  onlineOrdersOnly: boolean;
};

export const useCreateOrderActivityReportMutation = (
  shopId: Shop['shopId'],
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ dates, onlineOrdersOnly }: Values) =>
      api.createOrderActivityReport(shopId, {
        start: dates.start.toString(),
        end: dates.end.toString(),
        onlyOnlineOrders: onlineOrdersOnly,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getOrderActivityReportsQueryKey(shopId));
      },
    },
  );
};
