import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import {
  GetOrderActivityReportsResponse,
  GetReportsRequestParams,
} from 'types/financials';
import { toSnakeCase } from 'utilities/strings';

type UseOrderActivityReportsQueryOptions = Omit<
  UseQueryOptions<GetOrderActivityReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getOrderActivityReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'orderActivityReports', ...(params ? [params] : [])];

export const useOrderActivityReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseOrderActivityReportsQueryOptions,
): UseQueryResult<GetOrderActivityReportsResponse> => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: `${sorting.desc ? '-' : '+'}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getOrderActivityReportsQueryKey(shopId, params),
    queryFn: () => api.getOrderActivityReports(shopId, params),
    keepPreviousData: true,
    ...options,
  });
};
