import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Moment } from 'moment-timezone';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { API } from 'types/api';
import {
  AvailabilitySummary,
  AvailabilitySummaryWithoutAsOfText,
} from 'types/hours-analytics';
import { Shop } from 'types/shops';
import { nowDateTime } from 'utilities/date-time';
import { getBlankSummaryData } from 'utilities/hours-analytics';

const requestData = async (
  api: API,
  shopId: number,
  timezone: string,
  daysAgo: number,
): Promise<AvailabilitySummary> => {
  const day = nowDateTime(timezone).subtract(daysAgo, 'days');
  const result = await api.getHoursAnalyticsSummary(
    shopId,
    day.format('yyyy-MM-DD'),
  );
  return formatResponse(result, day);
};

const formatResponse = (
  response: AvailabilitySummaryWithoutAsOfText,
  day: Moment,
): AvailabilitySummary => {
  return {
    ...response,
    dataAsOfText: day.format('MMM D'),
  };
};

const is404 = (error: unknown): boolean => {
  return error instanceof ApiRequestError && error.status == 404;
};

type UseHoursAnalyticsSummaryQueryOptions = Omit<
  UseQueryOptions<AvailabilitySummary>,
  'queryKey' | 'queryFn'
>;

export const getHoursAnalyticsSummaryQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'hours-analytics-summary'];

type Props = {
  shopId: Shop['shopId'];
  timezone: string;
  options?: UseHoursAnalyticsSummaryQueryOptions;
};

const useHoursAnalyticsSummaryQuery = ({
  shopId,
  timezone,
  options,
}: Props): UseQueryResult<AvailabilitySummary> => {
  const api = useApi();

  return useQuery({
    queryKey: getHoursAnalyticsSummaryQueryKey(shopId),
    queryFn: async () => {
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString('en-US', {
        timeZone: 'EST',
        hour12: false,
      });
      const currentHour = Number(formattedTime.split(':')[0]);

      // We want to query today data after the time when the metrics should be generated.
      // After 12:00EST for today we'll be sure that the metrics are generated.
      // This is enough time for the data to be exported.
      if (currentHour >= 12) {
        try {
          return await requestData(api, shopId, timezone, 0);
        } catch (firstError: unknown) {
          // We want to look for a 404, which is expected if the backend has not
          // yet calculated the last 30 day and weekly summary metrics for the
          // Hours Analytics summary endpoint.
          // Upon 404, request data as of the previous day. We perform this
          // fallback to the previous day only once (i.e., request today, if 404,
          // request yesterday, if still no data, no special logic, display
          // nothing).
          if (!is404(firstError)) {
            throw firstError;
          }
        }
      }

      // Before 12:00EST for today we'll query the yesterday data
      try {
        return await requestData(api, shopId, timezone, 1);
      } catch (secondError: unknown) {
        // A 404 on the previous day's data is also possible, e.g.,
        // the shop has no availability data for the past month.
        // In this case, we just want to show the "no data" state.
        if (!is404(secondError)) {
          throw secondError;
        }
        return getBlankSummaryData(timezone);
      }
    },
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useHoursAnalyticsSummaryQuery;
