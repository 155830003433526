import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { Shop, ShopTraits } from 'types/shops';

import useApi from '../use-api';

import { getShopQueryKey } from '.';

type UseShoptraitsQueryOptions = Omit<
  UseQueryOptions<ShopTraits>,
  'queryKey' | 'queryFn'
>;

export const getShopTraitsQueryKey = (shopId: Shop['shopId']): QueryKey => [
  ...getShopQueryKey(shopId),
  'traits',
];

export const useShopTraitsQuery = (
  shopId: Shop['shopId'],
  options?: UseShoptraitsQueryOptions,
): UseQueryResult<ShopTraits> => {
  const api = useApi();

  return useQuery({
    queryKey: getShopTraitsQueryKey(shopId),
    queryFn: () => api.getTraits(String(shopId)),
    ...options,
  });
};
