import { CalendarDate } from '@internationalized/date';

import { RangeValue } from 'crust';

import AnalyticsTile from 'components/orders/analytics-tile';
import { OnlineMetricKeys } from 'types/orders';
import {
  onlineMetricDashboardIds,
  onlineMetricIds,
  onlineMetricLabels,
} from 'utilities/financials/orders';

import useTipsAndEarnings from './use-tips-and-earnings';

import styles from './styles.module.scss';

type Props = {
  dates: RangeValue<CalendarDate>;
  metricIds: typeof onlineMetricDashboardIds | typeof onlineMetricIds;
  shopId: string;
  shopTimezone: string;
};

const OnlineOrderMetrics = ({
  dates,
  metricIds,
  shopId,
  shopTimezone,
}: Props) => {
  const { data, isLoading } = useTipsAndEarnings({
    dates,
    shopId,
    shopTimezone,
  });

  return (
    <div className={styles.metricsContainer}>
      {Object.entries(onlineMetricLabels).map(([key, label]) => {
        return (
          <AnalyticsTile
            dataTestId={metricIds[key as OnlineMetricKeys]}
            isLoading={isLoading}
            key={key}
            labelKey={key}
            title={label}
            value={data?.[key as OnlineMetricKeys] ?? 0}
            isOnlineOrdersMetric
          />
        );
      })}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OnlineOrderMetrics;
