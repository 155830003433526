import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  RegisterProfile,
  RegisterProfileFormValues,
} from 'types/register-profile';
import { Shop } from 'types/shops';
import { upsertBy } from 'utilities/lists';

import { getRegisterProfilesQueryKey } from './use-register-profiles-query';

export const useSaveRegisterProfileMutation = (
  shopId: Shop['shopId'],
): UseMutationResult<
  RegisterProfile,
  ApiRequestError,
  RegisterProfileFormValues
> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: RegisterProfileFormValues) => {
      const body = {
        name: values.name.trim(),
      };

      return values.id
        ? api.updateRegisterProfile(shopId, values.id, body)
        : api.createRegisterProfile(shopId, body);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<RegisterProfile[]>(
        getRegisterProfilesQueryKey(shopId),
        (current) => upsertBy(current ?? [], data, 'id'),
      );
    },
  });
};
