import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import {
  GetReportsRequestParams,
  GetSalesMixReportsResponse,
} from 'types/financials';
import { toSnakeCase } from 'utilities/strings';

type UseSalesMixReportsQueryOptions = Omit<
  UseQueryOptions<GetSalesMixReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getSalesMixReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'salesMixReports', ...(params ? [params] : [])];

export const useSalesMixReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseSalesMixReportsQueryOptions,
): UseQueryResult<GetSalesMixReportsResponse> => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getSalesMixReportsQueryKey(shopId, params),
    queryFn: () => api.getSalesMixReports(shopId, params),
    keepPreviousData: true,
    ...options,
  });
};
