import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { PermissionsInfo } from 'types/register-users';

export const getRecommendedRegisterPermissionGroupsQueryKey = (): QueryKey => [
  'recommendedPermissionGroups',
];

export const useRecommendedRegisterPermissionGroupsQuery =
  (): UseQueryResult<PermissionsInfo> => {
    const api = useApi();

    return useQuery(getRecommendedRegisterPermissionGroupsQueryKey(), () =>
      api.getRecommendedPermissionGroups(),
    );
  };
