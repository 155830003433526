import {
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
} from 'react-aria-components';

import { Dialog } from '../dialog/dialog';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './popover.css';

export type PopoverProps = AriaPopoverProps & {
  kind?: 'dialog' | 'menu';
};

const getPopoverClassName = getCrustClassName.bind(null, 'popover');

export const Popover = ({
  children,
  className,
  kind = 'dialog',
  ...props
}: PopoverProps) => (
  <AriaPopover
    className={mergeAriaClassName(getPopoverClassName(), className)}
    {...props}
  >
    {kind === 'dialog' ? (
      <Dialog className={getPopoverClassName('dialog')}>{children}</Dialog>
    ) : (
      children
    )}
  </AriaPopover>
);
