import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerList,
  CustomerSortBy,
  CustomerSortDirection,
  CustomersResponse,
  CustomerType,
  DateRange,
  Top10CustomersType,
} from 'types/customers';
import { getInterval } from 'utilities/customers';

type UseTop10CustomersQueryOptions = Omit<
  UseQueryOptions<CustomersResponse>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  top10CustomersType: Top10CustomersType;
  shopId: string;
  timezone: string;
  options?: UseTop10CustomersQueryOptions;
};

export const getTop10CustomersQueryKey = ({
  customerType,
  dateRange,
  top10CustomersType,
  shopId,
}: Omit<Props, 'timezone' | 'options'>): QueryKey => [
  shopId,
  'top10Customers',
  dateRange,
  customerType,
  top10CustomersType,
];

const useTop10CustomersQuery = ({
  customerType,
  dateRange,
  top10CustomersType,
  shopId,
  timezone,
  options,
}: Props): UseQueryResult<CustomersResponse> => {
  const api = useApi();

  return useQuery({
    queryKey: getTop10CustomersQueryKey({
      customerType,
      dateRange,
      top10CustomersType,
      shopId,
    }),
    queryFn: () =>
      api.getCustomers(
        shopId,
        1,
        10,
        {
          by:
            top10CustomersType === Top10CustomersType.MostValuable
              ? CustomerSortBy.OrderTotal
              : CustomerSortBy.OrderCount,
          direction: CustomerSortDirection.Descending,
        },
        getInterval(dateRange, timezone),
        customerType,
        CustomerList.All,
        [],
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useTop10CustomersQuery;
