import { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  GetTogglePropsInput,
  GetTogglePropsOutput,
} from 'react-collapsed/dist/types';
import cx from 'classnames';

import { Icon } from 'crust';

import { capitalize } from 'utilities/strings';

import {
  CollapsibleTileAnimationState,
  CollapsibleTileVariant,
} from '../utilities';

import styles from './styles.module.scss';

type Props = {
  animationState: CollapsibleTileAnimationState;
  className?: string;
  description?: ReactNode;
  descriptionClassName?: string;
  getToggleProps: (config?: GetTogglePropsInput) => GetTogglePropsOutput;
  isDisabled?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  summary?: ReactNode;
  summaryClassName?: string;
  title: ReactNode;
  titleClassName?: string;
  toggleAfter?: ReactNode;
  toggleBefore?: ReactNode;
  toggleClassName?: string;
  variant: CollapsibleTileVariant;
};

const CollapsibleTileHeader = ({
  animationState,
  className,
  description,
  descriptionClassName,
  getToggleProps,
  isDisabled,
  setIsOpen,
  summary,
  summaryClassName,
  title,
  titleClassName,
  toggleAfter,
  toggleBefore,
  toggleClassName,
  variant,
}: Props) => {
  const isRegular = variant === CollapsibleTileVariant.Regular;
  const icon = isRegular ? 'chevronDown' : 'pencil';

  const getClassName = (base: string, custom?: string) =>
    cx(
      styles[`${base}${capitalize(variant)}${capitalize(animationState)}`],
      custom,
    );

  return (
    <div className={getClassName('header', className)}>
      {toggleBefore}
      <button
        {...getToggleProps({
          className: getClassName('toggle', toggleClassName),
          disabled: isDisabled,
          onClick: () => setIsOpen((current) => !current),
        })}
      >
        <span className={getClassName('title', titleClassName)}>{title}</span>
        {!isRegular && <hr className={getClassName('divider')} />}
        {description != null && (
          <span className={getClassName('description', descriptionClassName)}>
            {description}
          </span>
        )}
        {summary != null && (
          <span className={getClassName('summary', summaryClassName)}>
            {summary}
          </span>
        )}
        <Icon
          icon={icon}
          className={getClassName('indicator')}
          aria-hidden="true"
        />
      </button>
      {toggleAfter}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CollapsibleTileHeader;
