import { useNavigate } from 'react-router-dom';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import useTop10CustomersQuery from 'hooks/customers/use-top-10-customers-query';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import * as paths from 'routes/paths';
import {
  BaseCustomer,
  CustomerAnalyticsStorageKeys,
  CustomerList,
  CustomerType,
  DateRange,
  Top10CustomersTabType,
  Top10CustomersType,
} from 'types/customers';
import { CustomerListLabels } from 'utilities/customers';

import Top10CustomersTable from './table';
import Top10CustomersTabs from './tabs';

import styles from './styles.module.scss';

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<BaseCustomer | null>
  >;
};

const Top10CustomersTile = ({
  customerType,
  dateRange,
  shopId,
  timezone,
  setSelectedCustomer,
}: Props) => {
  const { trackClickedTop10CustomersTab, trackClickedViewList } =
    useAnalytics();

  const [selectedTab, setSelectedTab] = useStoredState(
    CustomerAnalyticsStorageKeys.SelectedTop10Tab,
    Top10CustomersTabType.Value,
  );
  const navigate = useNavigate();

  const handleSelectedTab = (newTab: Top10CustomersTabType): void => {
    trackClickedTop10CustomersTab(shopId, newTab);
    setSelectedTab(newTab);
  };

  const { data: customersResponse, isLoading } = useTop10CustomersQuery({
    customerType,
    dateRange,
    top10CustomersType:
      selectedTab === Top10CustomersTabType.Value
        ? Top10CustomersType.MostValuable
        : Top10CustomersType.MostFrequent,
    shopId,
    timezone,
  });

  const selectedCustomerList =
    selectedTab === Top10CustomersTabType.Value
      ? CustomerList.MostValuable
      : CustomerList.MostFrequent;

  const handleClickViewListLink = (): void => {
    navigate(
      {
        pathname: paths.getCustomerSegmentsPath(shopId),
      },
      {
        state: {
          customerList:
            selectedTab === Top10CustomersTabType.Value
              ? CustomerList.MostValuable
              : CustomerList.MostFrequent,
        },
      },
    );
    trackClickedViewList(
      shopId,
      CustomerListLabels[selectedCustomerList].toLowerCase(),
      customersResponse?.data.length ?? 0,
    );
  };

  return (
    <ContentTile
      className={styles.top10CustomersTile}
      title="Top 10 Customers"
      headerClassName={styles.tileHeader}
    >
      <div className={styles.tabArea}>
        <Top10CustomersTabs
          selectedTab={selectedTab ?? Top10CustomersTabType.Value}
          setSelectedTab={handleSelectedTab}
        />
        <Link
          appearance="button"
          variant="secondary"
          onPress={handleClickViewListLink}
        >
          View as List
        </Link>
      </div>
      <Top10CustomersTable
        customerList={selectedCustomerList}
        customers={customersResponse?.data ?? []}
        isLoading={isLoading}
        setSelectedCustomer={setSelectedCustomer}
        shopId={shopId}
        shopTimezone={timezone}
      />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Top10CustomersTile;
