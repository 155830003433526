import { useQuery } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import { CustomerExportsSchema } from 'types/customers/profile-exports';
import { Shop } from 'types/shops';

export const getCustomerExportsQueryKey = (
  shopId: Shop['shopId'],
  pagination?: PaginationState,
) => [shopId, 'customerExports', ...(pagination ? [pagination] : [])];

export const useCustomerExportsQuery = (
  shopId: Shop['shopId'],
  pagination: PaginationState,
) => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
  };

  return useQuery(
    getCustomerExportsQueryKey(shopId, pagination),
    async () => {
      const response = await api.getCustomerExports(String(shopId), params);

      return CustomerExportsSchema.parse(response);
    },
    {
      keepPreviousData: true,
    },
  );
};
