import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Button, ButtonGroup } from 'crust';

import { Divider } from 'components/shared/divider';
import FormText from 'components/shared/form-text';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import { RHFNumberField } from 'components/shared/rhf-number-field';
import { useSaveRegisterTipSettings } from 'hooks/register/use-save-register-tip-settings';
import { RegisterTipSettings } from 'types/register-tip-settings';
import { Shop } from 'types/shops';
import { showInvalidSubmitToast } from 'utilities/forms';

import styles from './styles.module.scss';

const validationSchema = z.object({
  adjustmentLimitPercent: z
    .number({
      errorMap: () => ({
        message: 'Please enter an amount 200% or greater.',
      }),
    })
    .gte(200)
    .lte(Number.MAX_VALUE)
    .optional(),
  defaultTips: z.array(
    z.object({
      amount: z
        .number({
          errorMap: () => ({
            message: 'Please enter an amount from 1% to 100%.',
          }),
        })
        .gte(1)
        .lte(100),
    }),
  ),
  isLargeTipsEnabled: z.boolean(),
});

type FormValues = z.infer<typeof validationSchema>;

type Props = {
  tipSettings: RegisterTipSettings;
  shopId: Shop['shopId'];
};

export const TipSettings = ({ tipSettings, shopId }: Props) => {
  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      adjustmentLimitPercent: Number(tipSettings.adjustmentLimitPercent),
      defaultTips: tipSettings.defaultTips.map((tip) => ({
        amount: Number(tip.amount),
      })),
      isLargeTipsEnabled: Number(tipSettings.adjustmentLimitPercent) > 200,
    },
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    shouldUnregister: true,
  });

  const isLargeTipsEnabled = watch('isLargeTipsEnabled');
  const { mutate, isLoading } = useSaveRegisterTipSettings();

  const handleValidSubmit = (data: FormValues) => {
    const variables = {
      shopId: shopId,
      tipSettings: {
        // Set back to 200 to disable large tips.
        adjustmentLimitPercent: String(data.adjustmentLimitPercent ?? 200),
        defaultTips: data.defaultTips.map((it) => ({
          amount: String(it.amount),
          amountType: 'percent',
        })),
      },
    };

    mutate(variables, {
      onError: () => {
        toast.error(
          'There was a problem submitting your tip settings, please try again.',
        );
      },
      onSuccess: () => {
        toast.success('Register tip settings updated!');
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}>
      <FormText>Tip Percentages</FormText>
      <div className={styles.suggestedTips}>
        {tipSettings.defaultTips.map((_, index) => (
          <RHFNumberField
            className={styles.tipInput}
            control={control}
            formatOptions={{
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment --
               * This option should be supported in TS 5.4:
               * https://github.com/microsoft/TypeScript/pull/56902 */
              // @ts-ignore
              roundingMode: 'trunc',
            }}
            isRequired
            key={index}
            label={`Suggested Tip ${index + 1}`}
            minValue={1}
            maxValue={100}
            name={`defaultTips.${index}.amount`}
            suffix="%"
          />
        ))}
      </div>
      <Divider className={styles.tipDivider} />
      <div className={styles.container}>
        <RHFCheckbox
          control={control}
          name="isLargeTipsEnabled"
          label="Enable tipping amounts exceeding 200% of the order total"
        />
        {isLargeTipsEnabled ? (
          <RHFNumberField
            control={control}
            formatOptions={{
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment --
               * This option should be supported in TS 5.4:
               * https://github.com/microsoft/TypeScript/pull/56902 */
              // @ts-ignore
              roundingMode: 'trunc',
            }}
            isRequired
            label="Enter a tip percentage exceeding 200%"
            minValue={200}
            name="adjustmentLimitPercent"
            suffix="%"
          />
        ) : null}
        <div className={styles.bottomText}>
          {`Please download changes on your credit card terminal. If you wish to
            additionally put this behind an employee pin action, please navigate
            to the 'Employee Permissions' tab.`}
        </div>
      </div>
      <ButtonGroup autoCollapse>
        <Button type="submit" variant="primary" isDisabled={isLoading}>
          Save
        </Button>
      </ButtonGroup>
    </form>
  );
};
