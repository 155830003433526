import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

const schema = z.object({
  redirectUrl: z.string(),
  showOnboardingLink: z.boolean(),
});

const getAchOnboardingLinkQueryKey = (shopId: Shop['shopId']) =>
  [shopId, 'ach_onboarding_link'] as const;

export const useAchOnboardingLink = (shopId: Shop['shopId']) => {
  const api = useApi();

  return useQuery({
    queryKey: getAchOnboardingLinkQueryKey(shopId),
    queryFn: async () => {
      const data = await api.getAchOnboardingLink(shopId);
      return schema.parse(data);
    },
  });
};
