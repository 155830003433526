import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CustomerOrderProfile } from 'types/customers';

export const getCustomerOrderProfileQueryKey = (
  shopId: number,
  customerUuid: string,
): QueryKey => [shopId, customerUuid, 'getCustomerOrderProfile'];

export const useCustomerOrderProfileQuery = (
  shopId: number,
  customerUuid: string,
  options?: UseQueryOptions<CustomerOrderProfile>,
): UseQueryResult<CustomerOrderProfile> => {
  const api = useApi();

  return useQuery(
    getCustomerOrderProfileQueryKey(shopId, customerUuid),
    () => api.getCustomer(shopId, customerUuid),
    {
      ...options,
    },
  );
};
