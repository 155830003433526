import { CalendarDate } from '@internationalized/date';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';

import { getStandalonePaymentsReportsQueryKey } from './use-standalone-payments-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateStandalonePaymentsReportMutation = (
  shopId: Shop['shopId'],
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ dates }: Values) =>
      api.createStandalonePaymentsReport(shopId, {
        start: dates.start.toString(),
        finish: dates.end.toString(),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getStandalonePaymentsReportsQueryKey(shopId),
        );
      },
    },
  );
};
