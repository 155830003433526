import { RowData } from '@tanstack/react-table';
import cx from 'classnames';

import {
  AutomaticTableBodyRowProps,
  BaseTableBodyRowProps,
  OverloadedTableBodyRowComponent,
} from 'types/shared/table';

import TableBodyCell from '../cell';
import TableBodySpanner from '../spanner';

import styles from './styles.module.scss';

const BaseTableBodyRow = ({
  className,
  index,
  ...props
}: BaseTableBodyRowProps) => {
  const isOdd = index % 2 === 1;

  return (
    <tr className={cx(styles.row, isOdd && styles.odd, className)} {...props} />
  );
};

const TableBodyRow: OverloadedTableBodyRowComponent = <TData extends RowData>(
  props: BaseTableBodyRowProps | AutomaticTableBodyRowProps<TData>,
) => {
  // Pass a row object from the result of useReactTable to automatically render
  // the row and its cells.
  if ('row' in props) {
    const { index, row, className, ...base } = props;
    const cells = row.getVisibleCells();
    const isExpanded = row.getIsExpanded();

    const metaClassName = cells[0]
      ?.getContext()
      .table.options.meta?.getRowClassName?.({ row });

    // The ID used here is provided by React Table and is separate from any ID
    // that may be present in the table data.
    // https://tanstack.com/table/v8/docs/api/core/cell#id
    const rendered = (
      <BaseTableBodyRow
        className={cx(className, isExpanded && styles.expanded, metaClassName)}
        index={index}
        {...base}
      >
        {row.getVisibleCells().map((cell) => (
          <TableBodyCell key={cell.id} cell={cell} rowIndex={index} />
        ))}
      </BaseTableBodyRow>
    );

    if (isExpanded) {
      const colSpan = cells.length;
      const expansion = cells[0]
        ?.getContext()
        .table.options.meta?.getRowExpansion?.({ row });

      if (expansion) {
        return (
          <>
            {rendered}
            <TableBodySpanner className={styles.expansion} colSpan={colSpan}>
              {expansion}
            </TableBodySpanner>
          </>
        );
      }
    }

    return rendered;
  }

  return <BaseTableBodyRow {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBodyRow;
