import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { SiteEditorSettings } from 'types/site-editor';

export const getDirectWebSettingsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'directWebSettings',
];

export const useDirectWebSettingsQuery = (
  shopId: string,
  options?: UseQueryOptions<SiteEditorSettings>,
): UseQueryResult<SiteEditorSettings> => {
  const api = useApi();

  return useQuery(
    getDirectWebSettingsQueryKey(shopId),
    () => api.getDirectWebSettings(shopId),
    {
      ...options,
    },
  );
};
