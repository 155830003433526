import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CampaignManagerHistory } from 'types/campaign-manager';
import { Shop } from 'types/shops';

type UseCampaignManagerHistoryQueryOptions = Omit<
  UseQueryOptions<CampaignManagerHistory>,
  'queryKey' | 'queryFn'
>;

export const getCampaignManagerHistoryQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'campaign-manager-History'];

const useCampaignManagerHistoryQuery = (
  shopId: Shop['shopId'],
  options?: UseCampaignManagerHistoryQueryOptions,
): UseQueryResult<CampaignManagerHistory> => {
  const api = useApi();

  return useQuery({
    queryKey: getCampaignManagerHistoryQueryKey(shopId),
    queryFn: () => api.getCampaignManagerHistory(shopId),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCampaignManagerHistoryQuery;
