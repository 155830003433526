import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterProfile } from 'types/register-profile';
import { Shop } from 'types/shops';

type UseRegisterProfilesQueryOptions = Omit<
  UseQueryOptions<RegisterProfile[]>,
  'queryFn' | 'queryKey'
>;

export const getRegisterProfilesQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'registerProfiles'];

export const useRegisterProfilesQuery = (
  shopId: Shop['shopId'],
  options?: UseRegisterProfilesQueryOptions,
): UseQueryResult<RegisterProfile[]> => {
  const api = useApi();

  return useQuery({
    queryKey: getRegisterProfilesQueryKey(shopId),
    queryFn: () => api.getRegisterProfiles(shopId),
    ...options,
  });
};
