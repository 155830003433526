import {
  ComponentPropsWithoutRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import BellIcon from './svgs/bell-icon.svg?react';
import BubbleIcon from './svgs/bubble-icon.svg?react';
import CalendarIcon from './svgs/calendar-icon.svg?react';
import CameraIcon from './svgs/camera-icon.svg?react';
import CartIcon from './svgs/cart-icon.svg?react';
import ChartIcon from './svgs/chart-icon.svg?react';
import CheckmarkIcon from './svgs/checkmark-icon.svg?react';
import ChevronDownIcon from './svgs/chevron-down-icon.svg?react';
import ChevronLeftIcon from './svgs/chevron-left-icon.svg?react';
import ChevronRightIcon from './svgs/chevron-right-icon.svg?react';
import ChevronUpIcon from './svgs/chevron-up-icon.svg?react';
import ClockIcon from './svgs/clock-icon.svg?react';
import CloseIcon from './svgs/close-icon.svg?react';
import CreditCardIcon from './svgs/credit-card-icon.svg?react';
import DownloadIcon from './svgs/download-icon.svg?react';
import GaugeIcon from './svgs/gauge-icon.svg?react';
import GearIcon from './svgs/gear-icon.svg?react';
import GraphIcon from './svgs/graph-icon.svg?react';
import HeadsetIcon from './svgs/headset-icon.svg?react';
import HelpIcon from './svgs/help-icon.svg?react';
import HouseIcon from './svgs/house-icon.svg?react';
import InfoIcon from './svgs/info-icon.svg?react';
import LockIcon from './svgs/lock-icon.svg?react';
import MagnifyingGlassIcon from './svgs/magnifying-glass-icon.svg?react';
import MeetAtDoorIcon from './svgs/meet-at-door-icon.svg?react';
import MegaphoneIcon from './svgs/megaphone-icon.svg?react';
import MenuIcon from './svgs/menu-icon.svg?react';
import MinusIcon from './svgs/minus-icon.svg?react';
import MoneyIcon from './svgs/money-icon.svg?react';
import NewWindowIcon from './svgs/new-window-icon.svg?react';
import OrderDetailsIcon from './svgs/order-details-icon.svg?react';
import PageIcon from './svgs/page-icon.svg?react';
import PencilIcon from './svgs/pencil-icon.svg?react';
import PeopleIcon from './svgs/people-icon.svg?react';
import PersonIcon from './svgs/person-icon.svg?react';
import PhoneIcon from './svgs/phone-icon.svg?react';
import PinIcon from './svgs/pin-icon.svg?react';
import PizzaLeftIcon from './svgs/pizza-left-icon.svg?react';
import PizzaRightIcon from './svgs/pizza-right-icon.svg?react';
import PizzaSliceIcon from './svgs/pizza-slice-icon.svg?react';
import PizzaWholeIcon from './svgs/pizza-whole-icon.svg?react';
import PlayIcon from './svgs/play-icon.svg?react';
import PlusIcon from './svgs/plus-icon.svg?react';
import RegisterIcon from './svgs/register-icon.svg?react';
import ShareIcon from './svgs/share-icon.svg?react';
import ShopIcon from './svgs/shop-icon.svg?react';
import SparkleIcon from './svgs/sparkle-icon.svg?react';
import TagIcon from './svgs/tag-icon.svg?react';
import TrashCanIcon from './svgs/trash-can-icon.svg?react';
import VerificationIcon from './svgs/verification-icon.svg?react';
import WalletIcon from './svgs/wallet-icon.svg?react';
import WarningIcon from './svgs/warning-icon.svg?react';
import WebsiteIcon from './svgs/website-icon.svg?react';

import './icon.css';

const icons = {
  bell: BellIcon,
  bubble: BubbleIcon,
  calendar: CalendarIcon,
  camera: CameraIcon,
  cart: CartIcon,
  chart: ChartIcon,
  checkmark: CheckmarkIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  clock: ClockIcon,
  close: CloseIcon,
  creditCard: CreditCardIcon,
  download: DownloadIcon,
  guage: GaugeIcon,
  gear: GearIcon,
  graph: GraphIcon,
  headset: HeadsetIcon,
  help: HelpIcon,
  house: HouseIcon,
  info: InfoIcon,
  lock: LockIcon,
  magnifyingGlass: MagnifyingGlassIcon,
  meetAtDoor: MeetAtDoorIcon,
  megaphone: MegaphoneIcon,
  menu: MenuIcon,
  minus: MinusIcon,
  money: MoneyIcon,
  newWindow: NewWindowIcon,
  orderDetails: OrderDetailsIcon,
  page: PageIcon,
  pencil: PencilIcon,
  people: PeopleIcon,
  person: PersonIcon,
  phone: PhoneIcon,
  pin: PinIcon,
  pizzaLeft: PizzaLeftIcon,
  pizzaRight: PizzaRightIcon,
  pizzaSlice: PizzaSliceIcon,
  pizzaWhole: PizzaWholeIcon,
  play: PlayIcon,
  plus: PlusIcon,
  register: RegisterIcon,
  share: ShareIcon,
  shop: ShopIcon,
  sparkle: SparkleIcon,
  tag: TagIcon,
  trashCan: TrashCanIcon,
  verification: VerificationIcon,
  wallet: WalletIcon,
  warning: WarningIcon,
  website: WebsiteIcon,
} as const;

type IconName = keyof typeof icons;
type IconSize = 'default' | 'large';

export type IconProps = ComponentPropsWithoutRef<typeof BellIcon> & {
  icon: IconName;
  size?: IconSize;
};

const toKebabCase = (name: string) =>
  name.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);

export const Icon = forwardRef(function Icon(
  { className, icon, size = 'default', ...props }: IconProps,
  ref: ForwardedRef<ElementRef<'svg'>>,
) {
  const Component = icons[icon];
  return (
    <Component
      aria-hidden="true"
      className={classNames(getCrustClassName('icon'), className)}
      data-icon={toKebabCase(icon)}
      data-size={size}
      ref={ref}
      {...props}
    />
  );
});
