import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductPrintSettingsResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';

import { getProductQueryKey } from './use-product-query';

export const getProductPrintSettingsQueryKey = (
  shopId: Shop['shopId'],
  productId: MenuProduct['id'],
): QueryKey => [...getProductQueryKey(shopId, productId), 'printSettings'];

export const useProductPrintSettingsQuery = (
  shopId: Shop['shopId'],
  productId: MenuProduct['id'],
  options?: UseQueryOptions<MenuProductPrintSettingsResponseBody>,
): UseQueryResult<MenuProductPrintSettingsResponseBody> => {
  const api = useApi();
  return useQuery({
    queryKey: getProductPrintSettingsQueryKey(shopId, productId),
    queryFn: () => api.getProductPrintSettings(shopId, productId),
    ...options,
  });
};
