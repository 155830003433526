import { useEffect, useState } from 'react';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import { useWindowSize } from 'hooks/use-window-size';
import {
  HoursAnalyticsFeedbackValue,
  HoursAnalyticsStorageKey,
} from 'types/hours-analytics';

import styles from './styles.module.scss';

const desktopCopy = 'Is this helpful?';
const mobileCopy = 'Helpful?';
const hideFeedbackMilliseconds = 3000;

type Props = {
  contentDescription: string;
  hoursAnalyticsStorageKey: HoursAnalyticsStorageKey;
  shopId: number;
  tile: string;
};

const Feedback = ({
  contentDescription,
  hoursAnalyticsStorageKey,
  shopId,
  tile,
}: Props) => {
  const { isMobile } = useWindowSize();
  const { trackSubmittedProductFeedbackSurvey } = useAnalytics();

  const [storedFeedback, setStoredFeedback] = useStoredState(
    `${shopId}-${hoursAnalyticsStorageKey}`,
  );

  const [isFeedbackProvided, setIsFeedbackProvided] = useState(storedFeedback);
  const [shouldHideFeedback, setShouldHideFeedback] = useState(storedFeedback);

  const copy = isMobile ? mobileCopy : desktopCopy;

  useEffect(() => {
    if (isFeedbackProvided) {
      const timeout = setTimeout(() => {
        setShouldHideFeedback(true);
      }, hideFeedbackMilliseconds);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isFeedbackProvided]);

  const handleClick = (variant: HoursAnalyticsFeedbackValue): void => {
    trackSubmittedProductFeedbackSurvey({
      shopId,
      page: 'hours analytics',
      tile,
      copy: 'is this helpful?',
      response: variant,
    });

    setStoredFeedback(variant);
    setIsFeedbackProvided(true);
  };

  let content;
  if (shouldHideFeedback) {
    content = <></>;
  } else if (isFeedbackProvided) {
    content = <div className={styles.text}>Thanks for the feedback!</div>;
  } else {
    content = (
      <>
        <div className={styles.text}>{copy}</div>
        <Button
          className={styles.button}
          data-testid="thumbs-up-button"
          variant={ButtonVariant.Transparent}
          onClick={() => handleClick('thumbs up')}
          title={`Provide feedback that this ${contentDescription} is helpful.`}
        >
          <span className={styles.icon}>👍</span>
        </Button>
        <Button
          className={styles.button}
          data-testid="thumbs-down-button"
          variant={ButtonVariant.Transparent}
          onClick={() => handleClick('thumbs down')}
          title={`Provide feedback that this ${contentDescription} is not helpful.`}
        >
          <span className={styles.icon}>👎</span>
        </Button>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Feedback;
