import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { ShopScoreResponse } from 'types/shops';

import { getShopQueryKey } from './use-shop-query';

export const getShopScoreQueryKey = (shopId: number): QueryKey => [
  ...getShopQueryKey(shopId),
  'shop-score',
];

// Until all components read the current shop ID as a number and not a string,
// we'll allow the string and convert it to a number.
export const useShopScoreQuery = (
  shopId: number | string,
): UseQueryResult<ShopScoreResponse, ApiRequestError> => {
  const api = useApi();
  return useQuery(getShopScoreQueryKey(Number(shopId)), () =>
    api.shopScoreLoad(Number(shopId)),
  );
};
