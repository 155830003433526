import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops/shop';
import { sortRelationships } from 'utilities/menu';

import { getMenuQueryKey } from './use-menu-query';

export const getProductQueryKey = (
  shopId: Shop['shopId'] | string,
  productId: MenuProduct['id'],
): QueryKey => [...getMenuQueryKey(shopId), 'products', productId];

export const useProductQuery = (
  shopId: Shop['shopId'],
  productId: MenuProduct['id'],
  options?: UseQueryOptions<MenuProductResponseBody>,
): UseQueryResult<MenuProductResponseBody> => {
  const api = useApi();

  return useQuery({
    queryKey: getProductQueryKey(shopId, productId),
    queryFn: async () => {
      const response = await api.getProduct(shopId, productId);
      return sortRelationships(response);
    },
    ...options,
  });
};
