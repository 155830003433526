import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CustomerListDropdown from 'components/customers/customer-list-dropdown';
import CustomerTypeDropdown from 'components/customers/customer-type-dropdown';
import DateRangeDropdown from 'components/customers/date-range-dropdown';
import { RegisterUpsellModal } from 'components/register/register-splash/register-upsell-modal';
import useCustomersAnalyticsQuery, {
  getCustomersAnalyticsQueryKey,
} from 'hooks/customers/use-customers-analytics-query';
import { useCustomersFiltersStorage } from 'hooks/customers/use-customers-filters-storage';
import useTop10CustomersQuery from 'hooks/customers/use-top-10-customers-query';
import { useRegisterUpsellModal } from 'hooks/salesforce/use-register-upsell-modal';
import useAnalytics from 'hooks/use-analytics';
import {
  BaseCustomer,
  CustomerList,
  CustomerSegmentsLocationState,
  RegistrationType,
  Top10CustomersType,
} from 'types/customers';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  CustomerListLabels,
  customerTypeLabels,
  dateRangeLabels,
  getCustomerListOptions,
  getCustomerTypeOptions,
  getDateRangeOptions,
} from 'utilities/customers';
import { getShopHasFullRegister } from 'utilities/shops';

import CustomerDetailsModal from '../customer-details-modal';

import CustomerSegmentsAnalyticsTiles from './analytics-tiles';
import FavoriteItem from './favorite-item';
import KnowYourCustomers from './know-your-customers';
import CustomerSegmentsTableTile from './table-tile';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  user: User;
};

const CustomerSegments = ({ shop, user }: Props) => {
  const { trackUpdatedCustomerSegmentsPage } = useAnalytics();

  const shopId = String(shop.shopId);
  const timezone = shop.timezoneIdentifier;

  const {
    storedCustomerList,
    setStoredCustomerList,
    storedCustomerType,
    setStoredCustomerType,
    storedDateRange,
    setStoredDateRange,
  } = useCustomersFiltersStorage();

  const { options: customerListOptions, defaultCustomerListOption } =
    getCustomerListOptions();
  const { options: customerTypeOptions, defaultCustomerTypeOption } =
    getCustomerTypeOptions(getShopHasFullRegister(shop));
  const { options: dateRangeOptions, defaultDateRangeOption } =
    getDateRangeOptions();

  // Check if filter state has been provided upon navigation.
  // If so, update stored values based on the navigation state.
  // This avoids a race condition in which navigation occurs
  // before local storage is updated in the useCustomersFiltersStorage hook.
  const initialLocationState: CustomerSegmentsLocationState =
    useLocation().state;
  const [locationState, setLocationState] = useState(initialLocationState);

  let storedCustomerListOption;
  let storedCustomerTypeOption;
  let storedDateRangeOption;

  if (locationState?.customerList) {
    storedCustomerListOption = customerListOptions.find(
      (option) => option.value === locationState.customerList,
    );

    setStoredCustomerList(storedCustomerListOption?.value);
  } else if (storedCustomerList) {
    storedCustomerListOption = customerListOptions.find(
      (option) => option.value === storedCustomerList,
    );
  }

  if (locationState?.orderType) {
    storedCustomerTypeOption = customerTypeOptions.find(
      (option) => option.value === locationState.orderType,
    );

    setStoredCustomerType(storedCustomerTypeOption?.value);
  } else if (storedCustomerType) {
    storedCustomerTypeOption = customerTypeOptions.find(
      (option) => option.value === storedCustomerType,
    );
  }

  if (locationState?.resetDateRange) {
    storedDateRangeOption = defaultDateRangeOption;
    setStoredDateRange(storedDateRangeOption.value);
  } else if (storedDateRange) {
    storedDateRangeOption = dateRangeOptions.find(
      (option) => option.value === storedDateRange,
    );
  }

  if (locationState && Object.keys(locationState).length > 0) {
    // Update location state to reflect that state has been processed.
    setLocationState({});
  }

  const [customerList, setCustomerList] = useState(
    storedCustomerListOption?.value || defaultCustomerListOption.value,
  );
  const [customerType, setCustomerType] = useState(
    storedCustomerTypeOption?.value || defaultCustomerTypeOption.value,
  );
  const [dateRange, setDateRange] = useState(
    storedDateRangeOption?.value || defaultDateRangeOption.value,
  );

  const isCustomerListTop10 =
    customerList === CustomerList.MostValuable ||
    customerList === CustomerList.MostFrequent;

  const { data: top10CustomersResponse, isLoading: isTop10CustomersLoading } =
    useTop10CustomersQuery({
      customerType,
      dateRange,
      top10CustomersType:
        customerList === CustomerList.MostValuable
          ? Top10CustomersType.MostValuable
          : Top10CustomersType.MostFrequent,
      shopId,
      timezone,
      options: {
        enabled: isCustomerListTop10,
      },
    });

  const areNonTop10QueriesEnabled = !(
    isCustomerListTop10 && isTop10CustomersLoading
  );

  const customerUuids = useMemo((): string[] => {
    if (
      !isCustomerListTop10 ||
      isTop10CustomersLoading ||
      !top10CustomersResponse?.data
    ) {
      return [];
    }

    const top10Customers = top10CustomersResponse.data;

    return top10Customers.reduce((uuids: string[], customer) => {
      uuids.push(customer.uuid);
      return uuids;
    }, []);
  }, [top10CustomersResponse, isTop10CustomersLoading, isCustomerListTop10]);

  const customerAnalyticsQueryKey = getCustomersAnalyticsQueryKey({
    customerType,
    dateRange,
    registrationType: RegistrationType.Registered,
    shopId,
    customerList,
    customerUuids,
  });

  const { data: customersAnalytics, isLoading: isCustomersAnalyticsLoading } =
    useCustomersAnalyticsQuery({
      customerType,
      dateRange,
      registrationType: RegistrationType.Registered,
      shopId,
      timezone,
      customerList,
      customerUuids,
      options: {
        enabled: areNonTop10QueriesEnabled,
      },
    });

  useEffect(() => {
    if (!isCustomersAnalyticsLoading) {
      let customerCount = 0;

      if (customersAnalytics?.usersCount) {
        customerCount = customersAnalytics.usersCount;
      }

      trackUpdatedCustomerSegmentsPage(
        shopId,
        CustomerListLabels[customerList],
        customerTypeLabels[customerType],
        dateRangeLabels[dateRange],
        customerCount,
        customersAnalytics?.topProduct
          ? customersAnalytics.topProduct.productId
          : undefined,
        !!customersAnalytics?.topProduct?.image,
      );
    }
    // We want to fire the event when the request to update the data is returned.
    // We also want the event to fire upon change of date range, customer list,
    // or customer type (otherwise the event would not fire again if there was
    // cached data).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomersAnalyticsLoading, dateRange, customerList, customerType]);

  const [selectedCustomer, setSelectedCustomer] = useState<BaseCustomer | null>(
    null,
  );

  const {
    handleModalClose,
    handleOpenModalClick,
    hasValidCase,
    openedModal,
    setCaseExpiration,
  } = useRegisterUpsellModal(shop.shopId);

  const handleCloseCustomerDetailsModalClick = () => {
    setSelectedCustomer(null);
  };

  return (
    <div>
      <div
        className={styles.headerContainer}
        data-chameleon-target="Customer Segments Header Container"
      >
        <span className={styles.headerTitle}>
          {'Which customers do you want to see?'}
        </span>
        <div className={styles.filtersContainer}>
          <CustomerListDropdown
            className={styles.customerListDropdown}
            customerList={customerList}
            customerListOptions={customerListOptions}
            setCustomerList={setCustomerList}
            shopId={shopId}
            setStoredCustomerList={setStoredCustomerList}
          />
          <CustomerTypeDropdown
            className={styles.customerTypeDropdown}
            customerType={customerType}
            customerTypeOptions={customerTypeOptions}
            hasValidCase={hasValidCase}
            onRequestDemoButtonClick={handleOpenModalClick}
            page="customer segments"
            setCustomerType={setCustomerType}
            setStoredCustomerType={setStoredCustomerType}
            shop={shop}
          />
          <DateRangeDropdown
            dateRange={dateRange}
            dateRangeOptions={dateRangeOptions}
            page="customer segments"
            setDateRange={setDateRange}
            setStoredDateRange={setStoredDateRange}
            shopId={shopId}
          />
        </div>
      </div>
      <div className={styles.knowYourCustomersAndFavoriteItem}>
        <KnowYourCustomers
          customerList={customerList}
          customerType={customerType}
          dateRange={dateRange}
        />
        {!isCustomersAnalyticsLoading && customersAnalytics?.topProduct && (
          <FavoriteItem
            customerAnalyticsQueryKey={customerAnalyticsQueryKey}
            customerList={customerList}
            shopId={shopId}
            topProduct={customersAnalytics.topProduct}
          />
        )}
      </div>
      <CustomerSegmentsAnalyticsTiles
        customersAnalytics={customersAnalytics}
        isLoading={isCustomersAnalyticsLoading}
      />
      <CustomerSegmentsTableTile
        areNonTop10QueriesEnabled={areNonTop10QueriesEnabled}
        customerList={customerList}
        customerType={customerType}
        customerUuids={customerUuids}
        dateRange={dateRange}
        setSelectedCustomer={setSelectedCustomer}
        shopId={shopId}
        timezone={timezone}
      />
      {selectedCustomer && (
        <CustomerDetailsModal
          shopId={Number(shopId)}
          selectedCustomer={selectedCustomer}
          handleCloseCustomerDetailsModalClick={
            handleCloseCustomerDetailsModalClick
          }
        />
      )}

      <RegisterUpsellModal
        isOpen={openedModal}
        onRequestClose={handleModalClose}
        page="customer analytics"
        setCaseExpiration={setCaseExpiration}
        shopId={shop.shopId}
        user={user}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegments;
