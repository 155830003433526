import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

import { getShopsQueryKey } from './use-shops-query';

export const getShopQueryKey = (id: number | string): QueryKey => [
  ...getShopsQueryKey(),
  Number(id),
];

// Until all components read the current shop ID as a number and not a string,
// we'll allow the string and convert it to a number.
export const useShopQuery = (
  id: number | string,
  options?: UseQueryOptions<Shop>,
): UseQueryResult<Shop> => {
  const api = useApi();

  options = {
    staleTime: 60000, // One minute.
    ...options,
  };

  return useQuery(getShopQueryKey(id), () => api.getShop(id), options);
};
