import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import {
  GetReportsRequestParams,
  GetSalesSummaryReportsResponse,
} from 'types/financials';
import { toSnakeCase } from 'utilities/strings';

type UseRegisterSalesSummaryReportsQueryOptions = Omit<
  UseQueryOptions<GetSalesSummaryReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getRegisterSalesSummaryReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [
  shopId,
  'registerSalesSummaryReports',
  ...(params ? [params] : []),
];

export const useRegisterSalesSummaryReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseRegisterSalesSummaryReportsQueryOptions,
): UseQueryResult<GetSalesSummaryReportsResponse> => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getRegisterSalesSummaryReportsQueryKey(shopId, params),
    queryFn: () => api.getRegisterSalesSummaryReports(shopId, params),
    keepPreviousData: true,
    ...options,
  });
};
