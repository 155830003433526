import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  CreateOrdersReportResponse,
  OrdersReportFormValues,
} from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

import { getOrdersReportsQueryKey } from './use-orders-reports-query';

export const useCreateOnlineOrdersReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
): UseMutationResult<
  CreateOrdersReportResponse,
  ApiRequestError,
  OrdersReportFormValues
> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: OrdersReportFormValues) => {
      return api.createOnlineOrdersReport(shopId, {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getOrdersReportsQueryKey(shopId));
    },
  });
};
