import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { PartnerSuccessManagerInfo } from 'types/partner-success-manager';

export const getPsmInfoQueryKey = (shopId: string): QueryKey => [
  shopId,
  'psmInfo',
];

export const usePsmInfoQuery = (
  shopId: string,
): UseQueryResult<PartnerSuccessManagerInfo> => {
  const api = useApi();

  return useQuery(getPsmInfoQueryKey(shopId), () =>
    api.getPartnerSuccessManagerInfo(shopId),
  );
};
