import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategory, MenuCategoryFormValues } from 'types/menu/category';
import { Shop } from 'types/shops';
import { getCategoryBody } from 'utilities/menu';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';

export const useSaveCategoryMutation = (
  shopId: Shop['shopId'],
  original?: MenuCategory,
): UseMutationResult<
  MenuCategoryResponseBody,
  ApiRequestError,
  MenuCategoryFormValues
> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values) => {
      const body = getCategoryBody(values, original);
      const isNew = typeof body.id === 'string';

      return isNew
        ? api.postCategory(shopId, body)
        : api.putCategory(shopId, body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
