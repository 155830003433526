import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  CreateSalesSummaryReportResponse,
  SalesSummaryReportFormValues,
} from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

import { getSalesSummaryReportsQueryKey } from './use-sales-summary-reports-query';

export const useCreateSalesSummaryReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
): UseMutationResult<
  CreateSalesSummaryReportResponse,
  ApiRequestError,
  SalesSummaryReportFormValues
> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (values: SalesSummaryReportFormValues) =>
      api.createSalesSummaryReport(shopId, {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
        order_types:
          values.order_types === 'offline' || values.order_types === 'online'
            ? values.order_types
            : undefined,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getSalesSummaryReportsQueryKey(shopId));
      },
    },
  );
};
