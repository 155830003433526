export const helpCenter = 'https://slicelife.com/owners/faqs';
export const marketingGoogleDrive =
  'https://drive.google.com/drive/folders/1B5GdYrlD0XiXzeyLuGIrY0jI7y4tCstg';
export const sliceCommunity = 'https://blog.slicelife.com/category/shop-tips/';
export const sliceShop = 'https://supplies.slicelife.com/';
export const squareFaq = 'https://slicelife.com/owners/squarefaq';
export const termsOfUse = 'https://slicelife.com/pages/terms-of-use';
export const directDeposit =
  'https://drive.google.com/file/d/1BXb-DEcyLiMDaNzTVpsa7wQJFDRYQ7vZ/view';
export const sliceAccelerate = 'https://www.accelerate.slicelife.com/';
export const sliceIndeed = 'https://www.indeed.com/partner/slice_2021';
export const marketingServices =
  'https://slicelife.partners/marketing-services';
export const websiteIntegrationsInstructions =
  'https://slicelife.com/pages/website-integrations-instructions';
export const restaurantAgreement =
  'https://slicelife.com/owners/onlinerestaurantagreement';
export const registerUserGuide =
  'https://sites.google.com/slicelife.com/registerhelp/home';
export const shopReferral =
  'https://slicelife.partners/referral?SQF_SOURCE=ownersportal';
export const sliceRegister = 'https://sliceregister.com/';
export const statementsFaq =
  'https://sites.google.com/slicelife.com/owners-portal-help/home/faq';
export const statementsDefinitions =
  'https://sites.google.com/slicelife.com/owners-portal-help/home/statement-definitions';
export const customWebsiteDemo = 'https://www.slicecustomwebsitedemo.com/';
export const customerImportTemplateUrl =
  'https://docs.google.com/spreadsheets/d/14J4tV7A8Zof41czBCDdFObPiX7T6i3Ot-Xp3bKgYnhk';
export const scamAdviceUrl = 'https://slicelife.partners/scamadvice';
