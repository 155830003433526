import { useMutation, UseMutationResult } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { MenuPhotoUploadResponseBody } from 'types/menu/api';

type Props = {
  page: string;
  pageDetail?: string;
  productId: number;
  shopId: string;
};

type UsePhotoUploadMutation = UseMutationResult<
  MenuPhotoUploadResponseBody,
  ApiRequestError,
  string | Blob
>;

export const usePhotoUploadMutation = ({
  page,
  pageDetail,
  productId,
  shopId,
}: Props): UsePhotoUploadMutation => {
  const { uploadProductImage } = useApi();

  const { trackProductImageUpload } = useAnalytics();

  return useMutation<
    MenuPhotoUploadResponseBody,
    ApiRequestError,
    string | Blob
  >({
    mutationFn: (image) => uploadProductImage(shopId, image),
    onSuccess: (response, image) => {
      const url = response.sources.imgix;

      trackProductImageUpload({
        id: productId,
        shopId,
        productImageFile: image,
        imageUrl: url,
        page,
        pageDetail,
      });
    },
  });
};
