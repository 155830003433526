/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';

import {
  AssetLibraryAsset,
  AssetLibraryCategory,
  NetworkAssetLibraryCategory,
  NetworkGetAssetLibraryAssetsResponse,
  networkToDomainAssetLibraryAssets,
  networkToDomainAssetLibraryCategories,
} from 'types/asset-library';
import {
  CampaignManagerHistory,
  CampaignManagerSummary,
  NetworkCampaignManagerHistory,
  NetworkCampaignManagerSummary,
} from 'types/campaign-manager';
import ClosingReason from 'types/closing-reasons';
import {
  CustomerReviewReply,
  CustomerReviewReplyBody,
} from 'types/customer-feedback';
import {
  CustomerCounts,
  CustomerList,
  CustomerOrderProfile,
  CustomersAnalytics,
  CustomerSort,
  CustomersResponse,
  CustomerType,
  Interval,
  PagedCustomerProfileOrder,
  RegistrationType,
} from 'types/customers';
import {
  CreateCustomerExportParams,
  CreateCustomerUploadPresignedUrlParams,
  CreateCustomerUploadPresignedUrlResponse,
  Customer,
  GetProfileCustomersMeta,
  GetProfileCustomersParams,
  GetProfileCustomersResponse,
  UpdateCustomerUploadStatusParams,
  UpdateCustomerUploadStatusResponse,
} from 'types/customers/profile/customer-profile';
import { CustomerImport } from 'types/customers/profile/customer-profile-import';
import { DailyHours } from 'types/daily-hours';
import { DebitCards } from 'types/debit-cards';
import {
  CreateOrderActivityReportRequestParams,
  CreateOrdersReportRequestParams,
  CreateOrdersReportResponse,
  CreateRegisterSalesSummaryReportRequestParams,
  CreateSalesMixReportRequestParams,
  CreateSalesMixReportResponse,
  CreateSalesSummaryReportResponse,
  CreateSalesSummaryRequestParams,
  CreateStandalonePaymentsReportRequestParams,
  CreateStandalonePaymentsReportResponse,
  CreateTaxReportRequestParams,
  GetReportsRequestParams,
  GetReportsResponse,
  OrderActivityReport,
  OrdersReport,
  SalesMixReport,
  SalesSummaryReport,
  StandalonePaymentsReport,
  TaxReport,
} from 'types/financials';
import {
  OnlineOrdersRequestParams,
  OnlineOrdersResponse,
} from 'types/financials/online-orders';
import {
  GetStatementsParams,
  GetStatementsResponse,
  Statement,
} from 'types/financials/statements';
import {
  AvailabilitySummaryWithoutAsOfText,
  GetShopStatusHistoryRequestParams,
  GetShopStatusHistoryResponse,
} from 'types/hours-analytics';
import {
  MenuCategoryRequestBody,
  MenuCategoryResponseBody,
  MenuPhotoUploadResponseBody,
  MenuProductPrintSettingsRequestBody,
  MenuProductPrintSettingsResponseBody,
  MenuProductRequestBody,
  MenuProductResponseBody,
  MenuProductsResponseBody,
  MenuProductsStockRequestBody,
  MenuProductStockRequest,
  MenuResponseBody,
  SortMenuCategoriesRequestBody,
  SortMenuProductsRequestBody,
} from 'types/menu/api';
import { MenuCategory } from 'types/menu/category';
import { MenuProduct, MenuProductType } from 'types/menu/product';
import { OpasFilters, ProductSalesMetricsResponse } from 'types/opas';
import { OpenForToday } from 'types/open-for-today';
import { OrderStatement, OrderStatementResponse } from 'types/order-statement';
import {
  RegisterMetrics,
  TipsAndEarningsRequestParams,
  TipsAndEarningsResponseBody,
} from 'types/orders';
import { PartnerSuccessManagerInfo } from 'types/partner-success-manager';
import {
  GetPayoutsRequestParams,
  Payout,
  PayoutsResponse,
} from 'types/payouts';
import { RegisterLayout } from 'types/register-layout';
import {
  NetworkRosOrderMetrics,
  networkToDomainRosOrderMetrics,
  RegisterOrdersRequestParams,
  RegisterOrdersResponse,
  SingleRegisterOrder,
} from 'types/register-orders';
import {
  CreateRegisterPrinterValues,
  EditRegisterPrinterValues,
  NetworkRegisterPrinter,
  RegisterPrinter,
} from 'types/register-printing';
import {
  RegisterProfile,
  RegisterProfileRequestBody,
} from 'types/register-profile';
import { RegisterTipSettings } from 'types/register-tip-settings';
import {
  CreateEditPermissionBody,
  GetPermissionsLogResponse,
  NetworkCreateEditRegisterPermissionGroupResponse,
  NetworkGetPermissionsLogResponse,
  NetworkGetRecommendedPermissionGroupsResponse,
  NetworkGetRegisterUsersResponse,
  NetworkPermissionsToggleResponse,
  NetworkRegisterPermissionGroup,
  NetworkRegisterUser,
  NewRegisterUser,
  PermissionsInfo,
  PermissionsLogRequestParams,
  RegisterPermissionGroup,
  RegisterUser,
} from 'types/register-users';
import { CreateSalesforceCase } from 'types/salesforce';
import { Pause, Schedule } from 'types/shop-hours';
import {
  NetworkShopTraits,
  Shop,
  ShopScoreResponse,
  ShopsResponse,
  ShopTraits,
} from 'types/shops';
import { SiteEditorSettings } from 'types/site-editor';
import { createCustomerFilter } from 'utilities/customers';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { networkToDomainRegisterPrinter } from 'utilities/register-printers';
import {
  networkGetRegisterUsersResponseToRegisterUsers,
  networkRecommendedPermissionGroupsResponseToDomainInfo,
  networkRegisterUserToRegisterUser,
  networkToDomainRegisterPermissionGroup,
} from 'utilities/register-users';

import { ApiRequestError } from './helpers';

// This typescript rule is disabled to allow for type inferring of the API. This means in typescript files
// that make use of the useAPI hook, if the specific api function is typed the returned data will also be typed.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const create = (
  authenticatedFetch: {
    get: any;
    put: any;
    patch: any;
    post: any;
    del: any;
    delete: any;
    postFile: any;
  },
  hostnames: { restaurantApi: any },
) => ({
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
  getDailyHours: (shopId: string): DailyHours =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/daily_hours`,
      )
      .then((data: any) => camelCaseKeys(data)),

  updateDailyHours: (shopId: any, params: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/daily_hours`,
        params,
      )
      .then(({ hours }: any) => hours),

  getClosings: (shopId: any) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings`,
      )
      .then(({ data }: any) => {
        // Formatting the closing data to allow easier
        // interactions with the closureReason prop
        // at the component level e.g. sorting
        const closings = data.map((closing: any) => ({
          ...closing,
          closureReason: closing.closure_reason.id,
          closureReasonName: closing.closure_reason.name,
        }));
        return camelCaseKeys(closings);
      }),

  deleteClosing: (shopId: any, closingId: any) =>
    authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings/${closingId}`,
    ),

  createClosing: (shopId: any, closingParams: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings`,
        closingParams,
      )
      .then(({ data }: any) => camelCaseKeys(data)),

  async createOrderActivityReport(
    shopId: number,
    params: CreateOrderActivityReportRequestParams,
  ): Promise<OrderActivityReport> {
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/order_activity_reports`,
      snakeCaseKeys(params),
    );

    return camelCaseKeys(response);
  },

  async getOrderActivityReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<OrderActivityReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/order_activity_reports?${query}`,
      {},
      true,
      true,
      true,
    );

    // Normalize the response pagination values to be a part of the response
    // similar to OPAS rather than dealing with headers outside API layer.
    return {
      data: camelCaseKeys(response.body) as OrderActivityReport[],
      meta: {
        pagination: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          total: Number(response.headers.get('X-Total') ?? 0),
        },
      },
    };
  },

  async createBasicTaxReport(
    shopId: number,
    params: CreateTaxReportRequestParams,
  ): Promise<TaxReport> {
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/tax_reports`,
      params,
    );

    return camelCaseKeys(response);
  },

  async getBasicTaxReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<TaxReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/tax_reports?${query}`,
      {},
      true,
      true,
      true,
    );

    // Normalize the response pagination values to be a part of the response
    // similar to OPAS rather than dealing with headers outside API layer.
    return {
      data: camelCaseKeys(response.body) as TaxReport[],
      meta: {
        pagination: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          total: Number(response.headers.get('X-Total') ?? 0),
        },
      },
    };
  },

  async createSalesMixReport(
    shopId: number,
    params: CreateSalesMixReportRequestParams,
  ): Promise<CreateSalesMixReportResponse> {
    const query = `${queryString.stringify(params)}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/sales_mix_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getSalesMixReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<SalesMixReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/sales_mix_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getRegisterSalesSummaryReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<SalesSummaryReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/sales_summary_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getSalesSummaryReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<SalesSummaryReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/sales_summary_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async createRegisterSalesSummaryReport(
    shopId: number,
    params: CreateRegisterSalesSummaryReportRequestParams,
  ): Promise<CreateSalesSummaryReportResponse> {
    const query = `${queryString.stringify(params)}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/sales_summary_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async createSalesSummaryReport(
    shopId: number,
    params: CreateSalesSummaryRequestParams,
  ): Promise<CreateSalesSummaryReportResponse> {
    const query = `${queryString.stringify(params)}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/sales_summary_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getStandalonePaymentsReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<StandalonePaymentsReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/standalone_payments_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async createStandalonePaymentsReport(
    shopId: number,
    params: CreateStandalonePaymentsReportRequestParams,
  ): Promise<CreateStandalonePaymentsReportResponse> {
    const query = `${queryString.stringify(params)}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/standalone_payments_reports?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getOrdersReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<OrdersReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/order_ledger?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getOfflineOrderReports(
    shopId: number,
    params: GetReportsRequestParams,
  ): Promise<GetReportsResponse<OrdersReport>> {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/offline_order_ledger?${query}`,
    );

    return camelCaseKeys(response);
  },

  async createOfflineOrdersReport(
    shopId: number,
    params: CreateOrdersReportRequestParams,
  ): Promise<CreateOrdersReportResponse> {
    const fullParams = {
      ...params,
      forceUpdate: true,
    };
    const query = `${queryString.stringify(snakeCaseKeys(fullParams))}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/offline_order_ledger?${query}`,
    );

    return camelCaseKeys(response);
  },

  async createOnlineOrdersReport(
    shopId: number,
    params: CreateOrdersReportRequestParams,
  ): Promise<CreateOrdersReportResponse> {
    const fullParams = {
      ...params,
      forceUpdate: true,
    };
    const query = `${queryString.stringify(snakeCaseKeys(fullParams))}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/online_order_ledger?${query}`,
    );

    return camelCaseKeys(response);
  },

  updateClosing: (shopId: any, closingId: any, closingParams: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings/${closingId}`,
        closingParams,
      )
      .then(({ data }: any) => camelCaseKeys(data)),

  getTipsAndEarnings: (
    shopId: Shop['shopId'],
    params: TipsAndEarningsRequestParams,
  ): Promise<TipsAndEarningsResponseBody> => {
    const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders/tips_and_earnings${queryStr}`,
      )
      .then(camelCaseKeys);
  },

  getRosOrderMetrics: (shopId: any, query = {}): Promise<RegisterMetrics> => {
    const queryStr = `?${queryString.stringify(query)}`;

    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v2/ros/shops/${shopId}/orders/metrics/${queryStr}`,
      )
      .then(
        (response: NetworkRosOrderMetrics): RegisterMetrics =>
          networkToDomainRosOrderMetrics(response),
      );
  },

  getOrders: async (
    shopId: string,
    params: OnlineOrdersRequestParams,
  ): Promise<OnlineOrdersResponse> => {
    const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders${queryStr}`,
      {},
      true,
      true,
      true,
    );

    response.body.meta = {
      ...response.body.meta,
      pagination: {
        pages: Number(response.headers.get('X-Total-Pages') ?? 0),
        total: Number(response.headers.get('X-Total') ?? 0),
      },
    };

    return camelCaseKeys(response.body);
  },

  getRegisterOrders: async (
    shopId: string,
    params: RegisterOrdersRequestParams,
  ): Promise<RegisterOrdersResponse> => {
    const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/ros/shops/${shopId}/orders${queryStr}`,
      {},
      true,
      true,
      true,
    );

    return camelCaseKeys(response.body);
  },

  getRegisterOrder: async (
    shopId: string,
    orderId: string,
  ): Promise<SingleRegisterOrder> => {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/ros/shops/${shopId}/orders/${orderId}`,
      {},
      true,
      true,
      true,
    );

    return response.body;
  },

  getOrderStatement: async (
    shopId: number,
    orderId: string,
  ): Promise<OrderStatementResponse> => {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders/${orderId}/statement`,
    );

    return camelCaseKeys(response);
  },

  // Even though the confirmation metric tile has been removed, we intend to use
  // this endpoint again in the future.
  // https://app.clubhouse.io/slicelife/story/220935/remove-confirmation-metric-tile-and-redux-store
  getShopConfirmationMetrics: (shopId: any, intervalEndDate: any) => {
    const queryStr = queryString.stringify({
      interval_end_date: intervalEndDate,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/confirmation_time?${queryStr}`,
      )
      .then((response: { confirmation_metrics: any }) => ({
        // The corresponding saga wants to index the response by shopId, but a singular nested
        // object is returned. Putting the returned metrics into an array satisifies the saga
        // expectations for now.
        response: [camelCaseKeys(response.confirmation_metrics)],
      }))
      .catch((error: any) => ({ error }));
  },

  getShopOrderSummary: (
    shopId: any,
    startDate: any,
    endDate: any,
    shopTimezone: any,
  ) => {
    const queryStr = queryString.stringify({
      end: endDate,
      start: startDate,
      timezone: shopTimezone,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/orders/by/day?${queryStr}`,
      )
      .then(camelCaseKeys);
  },

  getShopOpenForToday: (
    shopId: any,
  ): {
    response: OpenForToday;
  } =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/open_for_today`,
      )
      .then((response: any) => camelCaseKeys(response.data)),

  getShopSchedule: (shopId: any): Promise<Schedule> =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/v1/shops/${shopId}/schedule`)
      .then(camelCaseKeys),

  generateShopSchedule: (
    shopId: string,
    generateScheduleParams: any,
  ): Promise<Schedule> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/v1/shops/${shopId}/schedule/generate`,
        generateScheduleParams,
      )
      .then(camelCaseKeys),

  updateShopOpenForToday: (shopId: any, openForTodayParams: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/open_for_today`,
        openForTodayParams,
      )
      .then(({ data }: any) => ({ response: camelCaseKeys(data) }))
      .catch((error: any) => ({ error })),

  getShopOpenings: (shopId: any) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings`,
      )
      .then(camelCaseKeys),

  createShopOpening: ({ shopId, opening }: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings`,
        { opening },
      )
      .then(camelCaseKeys),

  deleteShopOpening: ({ shopId, openingId, openFor, dayOfWeek }: any) =>
    authenticatedFetch
      .del(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings/${openingId}`,
      )
      .then(() => ({
        shopId,
        openingId,
        openFor,
        dayOfWeek,
        type: 'delete',
      })),

  updateShopOpening: ({ shopId, openingId, opening }: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings/${openingId}`,
        { opening },
      )
      .then(camelCaseKeys),

  getShops: (): Promise<ShopsResponse> =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/shops?role=shop_owner`)
      .then(camelCaseKeys),

  getShop: (id: number | string): Promise<Shop> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${id}?role=shop_owner`,
      )
      .then(camelCaseKeys),

  async getShopStatements(shopId: Shop['shopId']): Promise<OrderStatement[]> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/statements`,
    );

    return camelCaseKeys(response.statements);
  },

  async getShopStatementsV2(
    shopId: Shop['shopId'],
    params: GetStatementsParams,
  ): Promise<GetStatementsResponse> {
    const query = `?${queryString.stringify(snakeCaseKeys(params))}`;

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/statements${query}`,
      {},
      true,
      true,
      true,
    );

    return {
      statements: camelCaseKeys(response.body) as Statement[],
      meta: {
        pages: Number(response.headers.get('X-Total-Pages') ?? 0),
        results: Number(response.headers.get('X-Total') ?? 0),
      },
    };
  },

  async getDebitCards(shopId: number): Promise<DebitCards[]> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/debit_cards`,
    );

    return camelCaseKeys(response.data);
  },

  async getStatement(
    shopId: Shop['shopId'],
    statementId: OrderStatement['id'],
  ): Promise<OrderStatementResponse> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/statements/${statementId}`,
    );

    return camelCaseKeys(response.statement);
  },

  getCurrentUser: () =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/me`)
      .then(camelCaseKeys),

  putPasswordReset: (password: any, passwordResetToken: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/password_resets`,
        {
          password,
          password_reset_token: passwordResetToken,
        },
        {},
        true,
        false,
        true,
      )
      .then(camelCaseKeys),

  postSendPasswordResetEmail: (email: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/password_resets`,
        { email },
        {},
        true,
        false,
        true,
      )
      .then(camelCaseKeys),

  getPosIntegration: (shopId: string) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration`,
      )
      .then(camelCaseKeys)
      .catch((error: ApiRequestError) => {
        // A 404 here is fine, it just means the shop has no integration.
        if (error.status === 404) {
          return null;
        }

        throw error;
      }),

  getPosIntegrationRedirect: (shopId: string, provider: string) =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/authorize`,
    ),

  postPosIntegrationDisconnectAuth: (shopId: string, provider: string) =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/disconnect`,
    ),

  postPosIntegrationRevokeAuth: (shopId: string, provider: string) =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/revoke`,
    ),

  // This endpoint doesn't actually provide the full menu as it does not include
  // product addons (customizations). Restaurant API passes the request through
  // to Menu Service /management-api/v2/menus/{shopId} with the include depth hard-coded to `product-types`.
  getMenu(
    shopId: Shop['shopId'],
    channel?: 'online' | 'offline',
  ): Promise<MenuResponseBody> {
    const param = channel ? `?channel=${channel}` : '';
    return authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus${param}`,
    );
  },

  getMenuProducts(
    shopId: Shop['shopId'],
    channel?: 'online' | 'offline',
  ): Promise<MenuProductsResponseBody> {
    const param = channel ? `?channel=${channel}` : '';
    return authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products${param}`,
    );
  },

  // This endpoint does not provide the products in the category.
  getCategory(
    shopId: Shop['shopId'],
    categoryId: MenuCategory['id'],
  ): Promise<MenuCategoryResponseBody> {
    return authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories/${categoryId}`,
    );
  },

  putCategory(
    shopId: Shop['shopId'],
    body: MenuCategoryRequestBody,
  ): Promise<MenuCategoryResponseBody> {
    return authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories/${body.id}`,
      body,
    );
  },

  postCategory(
    shopId: Shop['shopId'],
    body: MenuCategoryRequestBody,
  ): Promise<MenuCategoryResponseBody> {
    return authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories`,
      body,
    );
  },

  deleteCategory(
    shopId: Shop['shopId'],
    categoryId: MenuCategory['id'],
  ): Promise<void> {
    return authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories/${categoryId}`,
    );
  },

  getProduct: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${productId}`,
    ),

  deleteProduct: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.delete(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${productId}`,
    ),

  putProductStock: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
    data: MenuProductStockRequest,
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${productId}/stock`,
      data,
    ),

  updateProductsStock(
    shopId: Shop['shopId'],
    products: MenuProductsStockRequestBody,
  ): Promise<void> {
    return authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/stock`,
      products,
    );
  },

  putProductTypeStock: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
    productTypeId: MenuProductType['id'],
    data: MenuProductStockRequest,
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${productId}/product_types/${productTypeId}/stock`,
      data,
    ),

  patchSortCategories: (
    shopId: string,
    data: SortMenuCategoriesRequestBody,
  ): Promise<MenuResponseBody> =>
    authenticatedFetch.patch(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories`,
      data,
    ),

  patchSortProducts: (
    shopId: string,
    categoryId: number,
    data: SortMenuProductsRequestBody,
  ): Promise<MenuCategoryResponseBody> =>
    authenticatedFetch.patch(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/categories/${categoryId}/products`,
      data,
    ),

  postProduct: (
    shopId: Shop['shopId'],
    data: MenuProductRequestBody,
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products`,
      data,
    ),

  putProduct: (
    shopId: Shop['shopId'],
    data: MenuProductRequestBody,
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${data.product.id}`,
      data,
    ),

  putProductImage: (
    shopId: string,
    productId: number,
    image: string,
  ): Promise<MenuProductResponseBody> =>
    authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/menus/products/${productId}`,
      { image, shopId },
    ),

  postProductUpdate: (
    request: string,
    productId: string,
    productName: string,
    shopId: string,
  ) =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/products/${productId}/change_request`,
      {
        request_content: request,
        product_name: productName,
      },
    ),

  uploadProductImage: (
    shopId: any,
    image: string | Blob,
  ): Promise<MenuPhotoUploadResponseBody> => {
    const data = new FormData();
    data.append('image', image);

    return authenticatedFetch.postFile(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/product_images`,
      data,
    );
  },

  getProductPrintSettings: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
  ): Promise<MenuProductPrintSettingsResponseBody> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu/products/${productId}/print_settings`,
      )
      .then(camelCaseKeys),

  putProductPrinterSettings: (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
    printerSettings: MenuProductPrintSettingsRequestBody,
  ): Promise<MenuProductPrintSettingsResponseBody> =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu/products/${productId}/print_settings`,
        printerSettings,
      )
      .then(camelCaseKeys),

  fetchOrderTotalsByShippingTypeMetrics: (
    shopId: any,
    dateParams: Record<string, any>,
  ) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/order_totals_by_shipping_types?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  async fetchCustomerTotalsMetrics(
    shopId: string,
    dateParams: Record<string, any>,
  ): Promise<any> {
    const response = await authenticatedFetch.get(
      `${
        hostnames.restaurantApi
      }/api/management/v2/shops/${shopId}/metrics/customers?${queryString.stringify(
        snakeCaseKeys(dateParams),
      )}`,
    );

    return camelCaseKeys(response);
  },

  fetchCustomersByWeekMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/customers/by/week?iterations=4&${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: any) => camelCaseKeys(response)),

  fetchProductSalesMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/product_sales?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  // OPAS uses a post request with filters supplied in the request body for getting product sales metrics in v2
  // of the OPAS endpoint.
  postProductSalesMetrics: (
    shopId: string,
    filters: OpasFilters,
  ): Promise<ProductSalesMetricsResponse> =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/metrics/product_sales`,
      filters,
    ),

  fetchSalesByDayMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/sales_by_days?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  fetchMostValuableCustomers: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/most_valuable_customers?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => camelCaseKeys(response.data)),

  shopScoreLoad: (shopId: number): Promise<ShopScoreResponse> =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/scores`,
    ),

  // Register layout:
  getRegisterLayout: (shopId: string): Promise<RegisterLayout> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu`,
      )
      .then(camelCaseKeys),

  updateRegisterLayout: (
    shopId: string,
    layout: RegisterLayout,
  ): Promise<RegisterLayout> =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu/layouts/${layout.id}`,
        snakeCaseKeys(layout),
      )
      .then(camelCaseKeys),

  // Register printing:
  async getRegisterPrintingSettings(
    shopId: string,
  ): Promise<RegisterPrinter[]> {
    const response: NetworkRegisterPrinter[] = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/printer_roles`,
    );

    return response.map(networkToDomainRegisterPrinter);
  },

  async createRegisterPrintingSetting(
    printerSetting: CreateRegisterPrinterValues,
    shopId: string,
  ): Promise<RegisterPrinter> {
    const response: NetworkRegisterPrinter = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/printer_roles`,
      snakeCaseKeys(printerSetting),
    );

    return networkToDomainRegisterPrinter(response);
  },

  async editRegisterPrintingSetting(
    printerSetting: EditRegisterPrinterValues,
    shopId: string,
  ): Promise<RegisterPrinter> {
    const response: NetworkRegisterPrinter = await authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/printer_roles/${printerSetting.id}`,
      snakeCaseKeys(printerSetting),
    );

    return networkToDomainRegisterPrinter(response);
  },

  async deleteRegisterPrintingSetting(
    printerSettingId: string,
    shopId: string,
  ): Promise<void> {
    await authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/printer_roles/${printerSettingId}`,
    );
  },

  // Register Tip Settings
  async getRegitserTipSettings(shopId: string) {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/payment_settings`,
    );

    return camelCaseKeys(response);
  },

  async patchRegisterTipSettings(
    shopId: Shop['shopId'],
    tipSettings: RegisterTipSettings,
  ) {
    await authenticatedFetch.patch(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/payment_settings`,
      snakeCaseKeys(tipSettings),
    );
  },

  // Add new review reply
  createReply: (
    shopId: Shop['shopId'],
    feedbackResponseId: number,
    body: CustomerReviewReplyBody,
  ): Promise<CustomerReviewReply> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/feedback_responses/${feedbackResponseId}/replies`,
        snakeCaseKeys(body),
      )
      .then(camelCaseKeys),

  createDebitCard: (shopId: any, debitCardToken: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/debit_cards`,
        debitCardToken,
      )
      .then(camelCaseKeys),

  getTraits: async (shopId: string): Promise<ShopTraits> => {
    try {
      const response: NetworkShopTraits = await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/traits`,
      );

      return camelCaseKeys(response.traits);
    } catch (error: any) {
      // A 404 here is fine, it just means the shop has no integration.
      if (error.status === 404) {
        return {};
      }

      throw error;
    }
  },

  async getRegisterProfiles(
    shopId: Shop['shopId'],
  ): Promise<RegisterProfile[]> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_profiles`,
    );

    return camelCaseKeys(response);
  },

  async createRegisterProfile(
    shopId: Shop['shopId'],
    newRegisterProfile: RegisterProfileRequestBody,
  ): Promise<RegisterProfile> {
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_profiles`,
      newRegisterProfile,
    );
    return camelCaseKeys(response);
  },

  async updateRegisterProfile(
    shopId: Shop['shopId'],
    registerProfileId: RegisterProfile['id'],
    updatedRegisterProfile: RegisterProfileRequestBody,
  ): Promise<RegisterProfile> {
    const response = await authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_profiles/${registerProfileId}`,
      updatedRegisterProfile,
    );
    return camelCaseKeys(response);
  },

  sendEmail: (shopId: string, subject: string, to: string) =>
    authenticatedFetch
      .post(`${hostnames.restaurantApi}/api/management/v1/email/send_email`, {
        subject,
        to,
        shop_id: shopId,
      })
      .then(camelCaseKeys),

  getClosingReasons: (scope: string | null): Promise<ClosingReason[]> =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/closure_reasons${
        scope ? `?scope=${scope}` : ''
      }`,
    ),

  // Customers
  async getCustomerExports(
    shopId: string,
    params: { page: number; perPage: number },
  ) {
    const query = `${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_downloads?${query}`,
    );

    return camelCaseKeys(response);
  },

  async getCustomerImports(shopId: Shop['shopId']) {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_uploads`,
    );

    return camelCaseKeys(response);
  },

  async getCustomerImportErrors(
    shopId: Shop['shopId'],
    uploadId: CustomerImport['id'],
  ) {
    try {
      const response = await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_uploads/${uploadId}/errors`,
      );

      return camelCaseKeys(response);
    } catch (error: any) {
      // A 404 here is fine, it means this upload ID has no errors.
      if (error.status === 404) {
        return {};
      }

      throw error;
    }
  },

  async getCustomersAnalytics(
    shopId: string,
    interval: Interval,
    customerType: CustomerType,
    registrationType: RegistrationType,
    customerList: CustomerList,
    customerUuids: string[],
  ): Promise<CustomersAnalytics> {
    const filters = [];

    // Filter by customer list
    if (customerList === CustomerList.New) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'new'),
      );
    } else if (customerList === CustomerList.Loyal) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'loyal'),
      );
    } else if (customerList === CustomerList.Repeat) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'repeat'),
      );
    } else if (customerList === CustomerList.Delivery) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'delivery'));
    } else if (customerList === CustomerList.Pickup) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'pickup'));
    } else if (
      customerList === CustomerList.MostFrequent ||
      customerList === CustomerList.MostValuable
    ) {
      filters.push(createCustomerFilter('customer_uuid', 'in', customerUuids));
    }

    // Filter by order type (online or register)
    if (customerType !== CustomerType.All) {
      filters.push(createCustomerFilter('order_type', 'is', customerType));
    }

    // Filter by customer type (registered or guest)
    filters.push(createCustomerFilter('customer_type', 'is', registrationType));

    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/v1/shops/${shopId}/marketing/customers/analytics`,
      {
        filters: filters,
        interval,
      },
    );

    return camelCaseKeys(response);
  },

  async getCustomers(
    shopId: string,
    page: number,
    perPage: number,
    sort: CustomerSort,
    interval: Interval,
    customerType: CustomerType,
    customerList: CustomerList,
    customerUuids: string[],
    userNameSearch?: string,
  ): Promise<CustomersResponse> {
    const filters = [];

    // Filter by customer list
    if (customerList === CustomerList.New) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'new'),
      );
    } else if (customerList === CustomerList.Loyal) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'loyal'),
      );
    } else if (customerList === CustomerList.Repeat) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'repeat'),
      );
    } else if (customerList === CustomerList.Delivery) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'delivery'));
    } else if (customerList === CustomerList.Pickup) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'pickup'));
    } else if (
      customerList === CustomerList.MostFrequent ||
      customerList === CustomerList.MostValuable
    ) {
      filters.push(createCustomerFilter('customer_uuid', 'in', customerUuids));
    }

    // Filter by order type (online or register)
    if (customerType !== CustomerType.All) {
      filters.push(createCustomerFilter('order_type', 'is', customerType));
    }

    // User name search filter
    if (userNameSearch && userNameSearch.length !== 0) {
      filters.push(
        createCustomerFilter('customer_name', 'like', userNameSearch),
      );
    }

    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/v1/shops/${shopId}/marketing/customers`,
      {
        filters: filters,
        interval,
        pagination: {
          page: page,
          per_page: perPage,
        },
        sort: {
          by: sort.by,
          direction: sort.direction,
        },
      },
    );

    return camelCaseKeys(response);
  },

  async getCustomerCounts(
    shopId: string,
    interval: Interval,
    customerType: CustomerType,
    registrationType: RegistrationType,
  ): Promise<CustomerCounts> {
    const filters = [];
    if (customerType !== CustomerType.All) {
      filters.push(createCustomerFilter('order_type', 'is', customerType));
    }

    filters.push(createCustomerFilter('customer_type', 'is', registrationType));

    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/v1/shops/${shopId}/marketing/customers/overview`,
      {
        filters: filters,
        interval,
      },
    );

    return camelCaseKeys(response);
  },

  getCustomer: (
    shopId: number,
    userUuid: string,
  ): Promise<CustomerOrderProfile> => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/v1/shops/${shopId}/marketing/customers/${userUuid}`,
      )
      .then(camelCaseKeys);
  },

  getCustomersOrders: async (
    shopId: number,
    userUuid: string,
    pageParams?: {
      page: number;
      perPage: number;
    },
  ): Promise<PagedCustomerProfileOrder> => {
    let query = '';

    if (pageParams) {
      query = `?${queryString.stringify(snakeCaseKeys(pageParams))}`;
    }

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/v1/shops/${shopId}/marketing/customers/${userUuid}${query}`,
    );

    const transformedResponse: CustomerOrderProfile = camelCaseKeys(response);

    const pagedData = {
      data: transformedResponse.orders,
      nextPage: pageParams
        ? pageParams.page * pageParams.perPage < transformedResponse.orderCount
          ? pageParams.page + 1
          : undefined
        : 2,
    };

    return pagedData;
  },

  // Pausings

  getPausings: (shopId: string): Promise<Pause[]> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings`,
      )
      .then(({ data }: { data: Pause[] }) => camelCaseKeys(data)),

  createPause: (shopId: string, newPause: any): Promise<Pause> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings`,
        snakeCaseKeys(newPause),
      )
      .then(({ data }: { data: Pause }) => camelCaseKeys(data)),

  updatePause: (
    shopId: string,
    pausingId: string,
    endTime: string,
  ): Promise<Pause> =>
    authenticatedFetch
      .patch(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings/${pausingId}`,
        snakeCaseKeys({ endTime }),
      )
      .then(({ data }: { data: Pause }) => camelCaseKeys(data)),

  deletePause: (shopId: string, pausingId: string) =>
    authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings/${pausingId}`,
    ),

  // Register Users
  getRegisterUsers: (shopId: any): RegisterUser[] => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users`,
      )
      .then((response: NetworkGetRegisterUsersResponse): RegisterUser[] => {
        return networkGetRegisterUsersResponseToRegisterUsers(response);
      });
  },

  getPermissionGroups: (shopId: string): RegisterPermissionGroup[] => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/permission_groups`,
      )
      .then(
        (
          response: NetworkRegisterPermissionGroup[],
        ): RegisterPermissionGroup[] => {
          return response.map((networkRegisterPermissionGroup) => {
            return networkToDomainRegisterPermissionGroup(
              networkRegisterPermissionGroup,
            );
          });
        },
      );
  },

  getRecommendedPermissionGroups: (): PermissionsInfo => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/recommended_permission_groups`,
      )
      .then(
        (
          response: NetworkGetRecommendedPermissionGroupsResponse,
        ): PermissionsInfo => {
          return networkRecommendedPermissionGroupsResponseToDomainInfo(
            response,
          );
        },
      );
  },

  createRegisterUser: (
    user: NewRegisterUser,
    shopId: string,
  ): Promise<RegisterUser> => {
    return authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users`,
        snakeCaseKeys(user),
      )
      .then((response: NetworkRegisterUser) =>
        networkRegisterUserToRegisterUser(response),
      );
  },

  editRegisterUser: (
    user: RegisterUser,
    shopId: string,
  ): Promise<RegisterUser> => {
    return authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${user.id}`,
        snakeCaseKeys(user),
      )
      .then((response: NetworkRegisterUser) =>
        networkRegisterUserToRegisterUser(response),
      );
  },

  deleteRegisterUser: (userId: string, shopId: string) => {
    console.log(
      `I would have sent a delete request to ${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    );
    // TODO - When backend endpoint is in place uncomment the actual api call below
    // return authenticatedFetch.del(
    //   `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    // );
    return Promise.resolve(userId);
  },

  updatePermissionsToggle: (shopId: string, isEnabled: boolean) => {
    return authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/settings`,
        {
          setting: {
            register_user_permissions: isEnabled,
          },
        },
      )
      .then((response: NetworkPermissionsToggleResponse): boolean => {
        return response.setting.register_user_permissions;
      });
  },

  resetRegisterUserPin: (
    shopId: string,
    userId: string,
  ): Promise<RegisterUser> => {
    return authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}/reset_pin`,
      )
      .then((response: NetworkRegisterUser) =>
        networkRegisterUserToRegisterUser(response),
      );
  },

  createPermissionGroup: (
    shopId: string,
    permissionGroup: CreateEditPermissionBody,
  ): Promise<NetworkCreateEditRegisterPermissionGroupResponse> => {
    return authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/permission_groups`,
      {
        name: permissionGroup.name,
        permissions: permissionGroup.permissions,
      },
    );
  },

  getPermissionsLog: (
    shopId: string,
    params: PermissionsLogRequestParams,
  ): GetPermissionsLogResponse => {
    const query = queryString.stringify(snakeCaseKeys(params));

    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/audit_logs?${query}`,
      )
      .then(
        (
          response: NetworkGetPermissionsLogResponse,
        ): GetPermissionsLogResponse => {
          return camelCaseKeys(response);
        },
      );
  },

  editPermissionGroup: (
    shopId: string,
    permissionGroup: CreateEditPermissionBody,
  ): Promise<NetworkCreateEditRegisterPermissionGroupResponse> => {
    return authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/permission_groups/${permissionGroup.id}`,
      {
        name: permissionGroup.name,
        permissions: permissionGroup.permissions,
        id: permissionGroup.id,
      },
    );
  },

  // Asset library
  getAssetLibraryCategories: (): Promise<AssetLibraryCategory[]> => {
    return authenticatedFetch
      .get(`${hostnames.restaurantApi}/digital-asset-service/api/categories`)
      .then(
        (response: NetworkAssetLibraryCategory[]): AssetLibraryCategory[] => {
          return networkToDomainAssetLibraryCategories(response);
        },
      );
  },

  getAssetLibraryAssets: (): Promise<AssetLibraryAsset[]> => {
    return authenticatedFetch
      .get(`${hostnames.restaurantApi}/digital-asset-service/api/assets`)
      .then(
        (
          response: NetworkGetAssetLibraryAssetsResponse,
        ): AssetLibraryAsset[] => {
          return networkToDomainAssetLibraryAssets(response.assets);
        },
      );
  },

  getDirectWebSettings: (shopId: string): Promise<SiteEditorSettings> => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/direct_web_settings`,
      )
      .then(camelCaseKeys);
  },

  getPartnerSuccessManagerInfo: (
    shopId: string,
  ): Promise<PartnerSuccessManagerInfo> => {
    return authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/admin`)
      .then(camelCaseKeys);
  },

  // Campaign Manager
  async getCampaignManagerSummary(
    shopId: number,
  ): Promise<CampaignManagerSummary> {
    const response: NetworkCampaignManagerSummary =
      await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/metrics/advanced_marketing_services/summary`,
      );
    return camelCaseKeys(response);
  },

  async getCampaignManagerHistory(
    shopId: number,
  ): Promise<CampaignManagerHistory> {
    const response: NetworkCampaignManagerHistory =
      await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/metrics/advanced_marketing_services`,
      );

    return camelCaseKeys(response);
  },

  async getAchOnboardingLink(shopId: Shop['shopId']) {
    try {
      const response = await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/onboarding_link`,
      );

      return camelCaseKeys(response);
    } catch (error: unknown) {
      // This endpoint will return with an error for shops not configured for adyen
      // which currently we cannot determine before making the request used to determine
      // the ach banner visibility
      return {
        redirectUrl: '',
        showOnboardingLink: false,
      };
    }
  },

  // Hours Analytics
  async getHoursAnalyticsSummary(
    shopId: number,
    date: string,
  ): Promise<AvailabilitySummaryWithoutAsOfText> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/metrics/availability/summary?date=${date}`,
    );

    return camelCaseKeys(response);
  },

  getShopStatusHistory: async (
    shopId: number,
    params: GetShopStatusHistoryRequestParams,
  ): Promise<GetShopStatusHistoryResponse> => {
    const query = `?${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/hours/log${query}`,
    );

    const transformedResponse: GetShopStatusHistoryResponse = response;

    return camelCaseKeys(transformedResponse);
  },

  // Salesforce cases
  createSalesforceCase: async (
    shopId: Shop['shopId'],
    body: CreateSalesforceCase,
  ): Promise<string> => {
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/cases`,
      snakeCaseKeys(body),
    );
    return response.status;
  },

  // Payouts
  getPayouts: async (
    shopId: Shop['shopId'],
    params: GetPayoutsRequestParams,
  ): Promise<PayoutsResponse> => {
    const query = queryString.stringify(snakeCaseKeys(params));
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/payouts?${query}`,
      {},
      true,
      true,
      true,
    );

    // Normalize the response pagination values to be a part of the response
    // similar to OPAS rather than dealing with headers outside API layer.
    return {
      payouts: camelCaseKeys(response.body) as Payout[],
      meta: {
        pages: Number(response.headers.get('X-Total-Pages') ?? 0),
        results: Number(response.headers.get('X-Total') ?? 0),
      },
    };
  },

  // get customers data uses on the customer profiles page
  getProfileCustomers: async (
    shopId: Shop['shopId'],
    params: GetProfileCustomersParams,
  ): Promise<GetProfileCustomersResponse> => {
    const query = `?${queryString.stringify(snakeCaseKeys(params))}`;

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customers${query}`,
      {},
      true,
      true,
      true,
    );

    return {
      customers: camelCaseKeys(response.body.data) as Customer[],
      meta: camelCaseKeys(response.body.meta) as GetProfileCustomersMeta,
    };
  },

  createCustomerExport: async (
    shopId: Shop['shopId'],
    params: CreateCustomerExportParams,
  ) => {
    const query = `?${queryString.stringify(snakeCaseKeys(params))}`;
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_downloads${query}`,
    );

    return camelCaseKeys(response);
  },

  createCustomerUploadPresignedUrl: async (
    shopId: Shop['shopId'],
    params: CreateCustomerUploadPresignedUrlParams,
  ): Promise<CreateCustomerUploadPresignedUrlResponse> => {
    const response = await authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_uploads`,
      snakeCaseKeys(params),
    );

    return camelCaseKeys(response);
  },

  updateCustomerUploadStatus: async (
    shopId: Shop['shopId'],
    uploadUuid: string,
    params: UpdateCustomerUploadStatusParams,
  ): Promise<UpdateCustomerUploadStatusResponse> => {
    const response = await authenticatedFetch.put(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/customer_uploads/${uploadUuid}`,
      snakeCaseKeys(params),
    );

    return camelCaseKeys(response);
  },
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default create;
