import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { PagedCustomerProfileOrder } from 'types/customers';

export const getCustomersOrdersQueryKey = (
  shopId: number,
  customerUuid: string,
): QueryKey => [shopId, customerUuid, 'getCustomersOrders'];

export const useCustomersOrdersQuery = (
  shopId: number,
  customerUuid: string,
  options?: UseInfiniteQueryOptions<PagedCustomerProfileOrder>,
): UseInfiniteQueryResult<PagedCustomerProfileOrder> => {
  const api = useApi();

  const fetchCustomersOrders = async ({
    pageParam = 1,
  }: {
    pageParam?: number;
  }) => {
    const customerDetails = await api.getCustomersOrders(shopId, customerUuid, {
      page: pageParam,
      perPage: 5,
    });
    return customerDetails;
  };

  return useInfiniteQuery({
    queryKey: getCustomersOrdersQueryKey(shopId, customerUuid),
    queryFn: fetchCustomersOrders,
    ...options,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
};
