import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerCounts,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';
import { getInterval } from 'utilities/customers';

type UseCustomerCountsQueryOptions = Omit<
  UseQueryOptions<CustomerCounts>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  registrationType: RegistrationType;
  shopId: string;
  timezone: string;
  options?: UseCustomerCountsQueryOptions;
};

export const getCustomerCountsQueryKey = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
}: Omit<Props, 'timezone'>): QueryKey => [
  shopId,
  'customerCounts',
  dateRange,
  customerType,
  registrationType,
];

const useCustomerCountsQuery = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
  timezone,
  options,
}: Props): UseQueryResult<CustomerCounts> => {
  const api = useApi();

  return useQuery<CustomerCounts>({
    queryKey: getCustomerCountsQueryKey({
      customerType,
      dateRange,
      registrationType,
      shopId,
    }),
    queryFn: () =>
      api.getCustomerCounts(
        shopId,
        getInterval(dateRange, timezone),
        customerType,
        registrationType,
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomerCountsQuery;
