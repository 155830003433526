import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerList,
  CustomersAnalytics,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';
import { getInterval } from 'utilities/customers';

type UseCustomersAnalyticsQueryOptions = Omit<
  UseQueryOptions<CustomersAnalytics>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  customerUuids?: string[];
  dateRange: DateRange;
  options?: UseCustomersAnalyticsQueryOptions;
  registrationType: RegistrationType;
  shopId: string;
  timezone: string;
};

export const getCustomersAnalyticsQueryKey = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
  customerList,
  customerUuids,
}: Omit<Props, 'timezone'>): QueryKey => [
  shopId,
  'customersAnalytics',
  dateRange,
  customerType,
  registrationType,
  customerList,
  customerUuids,
];

const useCustomersAnalyticsQuery = ({
  customerList,
  customerType,
  customerUuids = [],
  dateRange,
  options,
  registrationType,
  shopId,
  timezone,
}: Props): UseQueryResult<CustomersAnalytics> => {
  const api = useApi();

  return useQuery<CustomersAnalytics>({
    queryKey: getCustomersAnalyticsQueryKey({
      customerType,
      dateRange,
      registrationType,
      shopId,
      customerList,
      customerUuids,
    }),
    queryFn: () =>
      api.getCustomersAnalytics(
        shopId,
        getInterval(dateRange, timezone),
        customerType,
        registrationType,
        customerList,
        customerUuids,
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomersAnalyticsQuery;
