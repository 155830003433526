import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { MenuProductResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';
import { getProductQueryKey } from './use-product-query';

type Values = {
  productId: MenuProduct['id'];
  shopId: Shop['shopId'];
};

export const useDeleteProductMutation = (): UseMutationResult<
  MenuProductResponseBody,
  ApiRequestError,
  Values
> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ productId, shopId }) => api.deleteProduct(shopId, productId),
    onSuccess: (_, { productId, shopId }) => {
      // Also removes the query for the register printer settings associated
      // with this product.
      queryClient.removeQueries(getProductQueryKey(shopId, productId));
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
