import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  GetStatementsParams,
  GetStatementsResponse,
} from 'types/financials/statements';
import { Shop } from 'types/shops';

// adding new for now until we remove the feature flag for the new statements page and make it the only statements page
export const getStatementsQueryKey = (
  shopId: Shop['shopId'],
  params: GetStatementsParams,
): QueryKey => [shopId, 'statements-new', params];

type Props = {
  shopId: Shop['shopId'];
  params: GetStatementsParams;
};

export const useStatementsQuery = ({
  shopId,
  params,
}: Props): UseQueryResult<GetStatementsResponse> => {
  const api = useApi();

  return useQuery({
    queryKey: getStatementsQueryKey(shopId, params),
    queryFn: () => api.getShopStatementsV2(shopId, params),
    keepPreviousData: true,
  });
};
