import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { SingleRegisterOrder } from 'types/register-orders';

type Props = {
  orderId: string;
  shopId: string;
};

export const getSingleOrderQueryKey = ({
  orderId,
  shopId,
}: Props): QueryKey => ['register-orders', orderId, shopId];

export const useRegisterOrderQuery = ({
  orderId,
  shopId,
}: Props): UseQueryResult<SingleRegisterOrder> => {
  const api = useApi();

  return useQuery({
    queryKey: getSingleOrderQueryKey({ orderId, shopId }),
    queryFn: async () => await api.getRegisterOrder(shopId, orderId),
    enabled: !!orderId,
  });
};
