import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterTipSettings } from 'types/register-tip-settings';
import { Shop } from 'types/shops';

import { getRegisterTipSettingsQueryKey } from './use-register-tip-settings';

type SaveRegisterTipSettingsVariables = {
  shopId: Shop['shopId'];
  tipSettings: RegisterTipSettings;
};

export const useSaveRegisterTipSettings = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ shopId, tipSettings }: SaveRegisterTipSettingsVariables) =>
      api.patchRegisterTipSettings(shopId, tipSettings),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          getRegisterTipSettingsQueryKey(variables.shopId),
        );
      },
    },
  );
};
