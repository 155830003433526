import { useEffect, useMemo, useState } from 'react';

import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import Text from 'components/shared/text';
import { useProductsPageState } from 'hooks/menu';
import useAnalytics from 'hooks/use-analytics';
import { MenuProductsProduct, MenuProductsResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { ActionBar } from './action-bar';
import { useBulkEditContext } from './context';
import { CustomOutOfStockModal } from './custom-out-of-stock-modal';
import { ProductsTable } from './table';

import styles from './styles.module.scss';

type Props = {
  menu: MenuProductsResponseBody;
  shop: Shop;
};

export const MenuProducts = ({ menu, shop }: Props) => {
  const { trackSearchedMenuItems } = useAnalytics();

  const { scrollId, search, setScrollId, setSearch } = useProductsPageState();
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();

  const [isCustomOutOfStockModalOpen, setIsCustomOutOfStockModalOpen] =
    useState(false);

  const toggleCustomOutOfStockModal = () => {
    setIsCustomOutOfStockModalOpen(!isCustomOutOfStockModalOpen);
  };

  const selectedProductNames: string[] = useMemo(() => {
    const names: string[] = [];

    for (const product of menu.products) {
      if (selectedProductIds.has(product.id)) {
        names.push(product.name);
      }
    }

    return names;
  }, [menu.products, selectedProductIds]);

  const isRegister = getShopHasFullRegister(shop) ?? false;

  const filteredProducts = useMemo(() => {
    if (search) {
      const target = search.toLowerCase();
      const products: MenuProductsProduct[] = menu.products.filter(
        (product) =>
          product.name?.toLowerCase().includes(target) ||
          product.description?.toLowerCase().includes(target),
      );

      trackSearchedMenuItems({
        countResults: products.length,
        isRegister: isRegister,
        page: 'menu items',
        searchText: search,
        shopId: shop.shopId,
      });

      return products;
    }

    return menu.products;
  }, [isRegister, menu.products, search, shop.shopId, trackSearchedMenuItems]);

  useEffect(() => {
    clearSelectedProductIds();
  }, [clearSelectedProductIds, search]);

  useEffect(() => {
    if (!scrollId) {
      return;
    }

    const element = document.getElementById(scrollId);

    if (element) {
      const elementBoundingRect = element.getBoundingClientRect();
      const elementTop = elementBoundingRect.top;

      let offset = 0;

      const appHeader = document.getElementById('app-header');

      if (appHeader) {
        offset += appHeader.getBoundingClientRect().height;
      }

      const pageHeader = document.getElementById('menu-page-header');

      if (pageHeader) {
        offset += pageHeader.getBoundingClientRect().height;
      }

      window.scrollTo({
        top: elementTop - offset,
      });

      // Discard the ID regardless of whether we found it. We don't want to try to
      // scroll to this entity again.
      setScrollId(undefined);
    }
  }, [scrollId, setScrollId]);

  const hasEmptySearchResults = filteredProducts.length === 0 && search;

  const totalItems = menu.products.length ?? 0;
  const outOfStockCount = menu.products.filter(
    (product) => product.unavailable === true,
  ).length;

  return (
    <ContentTile
      dataTestId="menuItemsPageContentTile"
      className={styles.productContainer}
    >
      <div className={styles.headerWrapper}>
        <SearchBar
          initialSearchValue={search}
          onChange={setSearch}
          placeholderText={`Search ${totalItems} items`}
        />
        <div className={styles.itemInfo}>
          {outOfStockCount && outOfStockCount > 0 ? (
            <Text className={styles.text}>
              {`${outOfStockCount} ${outOfStockCount === 1 ? 'item' : 'items'} out of stock`}
            </Text>
          ) : null}
          <Text
            className={styles.text}
          >{`${totalItems} ${totalItems === 1 ? 'item' : 'items'}`}</Text>
        </div>
      </div>
      {hasEmptySearchResults ? (
        <div className={styles.noSearchResultsWrapper}>
          <div className={styles.noSearchResultsTitle}>
            {"Sorry, we couldn't find what you were looking for."}
          </div>
          <div className={styles.noSearchResultsBody}>
            No results for &quot;{search}&quot;. Check your spelling and try
            again, or search for something different.
          </div>
        </div>
      ) : (
        <ProductsTable
          products={filteredProducts}
          categories={menu.categories}
          shop={shop}
        />
      )}
      {/* Only here for testing purposes, this page is behind a flag anyway */}
      <ActionBar
        onPressCustom={toggleCustomOutOfStockModal}
        shop={shop}
        selectedProductNames={selectedProductNames}
      />
      <CustomOutOfStockModal
        closeModal={toggleCustomOutOfStockModal}
        isOpen={isCustomOutOfStockModalOpen}
        shop={shop}
      />
    </ContentTile>
  );
};
