import { toast } from 'react-toastify';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { ApiRequestError } from 'providers/api/helpers';
import { OrderStatementResponse } from 'types/order-statement';
import { Shop } from 'types/shops';

import useApi from './use-api';

type Props = {
  shopId: Shop['shopId'];
};

// Use useMutation here because the shop orders lack a URL for the statement PDF.
// When the order number button is clicked, we send a request to retrieve the pre-signed S3 link for the statement.
// Once the backend team includes the pre-signed link in the order data, we can eliminate the need for this hook.
const useStatementMutation = ({
  shopId,
}: Props): UseMutationResult<
  OrderStatementResponse,
  ApiRequestError,
  string
> => {
  const api = useApi();

  return useMutation((orderId) => api.getOrderStatement(shopId, orderId), {
    onMutate: () => {
      // We open a new window onMutate before an error or success because Safari blocks window.open(),
      // which is called inside of an async call. See the following for more details:
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      const statementWindow = window.open('', '_blank');

      // The statement window is passed in the context object to both onError and onSuccess
      return { statementWindow };
    },
    onError: (_error, _variables, ctx) => {
      ctx?.statementWindow?.close();
      toast.error('Statement not found');
    },
    onSuccess: (response: { url: string }, _variables, ctx) => {
      if (ctx?.statementWindow) {
        ctx.statementWindow.location = response.url;
      }
    },
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useStatementMutation;
