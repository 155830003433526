import ContentTile from 'components/shared/content-tile';
import { useShopPublishersQuery } from 'hooks/brand-manager';

import LinkedAccountTile from './linked-account-tile';
import { getShopPublishersInfoList } from './shop-publishers';
import LinkedAccountsTileHeader from './tile-header';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const LinkedAccountsArea = ({ shopId }: Props) => {
  const shopPublishersQuery = useShopPublishersQuery(shopId);
  const shopPublishersInfoList = getShopPublishersInfoList(shopPublishersQuery);

  return (
    <ContentTile
      className={styles.tileClassName}
      isLoading={shopPublishersQuery.isLoading}
      headerContent={<LinkedAccountsTileHeader />}
      headerClassName={styles.headerClassName}
    >
      <div>
        <div className={styles.linkedAccountTilesContainer}>
          {shopPublishersInfoList.map((shopPublisherInfo) => {
            return (
              <LinkedAccountTile
                key={shopPublisherInfo.displayName}
                shopPublisherInfo={shopPublisherInfo}
              />
            );
          })}
        </div>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LinkedAccountsArea;
