import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import cx from 'classnames';

import { Logomark } from 'crust';

import ContentTile from 'components/shared/content-tile';
import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import useApi from 'hooks/use-api';
import { emailRegex } from 'utilities/constants';
import { showInvalidSubmitToast } from 'utilities/forms';

import styles from './styles.module.scss';

const ResetPasswordForm = () => {
  const api = useApi();

  // Form
  const {
    handleSubmit,
    register,
    getValues,

    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  const {
    mutate: onSubmit,
    error,
    isLoading,
    isSuccess,
  } = useMutation((values) => api.postSendPasswordResetEmail(values.email));

  return (
    <div className={styles.container}>
      <ContentTile className={styles.tile}>
        <Logomark className={styles.logo} />
        <div
          className={cx(styles.message, {
            [styles.hide]: !isSuccess,
          })}
        >
          Password reset email sent to {getValues('email')}.
        </div>
        <form
          className={cx(styles.form, {
            [styles.hide]: isSuccess,
          })}
          onSubmit={handleSubmit(onSubmit, showInvalidSubmitToast)}
        >
          <div className={styles.message}>
            Enter your email to reset your password.
          </div>
          <div className={styles.input}>
            <Label htmlFor="email-input">Email</Label>
            <Input
              id="email-input"
              inputMode="email"
              isInvalid={errors.email != null}
              {...register('email', {
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address.',
                },
                required: 'Please enter your email address.',
              })}
            />
            <FormFeedback>{errors.email?.message}</FormFeedback>
          </div>
          <div
            className={cx(styles.error, {
              [styles.hide]: !error,
            })}
          >
            For security reasons, we only allow a restaurant owner to reset a
            password. If you need further help with reseting your password,
            please contact us at (855) 237-4992.
          </div>
          <Button
            disabled={isLoading}
            variant={ButtonVariant.Primary}
            type="submit"
          >
            Reset your Password
          </Button>
        </form>
      </ContentTile>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ResetPasswordForm;
