import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button } from 'crust';

import PrintModeInput from 'components/register/register-printing/form/mode-input';
import PrinterNameInput from 'components/register/register-printing/form/name-input';
import PrintQuantityInput from 'components/register/register-printing/form/quantity-input';
import useApi from 'hooks/use-api';
import {
  RegisterPrinter,
  RegisterPrinterFormValues,
} from 'types/register-printing';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';
import { insert, upsertBy } from 'utilities/lists';
import {
  printModeSelectOptions,
  printModeToOption,
} from 'utilities/register-printers';

import styles from './styles.module.scss';

type Props = {
  existingPrinter?: RegisterPrinter;
  inputIdPrefix: string;
  onClose: () => void;
  shopId: string;
};

const RegisterPrintingForm = ({
  existingPrinter,
  inputIdPrefix,
  onClose,
  shopId,
}: Props) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<RegisterPrinterFormValues>({
    mode: 'onBlur',
    defaultValues: {
      name: existingPrinter?.name ?? '',
      numberOfCopies: existingPrinter?.numberOfCopies ?? 1,
      printMode: existingPrinter
        ? printModeToOption(existingPrinter.printMode)
        : printModeSelectOptions[0],
    },
  });

  const { mutate: createSetting } = useMutation(
    (values: RegisterPrinterFormValues) =>
      api.createRegisterPrintingSetting(
        {
          name: values.name,
          numberOfCopies: values.numberOfCopies,
          printMode: values.printMode.value,
        },
        shopId,
      ),
    {
      onSuccess: (data) => {
        // Add the new print setting to cached list.
        queryClient.setQueryData<RegisterPrinter[]>(
          [shopId, 'printSettings'],
          (old) => {
            if (old) {
              return insert(old, data);
            }

            return [data];
          },
        );
      },
    },
  );

  const { mutate: editSetting } = useMutation(
    (values: RegisterPrinterFormValues) =>
      api.editRegisterPrintingSetting(
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: existingPrinter!.id as string,
          name: values.name,
          numberOfCopies: values.numberOfCopies,
          printMode: values.printMode.value,
        },
        shopId,
      ),
    {
      onSuccess: (data) => {
        // Update the entry for this setting in the cached list.
        queryClient.setQueryData(
          [shopId, 'printSettings'],
          (old: RegisterPrinter[] = []) => upsertBy(old, data, 'id'),
        );
      },
    },
  );

  const onSubmit = handleSubmit(
    existingPrinter
      ? (values) =>
          editSetting(values, {
            onError: () => showUnexpectedErrorToast(),
            onSuccess: () => onClose(),
          })
      : (values) =>
          createSetting(values, {
            onError: () => showUnexpectedErrorToast(),
            onSuccess: () => onClose(),
          }),
    showInvalidSubmitToast,
  );

  return (
    <form
      aria-label={`${inputIdPrefix} printer setting form`}
      onSubmit={onSubmit}
    >
      <div className={styles.fields}>
        <div
          className={styles.field}
          data-chameleon-target="Register Printing Name Field"
        >
          <PrinterNameInput
            error={errors.name}
            inputIdPrefix={inputIdPrefix}
            isReadOnly={existingPrinter?.name === 'Customer Receipt'}
            register={register}
          />
        </div>
        <div
          className={styles.field}
          data-chameleon-target="Register Printing Print Mode Field"
        >
          <PrintModeInput
            control={control}
            error={errors.printMode}
            inputIdPrefix={inputIdPrefix}
          />
        </div>
        <div
          className={styles.field}
          data-chameleon-target="Register Printing Quantity Field"
        >
          <PrintQuantityInput
            control={control}
            error={errors.numberOfCopies}
            inputIdPrefix={inputIdPrefix}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          isDisabled={isSubmitting}
          id={`${inputIdPrefix}-cancel-print-setting-button`}
          onPress={onClose}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          data-chameleon-target="Register Printing Save Button"
          isDisabled={isSubmitting}
          id={`${inputIdPrefix}-submit-print-setting-button`}
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPrintingForm;
