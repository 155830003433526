import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import Modal from 'components/shared/slice-modal';
import TextArea from 'components/shared/text-area';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';
import { ProductField } from 'utilities/menu';

import styles from './styles.module.scss';

const RequestProductChangeModal = ({
  getProductFormValues,
  isOpen,
  productId,
  setIsOpen,
  shopId,
}) => {
  const api = useApi();
  const { trackProductChangeRequest } = useAnalytics();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      content: '',
    },
    mode: 'onBlur',
  });

  const formId = 'request-product-changes-form';

  const handleClose = () => {
    reset();
    setIsOpen(false);
  };

  const { mutate: submit } = useMutation(
    (values) =>
      api.postProductUpdate(
        values.content,
        productId,
        getProductFormValues(ProductField.Name),
        shopId,
      ),
    {
      onSuccess: (_data, values) => {
        trackProductChangeRequest({
          id: productId,
          shopId,
          productRequestContent: values.content,
        });
      },
    },
  );

  const handleValidSubmit = (values) => {
    submit(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: () => {
        toast.success('Request sent!');
        handleClose();
      },
    });
  };

  // React propagates events up the component tree, *not* the DOM tree. Thus,
  // even though this <form> is in a modal outside the of the product form,
  // this submit would normally still propagate to the product form as well.
  const handleNestedSubmit = (event) => {
    event.stopPropagation();
    handleSubmit(handleValidSubmit, showInvalidSubmitToast)(event);
  };

  return (
    <Modal
      formId={formId}
      header="Request Changes"
      isOpen={isOpen}
      onClickNo={handleClose}
      onRequestClose={handleClose}
      yesButtonChameleonTarget="Product Request Change Modal Submit Button"
    >
      <form
        id={formId}
        className={styles.form}
        name="Request changes form"
        onSubmit={handleNestedSubmit}
      >
        <p className={styles.text}>
          Want to add a description? Need to change the price?
        </p>
        <p className={styles.text}>
          No problem! Send us changes to your menu. We’ll update in under 24
          hours or a rep will reach out.
        </p>
        <Label htmlFor="request-changes-textarea">Your changes</Label>
        <TextArea
          aria-required="true"
          id="request-changes-textarea"
          isInvalid={errors.content != null}
          placeholder="I would like to..."
          {...register('content', {
            required: 'Please write your changes.',
          })}
        />
        <FormFeedback>{errors.content?.message}</FormFeedback>
      </form>
    </Modal>
  );
};
RequestProductChangeModal.propTypes = {
  getProductFormValues: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RequestProductChangeModal;
