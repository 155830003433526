import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

export const getShopsQueryKey = (): QueryKey => ['shops'];

export const useShopsQuery = (
  options?: UseQueryOptions<Shop[]>,
): UseQueryResult<Shop[]> => {
  const api = useApi();

  options = {
    staleTime: 300000, // Five minutes.
    ...options,
  };

  return useQuery(
    getShopsQueryKey(),
    async () => {
      const { shops } = await api.getShops();
      return shops;
    },
    options,
  );
};
