import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

import { getMenuQueryKey } from './use-menu-query';

export const getCategoryQueryKey = (
  shopId: Shop['shopId'],
  categoryId: MenuCategory['id'],
): QueryKey => [...getMenuQueryKey(String(shopId)), 'categories', categoryId];

export const useCategoryQuery = (
  shopId: Shop['shopId'],
  categoryId: MenuCategory['id'],
  options?: UseQueryOptions<MenuCategoryResponseBody>,
): UseQueryResult<MenuCategoryResponseBody> => {
  const api = useApi();
  return useQuery(
    getCategoryQueryKey(shopId, categoryId),
    async () => {
      const response = await api.getCategory(shopId, categoryId);
      return sortRelationships(response);
    },
    options,
  );
};
