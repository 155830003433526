import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerImport,
  CustomerImportErrors,
  CustomerImportsErrorSchema,
} from 'types/customers/profile/customer-profile-import';
import { Shop } from 'types/shops';

export const getCustomerImportErrorsQueryKey = (
  shopId: Shop['shopId'],
  uploadId: CustomerImport['id'],
): QueryKey => [shopId, uploadId, 'getCustomerImportErrors'];

export const useCustomerImportErrorsQuery = (
  shopId: Shop['shopId'],
  uploadId: CustomerImport['id'],
  options?: UseQueryOptions<CustomerImportErrors>,
) => {
  const api = useApi();

  return useQuery({
    queryKey: getCustomerImportErrorsQueryKey(shopId, uploadId),
    queryFn: async () => {
      const response = await api.getCustomerImportErrors(shopId, uploadId);

      return CustomerImportsErrorSchema.parse(response);
    },
    ...options,
  });
};
