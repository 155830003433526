import { CalendarDate } from '@internationalized/date';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { ColumnSort } from '@tanstack/react-table';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import {
  OnlineOrderFilterValue,
  OnlineOrdersRequestParams,
  OnlineOrdersResponse,
  OnlineOrdersSortKey,
} from 'types/financials/online-orders';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { onlineOrdersSortKeys } from 'utilities/financials/orders';
import { toSnakeCase } from 'utilities/strings';

type Props = {
  dates: RangeValue<CalendarDate>;
  includeVoidedOrders?: boolean;
  page: number;
  perPage: number;
  shopId: string;
  shopTimezone: string;
  sort: ColumnSort;
  status: OnlineOrderFilterValue;
};

export const getOnlineOrdersQueryKey = (
  shopId: string,
  params: OnlineOrdersRequestParams,
): QueryKey => [shopId, 'online-orders', params];

const parseSort = (sortParams: ColumnSort): string => {
  const { id, desc } = sortParams;
  const direction = desc ? '-' : '+';
  const sortKey = onlineOrdersSortKeys[id as OnlineOrdersSortKey];

  return `${direction}${toSnakeCase(sortKey)}`;
};

const useOrders = ({
  dates,
  includeVoidedOrders = true,
  page,
  perPage,
  shopId,
  shopTimezone,
  sort,
  status,
}: Props): UseQueryResult<OnlineOrdersResponse> => {
  const api = useApi();

  // If we are requesting all orders we don't send an order_Status param and we
  // can use this included_voided_orders param. However including an
  // order_Status means we cannot use include_voided_orders so we need to check
  // if we are requesting all orders, ie. not sending an order_status
  const params = {
    startDate: toStartOfDateAbsoluteString(dates.start, shopTimezone),
    endDate: toEndOfDateAbsoluteString(dates.end, shopTimezone),
    includeVoidedOrders:
      status === OnlineOrderFilterValue.All ? includeVoidedOrders : undefined,
    orderStatus: status !== OnlineOrderFilterValue.All ? status : undefined,
    page,
    perPage,
    sort: parseSort(sort),
  };

  return useQuery({
    queryKey: getOnlineOrdersQueryKey(shopId, params),
    queryFn: () => api.getOrders(shopId, params),
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useOrders;
