export type NetworkAssetLibraryAsset = {
  active: boolean;
  category_id: string;
  created_at: string;
  description: string;
  duration: string | null;
  file_size: string;
  file_type: string;
  id: string;
  owner_id: string | null;
  resolution_x: number;
  resolution_y: number;
  status: string;
  title: string;
  thumbnail_uri: string | null;
  updated_at: string;
  uri: string;
  visible_to_owners: boolean;
};

export type NetworkGetAssetLibraryAssetsResponse = {
  assets: NetworkAssetLibraryAsset[];
};

export type AssetLibraryAsset = {
  active: boolean;
  categoryId: string;
  categoryName: string;
  createdAt: string;
  description: string;
  duration: string | null;
  fileType: string;
  id: string;
  resolutionX: number;
  resolutionY: number;
  title: string;
  thumbnailUri: string | null;
  uri: string;
  visibleToOwners: boolean;
  fileSize: string;
};

export const emptyAsset = (): AssetLibraryAsset => {
  return {
    active: false,
    categoryId: '',
    categoryName: '',
    createdAt: '',
    description: '',
    duration: '',
    fileType: '',
    id: '',
    resolutionX: 0,
    resolutionY: 0,
    title: '',
    thumbnailUri: '',
    uri: '',
    visibleToOwners: false,
    fileSize: '',
  };
};

export const networkToDomainAssetLibraryAssets = (
  networkAssets: NetworkAssetLibraryAsset[],
): AssetLibraryAsset[] => {
  return networkAssets.map((networkAsset) => {
    const {
      active,
      category_id,
      created_at,
      description,
      duration,
      file_type,
      id,
      resolution_x,
      resolution_y,
      title,
      thumbnail_uri,
      uri,
      visible_to_owners,
      file_size,
    } = networkAsset;

    return {
      active: active,
      categoryId: category_id,
      categoryName: '', // This is populated by using the categories data from the asset service.
      createdAt: created_at,
      description,
      duration,
      fileType: file_type,
      id,
      resolutionX: resolution_x,
      resolutionY: resolution_y,
      title,
      thumbnailUri: thumbnail_uri,
      uri,
      visibleToOwners: visible_to_owners,
      fileSize: file_size,
    };
  });
};
