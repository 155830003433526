import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  GetProfileCustomersParams,
  GetProfileCustomersResponse,
} from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops';

export const getProfileCustomersQueryKey = (
  shopId: Shop['shopId'],
  params?: GetProfileCustomersParams,
): QueryKey => [shopId, 'profile-customers', ...(params ? [params] : [])];

type Props = {
  shopId: Shop['shopId'];
  params: GetProfileCustomersParams;
};

export const useProfileCustomersQuery = ({
  shopId,
  params,
}: Props): UseQueryResult<GetProfileCustomersResponse> => {
  const api = useApi();

  return useQuery({
    queryKey: getProfileCustomersQueryKey(shopId, params),
    queryFn: () => api.getProfileCustomers(shopId, params),
    keepPreviousData: true,
  });
};
