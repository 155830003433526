import {
  createContext,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactNode,
  RefCallback,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ContextValue,
  FieldErrorContext,
  FormContext,
  InputContext,
  LabelContext,
  Provider,
  SlotProps,
  TextContext,
  useContextProps,
  useSlottedContext,
} from 'react-aria-components';
import { filterDOMProps } from '@react-aria/utils';

import { useFileField, UseFileFieldProps } from './use-file-field';
import { FileFieldState, useFileFieldState } from './use-file-field-state';

export type FileFieldRenderProps = {
  /**
   * Whether the file field is disabled.
   * @selector [data-disabled]
   */
  isDisabled: boolean;
  /**
   * Whether the value is invalid.
   * @selector [data-invalid]
   */
  isInvalid: boolean;
  /**
   * Whether the file field is read only.
   * @selector [data-readonly]
   */
  isReadOnly: boolean;
  /**
   * Whether the file field is required.
   * @selector [data-required]
   */
  isRequired: boolean;
};

export type FileFieldProps = Omit<
  UseFileFieldProps,
  'label' | 'description' | 'errorMessage' | 'validationState'
> &
  SlotProps & {
    children?: ReactNode;
    className?: string | ((values: FileFieldRenderProps) => string);
    style?: CSSProperties | ((values: FileFieldRenderProps) => CSSProperties);
  };

/**
 * The useSlot function is copied from react-aria-components because it is not
 * exported:
 *
 * https://github.com/adobe/react-spectrum/blob/f6a664ba900c3e6307a0f9461241aa3b2397efeb/packages/react-aria-components/src/utils.tsx#L209
 */
const useSlot = (): [RefCallback<Element>, boolean] => {
  // Assume we do have the slot in the initial render.
  const [hasSlot, setHasSlot] = useState(true);
  const hasRun = useRef(false);

  // A callback ref which will run when the slotted element mounts.
  // This should happen before the useLayoutEffect below.
  const ref = useCallback((el) => {
    hasRun.current = true;
    setHasSlot(!!el);
  }, []);

  // If the callback hasn't been called, then reset to false.
  useLayoutEffect(() => {
    if (!hasRun.current) {
      setHasSlot(false);
    }
  }, []);

  return [ref, hasSlot];
};

/**
 * The removeDataAttributes function is adapted from react-aria-components
 * because it is not exported:
 *
 * https://github.com/adobe/react-spectrum/blob/f6a664ba900c3e6307a0f9461241aa3b2397efeb/packages/react-aria-components/src/utils.tsx#L368
 */
const removeDataAttributes = (props: FileFieldProps): FileFieldProps =>
  Object.fromEntries(
    Object.entries(props).filter(([key]) => !key.startsWith('data-')),
  ) as FileFieldProps;

type RenderPropsHookOptions = Pick<FileFieldProps, 'className' | 'style'> & {
  values: FileFieldRenderProps;
};

/**
 * The useRenderProps hook is adapted from react-aria-components because it is
 * not exported:
 *
 * https://github.com/adobe/react-spectrum/blob/f6a664ba900c3e6307a0f9461241aa3b2397efeb/packages/react-aria-components/src/utils.tsx#L98
 */
const useRenderProps = (props: RenderPropsHookOptions) => {
  const { className, style, values } = props;

  return useMemo(
    () => ({
      className:
        typeof className === 'function' ? className(values) : className,
      style: typeof style === 'function' ? style(values) : style,
      'data-rac': '',
    }),
    [className, style, values],
  );
};

export const FileFieldContext =
  createContext<ContextValue<FileFieldProps, HTMLDivElement>>(null);
export const FileFieldStateContext = createContext<FileFieldState | null>(null);

export const FileField = forwardRef(function FileField(
  props: FileFieldProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  [props, ref] = useContextProps(props, ref, FileFieldContext);
  const { validationBehavior: formValidationBehavior } =
    useSlottedContext(FormContext) || {};
  const validationBehavior =
    props.validationBehavior ?? formValidationBehavior ?? 'native';

  const state = useFileFieldState({
    ...props,
    validationBehavior,
  });

  const inputRef = useRef(null);
  const [labelRef, label] = useSlot();

  const {
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    ...validation
  } = useFileField(
    {
      ...removeDataAttributes(props),
      label,
      validationBehavior,
    },
    state,
    inputRef,
  );

  /* eslint-disable-next-line testing-library/render-result-naming-convention --
   * This linting issue is a false positive. */
  const renderProps = useRenderProps({
    ...props,
    values: {
      isDisabled: props.isDisabled || false,
      isInvalid: validation.isInvalid,
      isReadOnly: props.isReadOnly || false,
      isRequired: props.isRequired || false,
    },
  });

  return (
    <div
      {...filterDOMProps(props)}
      {...renderProps}
      ref={ref}
      slot={props.slot || undefined}
      data-disabled={props.isDisabled || undefined}
      data-drop-target={state.isDropTarget || undefined}
      data-has-files={state.value.length ? true : undefined}
      data-invalid={validation.isInvalid || undefined}
      data-readonly={props.isReadOnly || undefined}
      data-required={props.isRequired || undefined}
    >
      <Provider
        values={[
          [FileFieldStateContext, state],
          [LabelContext, { ...labelProps, ref: labelRef }],
          [InputContext, { ...inputProps, ref: inputRef }],
          [
            TextContext,
            {
              slots: {
                description: descriptionProps,
                errorMessage: errorMessageProps,
              },
            },
          ],
          [FieldErrorContext, validation],
        ]}
      >
        {props.children}
      </Provider>
    </div>
  );
});
