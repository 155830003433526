import { CampaignManagerSummary } from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import ActiveCampaign from './active-campaign';
import InactiveCampaign from './inactive-campaign';

type Props = {
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
  user: User;
};

const CampaignInfo = ({ shopId, startDate, summaryData, user }: Props) => {
  const isLapsed = summaryData?.last90Days.lapsed;

  return (
    <>
      {isLapsed ? (
        <InactiveCampaign
          hasEnded={isLapsed}
          endDate={summaryData.last90Days.maxDsPt}
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
          user={user}
        />
      ) : (
        <ActiveCampaign
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
          user={user}
        />
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CampaignInfo;
