import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CustomerImportsSchema } from 'types/customers/profile/customer-profile-import';
import { Shop } from 'types/shops';

export const getCustomerImportsQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'getCustomerImports'];

export const useCustomerImportsQuery = (shopId: Shop['shopId']) => {
  const api = useApi();

  return useQuery({
    queryFn: async () => {
      const response = await api.getCustomerImports(shopId);

      return CustomerImportsSchema.parse(response);
    },
    queryKey: getCustomerImportsQueryKey(shopId),
  });
};
