import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { GetPublishersResponse } from 'types/brand-manager';
import { camelCaseKeys } from 'utilities/objects';

const getQueryKey = (shopId: string): QueryKey => [shopId, 'shopPublishers'];

export const useShopPublishersQuery = (
  shopId: string,
): UseQueryResult<GetPublishersResponse> => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/brand_manager/publishers/${shopId}`,
      );

      return camelCaseKeys(response) as GetPublishersResponse;
    },
  });
};
