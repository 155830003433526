import { useQuery, UseQueryResult } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  CustomerReview,
  CustomerReviewsRequestParams,
  CustomerReviewsResponse,
} from 'types/customer-feedback';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

const getReviewsQueryKey = (
  shopId: Shop['shopId'],
  params: CustomerReviewsRequestParams,
) => [shopId, 'reviews', ...(params ? [params] : [])];

export const useReviewsQuery = (
  shopId: Shop['shopId'],
  params: CustomerReviewsRequestParams,
  options?: {
    keepPreviousData: boolean;
  },
): UseQueryResult<CustomerReviewsResponse> => {
  const { authenticatedFetch } = useApi();

  const getReviews = async (
    shopId: Shop['shopId'],
  ): Promise<CustomerReviewsResponse> => {
    const query = queryString.stringify(snakeCaseKeys(params));
    const response = await authenticatedFetch.get(
      `api/management/v1/shops/${shopId}/feedback_responses?${query}`,
      {},
      true,
      true,
      true,
    );

    // Normalize the response pagination values to be a part of the response
    // similar to OPAS rather than dealing with headers outside API layer.
    return {
      data: camelCaseKeys(response.body) as CustomerReview[],
      meta: {
        pagination: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          results: Number(response.headers.get('X-Total') ?? 0),
        },
      },
    };
  };

  return useQuery({
    queryKey: getReviewsQueryKey(shopId, params),
    queryFn: () => getReviews(shopId),
    ...options,
  });
};
