import { useQuery } from '@tanstack/react-query';

import Suspended from 'components/shared/suspended';
import { TooltipIconButton } from 'components/shared/tooltips';
import useApi from 'hooks/use-api';
import { getFinancialsOrdersPath } from 'routes/paths/financials';
import { Shop } from 'types/shops/shop';

import {
  MetricTile,
  MetricTileContent,
  MetricTileDelta,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from '../metric-tile';

import styles from './styles.module.scss';

type Props = {
  activeDates: {
    current: {
      startDate: string;
      endDate: string;
    };
    previous: {
      startDate: string;
      endDate: string;
    };
  };
  className?: string;
  dataChameleonTarget?: string;
  shopId: Shop['shopId'];
};

export const VoidedSales = ({
  activeDates,
  className,
  dataChameleonTarget,
  shopId,
}: Props) => {
  const api = useApi();

  const { current } = activeDates;

  const { data, isLoading: isDataLoading } = useQuery(
    [shopId, 'tips-and-earnings', [current.startDate, current.endDate]],
    () =>
      api.getTipsAndEarnings(shopId, {
        startDate: current.startDate,
        endDate: current.endDate,
      }),
    {
      enabled: current != null,
    },
  );

  const voidedEarnings = Number(data?.voidedEarnings);
  const totalVoidedOrders = Number(data?.totalVoidedOrders);

  const isLoading = isDataLoading;

  return (
    <MetricTile
      className={className}
      link={{
        shopId: String(shopId),
        title: 'Go to digital orders Page',
        to: getFinancialsOrdersPath(shopId, 'online'),
        trackingName: 'voided_sales',
      }}
      dataChameleonTarget={dataChameleonTarget}
    >
      <MetricTileContent>
        <MetricTileHeader linkIncluded={true}>
          <MetricTileTitle>Voided Sales</MetricTileTitle>
          <TooltipIconButton label="voided sales info">
            These are orders that you have chosen not to accept or have been
            canceled by the customer.
          </TooltipIconButton>
        </MetricTileHeader>
        <Suspended isLoading={isLoading}>
          <MetricTileValue
            className={voidedEarnings !== 0 ? styles.highlightValue : ''}
            type="currency"
            value={voidedEarnings}
          />
          <MetricTileDelta
            healthIndicators="icons"
            isInverse={true}
            maxFractionDigits={0}
            statusMessages={{
              positive: 'Great Job! No voided sales',
              negative:
                totalVoidedOrders === 1 ? 'Canceled Order' : 'Canceled Orders',
            }}
            unit=""
            value={totalVoidedOrders !== 0 ? totalVoidedOrders : null}
          />
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};
