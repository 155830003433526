import { CalendarDate } from '@internationalized/date';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { RegisterMetrics } from 'types/orders';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

type Props = {
  dates: RangeValue<CalendarDate>;
  shopId: string;
  shopTimezone: string;
};

const useRosOrderMetrics = ({
  dates,
  shopId,
  shopTimezone,
}: Props): UseQueryResult<RegisterMetrics> => {
  const api = useApi();

  const params = {
    start: toStartOfDateAbsoluteString(dates.start, shopTimezone),
    finish: toEndOfDateAbsoluteString(dates.end, shopTimezone),
  };

  return useQuery({
    queryKey: [shopId, 'ros-order-metrics', params],
    queryFn: () => api.getRosOrderMetrics(shopId, params),
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useRosOrderMetrics;
