import { PropsWithChildren, useState } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseInput } from 'react-collapsed/dist/types';
import cx from 'classnames';

import ContentTile from '../content-tile';

import Body from './body';
import Header from './header';
import {
  CollapsibleTileAnimationState,
  CollapsibleTileVariant,
} from './utilities';

import styles from './styles.module.scss';

type CollapseEventListeners = Pick<
  UseCollapseInput,
  'onExpandStart' | 'onExpandEnd' | 'onCollapseStart' | 'onCollapseEnd'
>;

type SharedHeaderProps = Omit<
  Parameters<typeof Header>[0],
  'animationState' | 'getToggleProps' | 'variant'
>;

type Props = {
  bodyChameleonTarget?: string;
  bodyClassName?: string;
  dataTestId?: string;
  headerClassName?: string;
  isOpen: boolean;
  onCollapseEnd?: () => void;
  variant?: CollapsibleTileVariant;
} & CollapseEventListeners &
  SharedHeaderProps;

export const CollapsibleTile = ({
  bodyChameleonTarget,
  bodyClassName,
  dataTestId,
  children,
  className,
  headerClassName,
  isOpen,
  onCollapseEnd,
  onCollapseStart,
  onExpandStart,
  onExpandEnd,
  variant = CollapsibleTileVariant.Regular,
  ...headerProps
}: PropsWithChildren<Props>) => {
  const [animationState, setAnimationState] = useState(
    isOpen
      ? CollapsibleTileAnimationState.Expanded
      : CollapsibleTileAnimationState.Collapsed,
  );

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: isOpen,
    onCollapseEnd: () => {
      onCollapseEnd?.();
      setAnimationState(CollapsibleTileAnimationState.Collapsed);
    },
    onCollapseStart: () => {
      onCollapseStart?.();
      setAnimationState(CollapsibleTileAnimationState.Collapsing);
    },
    onExpandEnd: () => {
      onExpandEnd?.();
      setAnimationState(CollapsibleTileAnimationState.Expanded);
    },
    onExpandStart: () => {
      onExpandStart?.();
      setAnimationState(CollapsibleTileAnimationState.Expanding);
    },
  });

  return (
    <ContentTile className={cx(className, styles.tile)} dataTestId={dataTestId}>
      <Header
        animationState={animationState}
        className={headerClassName}
        getToggleProps={getToggleProps}
        variant={variant}
        {...headerProps}
      />
      <Body
        chameleonTarget={bodyChameleonTarget}
        className={bodyClassName}
        getCollapseProps={getCollapseProps}
        variant={variant}
      >
        {children}
      </Body>
    </ContentTile>
  );
};

export { CollapsibleTileVariant };

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CollapsibleTile;
