import { useEffect, useMemo, useState } from 'react';
import { CalendarDate, parseAbsolute } from '@internationalized/date';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';

import { PresetRangeValue, useDateFormatter } from 'crust';

import FilterDropdown, {
  FilterOption,
} from 'components/orders/filter-dropdown';
import TableFilter from 'components/orders/table-filter';
import Link from 'components/shared/link';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import useStatementMutation from 'hooks/use-statement-mutation';
import * as paths from 'routes/paths';
import {
  OnlineOrderFilterValue,
  OnlineOrderStatus,
  Order,
} from 'types/financials/online-orders';
import { OrderTabs } from 'types/orders';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { getOnlineOrderStatus } from 'utilities/financials/orders';

import useOrders from './use-orders';

import styles from './styles.module.scss';

type Props = {
  dates: PresetRangeValue<CalendarDate, string>;
  hideHeader?: boolean;
  hidePagination?: boolean;
  hideSortTriangle?: boolean;
  shopId: string;
  shopTimezone: string;
};

const filterOptions = [
  { label: 'All', value: OnlineOrderFilterValue.All },
  { label: 'Completed', value: OnlineOrderFilterValue.Completed },
  { label: 'Refunded', value: OnlineOrderFilterValue.Refunded },
  { label: 'Voided', value: OnlineOrderFilterValue.Void },
] as const satisfies FilterOption[];

const DEFAULT_SORTING = {
  id: 'createdAt',
  desc: true,
} as const;

const OnlineOrdersTable = ({
  dates,
  hideHeader = false,
  hidePagination = false,
  hideSortTriangle = false,
  shopId,
  shopTimezone,
}: Props) => {
  const {
    trackOrderPdfClick,
    trackSelectedOrderStatusFilter,
    trackClickedCreateAReport,
  } = useAnalytics();

  const [selectedStatus, setSelectedStatus] = useState<OnlineOrderFilterValue>(
    OnlineOrderFilterValue.All,
  );

  const setStatus = (status: string) => {
    setSelectedStatus(status as OnlineOrderFilterValue);
  };

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORTING]);

  const { data: onlineOrdersResponse, isLoading } = useOrders({
    dates,
    shopId,
    shopTimezone,
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: sorting[0] ?? DEFAULT_SORTING,
    status: selectedStatus,
  });

  const totalPages = onlineOrdersResponse?.meta.pagination.pages || 0;

  const suppressPagination =
    !onlineOrdersResponse?.meta.pagination || isLoading || totalPages <= 1;

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  }, [dates, selectedStatus]);

  const { mutate: onStatementClick } = useStatementMutation({
    shopId: Number(shopId),
  });

  const getRowClassName = (order: Order) => {
    return order.attributes.paymentStatus === 'void' ? (styles.void ?? '') : '';
  };

  // MM/DD/YYYY in en-US.
  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const timeFormatter = useDateFormatter({
    timeStyle: 'short',
    timeZone: shopTimezone,
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<Order>();

    // TODO: This function should be removed and the logic should be moved to the onClick handler after the feature flag is eliminated.
    const trackPdfClickHandler = (order: Order) => {
      trackOrderPdfClick({
        orderId: order.id,
        orderStatus: getOnlineOrderStatus(
          order.attributes.paymentStatus as OnlineOrderStatus,
        ),
        shopId: shopId.toString(),
      });
    };

    return [
      helper.accessor('id', {
        header: 'Order #',
        cell(ctx) {
          const order = ctx.row.original;

          return (
            <Button
              className={styles.orderNumber}
              disabled={order.attributes.faxPdfUploadedAt == null}
              variant={ButtonVariant.LinkStyleSecondary}
              onClick={() => {
                onStatementClick(order.id);
                trackPdfClickHandler(order);
              }}
            >
              {order.id}
            </Button>
          );
        },
      }),
      helper.accessor((order) => order.attributes.createdAt, {
        id: 'createdAt',
        header: 'Date',
        cell(ctx) {
          const value = parseAbsolute(ctx.getValue(), shopTimezone).toDate();

          return (
            <div className={styles.dateCell}>
              <div>{dateFormatter.format(value)}</div>
              <div className={styles.time}>{timeFormatter.format(value)}</div>
            </div>
          );
        },
        meta: {
          className: styles.dateColumn,
        },
      }),
      helper.accessor((order) => order.attributes.name, {
        id: 'name',
        header: 'Customer',
        meta: {
          className: styles.customerColumn,
        },
        cell(ctx) {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor(
        (order) => (order.attributes.delivery ? 'Delivery' : 'Pickup'),
        {
          id: 'orderType',
          header: 'Type',
          meta: {
            className: styles.orderTypeColumn,
          },
        },
      ),
      helper.accessor(
        (order) => toMoneyString(toDollarString(order.attributes.subtotal)),
        {
          id: 'subtotal',
          header: 'Subtotal',
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (order) => toMoneyString(toDollarString(order.attributes.tax)),
        {
          id: 'tax',
          header: 'Tax',
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (order) => toMoneyString(toDollarString(order.attributes.tip)),
        {
          id: 'tip',
          header: 'Tips',
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (order) => toMoneyString(toDollarString(order.attributes.total)),
        {
          id: 'total',
          header: 'Total',
          meta: {
            className: styles.moneyColumn,
          },
        },
      ),
      helper.accessor(
        (order) => {
          const originalStatus = order.attributes
            .paymentStatus as OnlineOrderStatus;
          return getOnlineOrderStatus(originalStatus);
        },
        {
          id: 'status',
          header: 'Status',
          meta: {
            className: styles.orderStatusColumn,
          },
        },
      ),
    ];
  }, [
    dateFormatter,
    onStatementClick,
    shopId,
    shopTimezone,
    timeFormatter,
    trackOrderPdfClick,
  ]);

  const table = useTable({
    columns,
    data: onlineOrdersResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    pageCount: onlineOrdersResponse?.meta.pagination.pages ?? 0,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableSorting: hideSortTriangle ? false : true,
    state: {
      isLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Online Orders',
    getRowClassName: ({ row }) => getRowClassName(row.original),
  });

  const trackItemClickHandler = (status: string) => {
    trackSelectedOrderStatusFilter({
      orderStatus: status as OnlineOrderStatus,
      shopId,
      tab: OrderTabs.Online,
    });
  };

  const selectedFilterOption =
    filterOptions.find((option) => option.value === selectedStatus) ??
    filterOptions[0];

  const handlePageChange = (selectedPage: number) => {
    table.setPageIndex(selectedPage - 1);
  };

  const handleCreateAReportClick = () => {
    trackClickedCreateAReport({
      dateRangeLabel: dates.label.toLowerCase(),
      endDate: dates.end.toString(),
      shopId,
      startDate: dates.start.toString(),
      tab: OrderTabs.Online,
    });
  };

  return (
    <>
      {!hideHeader && (
        <TableFilter
          currentPage={table.getState().pagination.pageIndex}
          dates={dates}
          itemsTotal={onlineOrdersResponse?.meta?.pagination.total ?? 0}
          maxPageSize={table.getState().pagination.pageSize}
          pageSize={onlineOrdersResponse?.data?.length ?? 0}
          timezone={shopTimezone}
        >
          <FilterDropdown
            filterBy="Order Status"
            filterOptions={filterOptions}
            selectedStatus={selectedFilterOption}
            setSelectedStatus={setStatus}
            onItemClick={trackItemClickHandler}
          />
          <Link
            className={styles.downloadCsvLink}
            onClick={handleCreateAReportClick}
            to={{
              pathname: paths.getFinancialsReportsOrdersPath(shopId),
            }}
            state={{
              orderType: 'online',
              startDate: dates.start.toString(),
              endDate: dates.end.toString(),
            }}
            variant="primary"
          >
            Create a Report
          </Link>
        </TableFilter>
      )}
      <Table className={styles.orderTable} table={table} />
      {!hidePagination && (
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          isPageControlVisible={!suppressPagination}
          onChangePage={handlePageChange}
          totalPages={onlineOrdersResponse?.meta.pagination.pages ?? 0}
          perPage={pagination.pageSize}
          onChangePerPage={setPerPage}
        />
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OnlineOrdersTable;
