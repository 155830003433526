import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';

import { getCategoryQueryKey } from './use-category-query';
import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';

type Values = {
  categoryId: MenuCategory['id'];
};

export const useDeleteCategoryMutation = (
  shopId: Shop['shopId'],
): UseMutationResult<void, ApiRequestError, Values> => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ categoryId }: Values) => api.deleteCategory(shopId, categoryId),
    {
      onSuccess: (_, { categoryId }) => {
        queryClient.removeQueries(getCategoryQueryKey(shopId, categoryId));
        queryClient.invalidateQueries(getMenuQueryKey(shopId));
        queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
      },
    },
  );
};
