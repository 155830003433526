import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import {
  GetReportsRequestParams,
  GetTaxReportsResponse,
} from 'types/financials';
import { toSnakeCase } from 'utilities/strings';

type UseTaxReportsQueryOptions = Omit<
  UseQueryOptions<GetTaxReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getTaxReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'basicTaxReports', ...(params ? [params] : [])];

export const useTaxReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseTaxReportsQueryOptions,
): UseQueryResult<GetTaxReportsResponse> => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: `${sorting.desc ? '-' : '+'}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getTaxReportsQueryKey(shopId, params),
    queryFn: () => api.getBasicTaxReports(shopId, params),
    keepPreviousData: true,
    ...options,
  });
};
