import {
  getCoreRowModel as getDefaultCoreRowModel,
  getExpandedRowModel as getDefaultExpandedRowModel,
  getFilteredRowModel as getDefaultFilteredRowModel,
  getSortedRowModel as getDefaultSortedRowModel,
  RowData,
  Table,
  TableMeta,
  TableOptions,
  TableState,
  useReactTable,
} from '@tanstack/react-table';

type UseTableState = {
  isLoading?: boolean;
} & TableState;

type UseTableOptions<TData extends RowData> = {
  state?: Partial<UseTableState>;
} & Omit<TableOptions<TData>, 'getCoreRowModel' | 'meta' | 'state'> &
  Partial<Pick<TableOptions<TData>, 'getCoreRowModel'>> &
  TableMeta<TData>;

export const useTable = <TData extends RowData>({
  chameleonTableTitle,
  columns,
  enableMultiSort = false,
  enableSortingRemoval = false, // We don't allow unsorting.
  getCoreRowModel = getDefaultCoreRowModel(),
  getEmptyPlaceholder,
  getExpandedRowModel = getDefaultExpandedRowModel(),
  getFilteredRowModel = getDefaultFilteredRowModel(),
  getRowClassName,
  getRowExpansion,
  getSortedRowModel = getDefaultSortedRowModel(),
  sortDescFirst = false,
  state,
  ...options
}: UseTableOptions<TData>): Table<TData> => {
  const isLoading = state?.isLoading;

  if (state) {
    delete state.isLoading;
  }

  return useReactTable<TData>({
    columns,
    enableMultiSort,
    enableSortingRemoval,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    sortDescFirst,
    state,
    meta: {
      chameleonTableTitle,
      getEmptyPlaceholder,
      getRowClassName,
      getRowExpansion,
      isLoading,
    },
    ...options,
  });
};
