import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

export const RegisterTipSettingsSchema = z.object({
  adjustmentLimitPercent: z.string(),
  defaultTips: z.array(
    z.object({ amount: z.string(), amountType: z.string() }),
  ),
});

export const getRegisterTipSettingsQueryKey = (shopId: Shop['shopId']) => [
  shopId,
  'registerTipSettings',
];

export const useRegisterTipSettings = (shopId: Shop['shopId']) => {
  const api = useApi();

  return useQuery(getRegisterTipSettingsQueryKey(shopId), async () => {
    const response = await api.getRegitserTipSettings(String(shopId));

    return RegisterTipSettingsSchema.parse(response);
  });
};
