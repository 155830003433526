import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import RegisterUserForm from 'components/register/users/register-user';
import { UnexpectedError } from 'components/shared/error';
import Navigation from 'components/shared/navigation';
import Suspended from 'components/shared/suspended';
import { useCurrentShopId, useShopsQuery } from 'hooks/shops';
import { ShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import { useShopTraitsQuery } from 'hooks/shops/use-shop-traits-query';
import {
  AddDebitCard,
  AnalyticsPage,
  AssetLibraryPage,
  AutomaticDiscountPage,
  BrandManagerPage,
  CampaignManagerPage,
  CustomerAnalyticsPage,
  CustomerFeedback,
  CustomerProfilesPage,
  CustomerSegmentsPage,
  CustomWebsitePage,
  DashboardPage,
  DebitCards,
  DigitalOrdersPage,
  DiscountsPage,
  GoogleBusinessProfilePage,
  Hours,
  HoursAnalyticsPage,
  Logout,
  Marketing,
  MenuCategoriesPage,
  MenuCategoryPage,
  MenuLayoutPage,
  MenuProductPage,
  MenuProductsPage,
  NotFound,
  OperationsPage,
  PasswordReset,
  PasswordResetEmail,
  PayoutsPage,
  PromoCodePage,
  RegisterDiscountPage,
  RegisterDiscountsPage,
  RegisterLayoutPage,
  RegisterPermissionGroupPage,
  RegisterPrintingPage,
  RegisterProfiles,
  RegisterSplashPage,
  RegisterTipSettings,
  RegisterUsers,
  ReportsPage,
  ShopIntegrationsPage,
  Shops,
  ShopScorePage,
  ShopUuidRedirect,
  SliceOrderingPage,
  SmartButtonPage,
  SmartPopupPage,
  Statements,
} from 'pages';

import * as accountPaths from './paths/account';
import * as authPaths from './paths/authentication';
import * as customersPaths from './paths/customers';
import * as financialsPaths from './paths/financials';
import * as hoursPaths from './paths/hours';
import * as marketingPaths from './paths/marketing';
import * as menuPaths from './paths/menu';
import * as onlinePresencePaths from './paths/online-presence';
import * as operationsPaths from './paths/operations';
import * as registerPaths from './paths/register';
import * as websiteToolsPaths from './paths/website-tools';
import ScrollToTop from './ScrollToTop';

// React Router combines the paths of nested routes with the path of the parent
// route. Instead of changing all of our route and path definitions at once,
// we'll simply remove the parent path component manually.
const nest = (path, parent = '/shops/:shopId/') => path.replace(parent, '');

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <ScrollToTop location={location}>
      <Routes>
        <Route path="/" element={<Navigate replace to="/shops" />} />
        <Route path="shops/*" element={<ShopsRoutes />} />
        <Route path={authPaths.logout} element={<Logout />} />

        {/* There are still some restaurant services using non-Auth0
        authentication. https://app.shortcut.com/slicelife/story/307280. */}
        <Route
          path="reset-password"
          element={<Navigate replace to={authPaths.passwordResetEmail} />}
        />
        <Route
          path="login"
          element={<Navigate replace to={authPaths.passwordReset} />}
        />
        <Route
          path={authPaths.passwordResetEmail}
          element={<PasswordResetEmail />}
        />
        <Route path={authPaths.passwordReset} element={<PasswordReset />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
};

// All shops related routes require authentication.
const ShopsRoutes = withAuthenticationRequired(() => (
  <Routes>
    <Route path=":shopId/*" element={<ShopRoutes />} />
    <Route path="/" element={<Shops />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
));

const ShopRoutes = () => {
  const location = useLocation();
  const shopId = useCurrentShopId();

  const {
    data: shops,
    isError: isShopsError,
    isLoading: isShopsLoading,
  } = useShopsQuery();

  const isOwner = shops?.some((it) => it.shopId === shopId) ?? false;

  const {
    data: traits,
    isError: isTraitsError,
    isFetching: isTraitsFetching,
    isLoading: isTraitsLoading,
  } = useShopTraitsQuery(shopId, {
    enabled: isOwner, // Don't leak shop data.
    staleTime: 300000, // Five minutes
  });

  if (isShopsLoading || (isTraitsLoading && isTraitsFetching)) {
    return <Suspended isLoading variant={'viewPort'} />;
  }

  if (isShopsError || isTraitsError) {
    return <UnexpectedError />;
  }

  if (!isOwner) {
    // The authenticated user is not the owner of the shop specified in the URL.
    // We'll redirect them to the same page, but for a shop that they own.
    return (
      <Navigate
        replace
        to={location.pathname.replace(String(shopId), String(shops[0].shopId))}
      />
    );
  }

  return (
    <ShopTraitsContext.Provider value={traits}>
      <Navigation shopId={String(shopId)} />
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route
          // Shop UUID redirect for POS integrations.
          path="redirect"
          element={<ShopUuidRedirect />}
        />
        <Route
          path={nest(financialsPaths.getFinancialsStatementsPath(':shopId'))}
          element={<Statements />}
        />
        <Route
          path={nest(financialsPaths.getFinancialsPayoutsPath(':shopId'))}
          element={<PayoutsPage />}
        />
        <Route
          path={nest(financialsPaths.getFinancialsOrdersPath())}
          element={<DigitalOrdersPage />}
        />
        <Route
          path={nest(accountPaths.shopIntegrations(':shopId'))}
          element={<ShopIntegrationsPage />}
        />
        <Route
          path={nest(
            websiteToolsPaths.shopPartnerButtonsCreateCustomButton(':shopId'),
          )}
          element={<SliceOrderingPage />}
        />
        <Route
          path={nest(websiteToolsPaths.shopPartnerButtonsGetLink(':shopId'))}
          element={<SliceOrderingPage />}
        />
        <Route path="analytics/*" element={<AnalyticsPage />} />
        <Route
          path={nest(onlinePresencePaths.googleBusinessProfile(':shopId'))}
          element={<GoogleBusinessProfilePage />}
        />
        <Route
          path={nest(onlinePresencePaths.shopScore(':shopId'))}
          element={<ShopScorePage />}
        />

        {/* Hours */}
        <Route
          path={nest(hoursPaths.shopHoursAnalytics(':shopId'))}
          element={<HoursAnalyticsPage />}
        />
        <Route
          path={nest(hoursPaths.shopHours(':shopId'))}
          element={<Hours />}
        />
        <Route
          path={nest(hoursPaths.shopClosings(':shopId'))}
          element={<Hours />}
        />
        <Route
          path={nest(hoursPaths.shopHoursDelivery(':shopId'))}
          element={<Hours />}
        />
        <Route
          path={nest(hoursPaths.shopHoursWeekly(':shopId'))}
          element={<Hours />}
        />

        {/* Discounts */}
        <Route
          path={nest(marketingPaths.newPromoCode(':shopId'))}
          element={<PromoCodePage />}
        />
        <Route
          path={nest(marketingPaths.promoCodes(':shopId'))}
          element={<DiscountsPage />}
        />
        <Route
          path={nest(marketingPaths.discountDealsPath(':shopId'))}
          element={<DiscountsPage />}
        />
        <Route
          path={nest(
            marketingPaths.automaticDiscount(':shopId', ':discountId'),
          )}
          element={<AutomaticDiscountPage />}
        />
        <Route
          path={nest(marketingPaths.automaticDiscounts(':shopId'))}
          element={<DiscountsPage />}
        />

        {/* Menu */}
        <Route
          path={nest(
            registerPaths.registerLayoutCategory(':shopId', ':categoryId'),
          )}
          element={<RegisterLayoutPage />}
        />
        <Route
          path={nest(registerPaths.registerLayout(':shopId'))}
          element={<RegisterLayoutPage />}
        />
        <Route
          path={nest(menuPaths.getMenuCategoryPath(':shopId', ':categoryId'))}
          element={<MenuCategoryPage />}
        />
        <Route
          path={nest(menuPaths.getMenuCategoriesPath(':shopId'))}
          element={<MenuCategoriesPage />}
        />
        <Route
          path={nest(menuPaths.getMenuLayoutPath(':shopId'))}
          element={<MenuLayoutPage />}
        />
        <Route
          path={nest(menuPaths.getMenuItemPath(':shopId', ':productId'))}
          element={<MenuProductPage />}
        />
        <Route
          path={nest(menuPaths.getMenuItemsPath(':shopId'))}
          element={<MenuProductsPage />}
        />

        {/* Reports */}
        <Route path="financials/reports/*" element={<ReportsPage />} />

        {/* Settings */}
        <Route
          path={nest(registerPaths.shopRegisterPrinting(':shopId'))}
          element={<RegisterPrintingPage />}
        />

        {/* Debit Cards */}
        <Route
          path={nest(accountPaths.debitCardNew(':shopId'))}
          element={<AddDebitCard />}
        />
        <Route
          path={nest(accountPaths.debitCards(':shopId'))}
          element={<DebitCards />}
        />

        {/* Register Profiles */}
        <Route
          path={nest(registerPaths.registerProfiles(':shopId'))}
          element={<RegisterProfiles />}
        />

        {/* Register Users */}
        <Route
          path={nest(registerPaths.registerUser(':shopId', ':userId'))}
          element={<RegisterUserForm />}
        />

        <Route
          path={nest(registerPaths.registerUsers(':shopId'))}
          element={<RegisterUsers />}
        />

        {/* Register Permissions */}
        <Route
          path={nest(
            registerPaths.registerPermission(':shopId', ':permissionGroupId'),
          )}
          element={<RegisterPermissionGroupPage />}
        />

        <Route
          path={nest(registerPaths.registerPermissions(':shopId'))}
          element={<RegisterUsers />}
        />

        {/* Register Permissions Log */}
        <Route
          path={nest(registerPaths.registerPermissionsLog(':shopId'))}
          element={<RegisterUsers />}
        />

        {/* Customers */}
        <Route
          path={nest(customersPaths.getCustomerAnalyticsPath(':shopId'))}
          element={<CustomerAnalyticsPage />}
        />
        <Route
          path={nest(customersPaths.getCustomerSegmentsPath(':shopId'))}
          element={<CustomerSegmentsPage />}
        />
        <Route
          path={nest(customersPaths.getCustomerProfilesPath(':shopId'))}
          element={<CustomerProfilesPage />}
        />
        {/* This redirect is from an old customer dashboard/analytics urls to the latest
        that the owners app currently uses. See:

        - https://app.shortcut.com/slicelife/story/512958/add-back-the-redirect-from-the-old-customer-dashboard-url-to-the-newest-customer-dashboard-url
        - https://app.shortcut.com/slicelife/story/458790/the-customer-dashboard-button-leads-to-a-blank-page-in-the-op-web

        We also need the '..' in the 'to' path so we replace the customer-dashboard part of the old url rather than append to it
        */}
        <Route
          path={nest(
            customersPaths.getCustomerDashboardPathDeprecated(':shopId'),
          )}
          element={
            <Navigate
              replace
              to={`../${nest(customersPaths.getCustomerAnalyticsPath(':shopId'))}`}
            />
          }
        />
        <Route
          path={nest(customersPaths.getCustomerFeedbackPath(':shopId'))}
          element={<CustomerFeedback />}
        />

        {/* Marketing */}
        <Route
          path={nest(marketingPaths.shopMarketing(':shopId'))}
          element={<Marketing />}
        />
        <Route
          path={nest(onlinePresencePaths.brandManager(':shopId'))}
          element={<BrandManagerPage />}
        />
        <Route
          path={nest(marketingPaths.assetLibrary(':shopId'))}
          element={<AssetLibraryPage />}
        />
        <Route
          path={nest(marketingPaths.campaignManager(':shopId'))}
          element={<CampaignManagerPage />}
        />

        {/* Custom Website */}
        <Route
          path={nest(websiteToolsPaths.shopCustomWebsite(':shopId'))}
          element={<CustomWebsitePage />}
        />

        {/* Smart Buttons */}
        <Route
          path={nest(websiteToolsPaths.shopSmartButtons(':shopId'))}
          element={<SmartButtonPage />}
        />

        {/* Smart Popups */}
        <Route
          path={nest(websiteToolsPaths.shopSmartPopups(':shopId'))}
          element={<SmartPopupPage />}
        />

        {/* Register */}
        <Route
          path={nest(registerPaths.registerDiscount(':shopId', ':discountId'))}
          element={<RegisterDiscountPage />}
        />
        <Route
          path={nest(registerPaths.registerDiscounts(':shopId'))}
          element={<RegisterDiscountsPage />}
        />
        <Route
          path={nest(registerPaths.registerTipSettings(':shopId'))}
          element={<RegisterTipSettings />}
        />
        <Route
          path={nest(registerPaths.getRegisterSplashPath(':shopId'))}
          element={<RegisterSplashPage />}
        />

        {/* Operations */}
        <Route
          path={nest(operationsPaths.operations(':shopId'))}
          element={<OperationsPage />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ShopTraitsContext.Provider>
  );
};
