import { camelCaseKeys } from 'utilities/objects';

export type NetworkAssetLibraryCategory = {
  children: NetworkAssetLibraryCategory[];
  created_at: string;
  id: string;
  name: string;
  parent_id: string | null;
  updated_at: string;
};

export const networkToDomainAssetLibraryCategories = (
  networkCategories: NetworkAssetLibraryCategory[],
): AssetLibraryCategory[] => {
  return camelCaseKeys(networkCategories);
};

export type AssetLibraryCategory = {
  children: AssetLibraryCategory[];
  createdAt: string;
  id: string;
  name: string;
  parentId: string | null;
  updatedAt: string;
};
