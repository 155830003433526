import { CalendarDate } from '@internationalized/date';
import { RangeValue } from '@react-types/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

import { getRegisterSalesSummaryReportsQueryKey } from './use-register-sales-summary-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateRegisterSalesSummaryReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ dates }: Values) =>
      api.createRegisterSalesSummaryReport(shopId, {
        start: toStartOfDateAbsoluteString(dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(dates.end, shopTimezone),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getRegisterSalesSummaryReportsQueryKey(shopId),
        );
      },
    },
  );
};
