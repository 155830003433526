import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterUser } from 'types/register-users';

export const getRegisterUsersQueryKey = (shopId: string): QueryKey => [
  shopId,
  'registerUsers',
];

export const useRegisterUsersQuery = (
  shopId: string,
  options?: UseQueryOptions<RegisterUser[]>,
): UseQueryResult<RegisterUser[]> => {
  const api = useApi();

  return useQuery(
    getRegisterUsersQueryKey(shopId),
    () => api.getRegisterUsers(shopId),
    { ...options },
  );
};
