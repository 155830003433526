import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop, ShopRatingsResponse } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

const getShopRatingsQueryKey = (shopId: Shop['shopId']) =>
  [shopId, 'ratings'] as const;

export const useShopRatingsQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  const getShopRatings = async (
    shopId: Shop['shopId'],
  ): Promise<ShopRatingsResponse> => {
    const response = await authenticatedFetch.get(
      `api/management/v1/shops/${shopId}/ratings`,
    );

    return camelCaseKeys(response);
  };

  return useQuery({
    queryKey: getShopRatingsQueryKey(shopId),
    queryFn: () => getShopRatings(shopId),
  });
};
