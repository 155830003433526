import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  RecommendedPermissionGroup,
  RegisterPermission,
  RegisterPermissionGroup,
  RegisterUser,
  RegisterUserRowData,
} from 'types/register-users';
import { getRegisterUserRowData } from 'utilities/register-users';

type UseRegisterUsers = {
  isLoading: boolean;
  permissionGroups: RegisterPermissionGroup[];
  permissions: RegisterPermission[];
  recommendedPermissionGroups: RecommendedPermissionGroup[];
  users: RegisterUser[];
  usersRowData: RegisterUserRowData[];
};

const useRegisterUsers = (shopId: string): UseRegisterUsers => {
  const api = useApi();

  const { data: usersData, isLoading: isUsersLoading } = useQuery(
    [shopId, 'registerUsers'],
    () => api.getRegisterUsers(shopId),
  );

  const { data: permissionsInfo, isLoading: isRecommendedGroupsLoading } =
    useQuery(['recommendedPermissionGroups'], () =>
      api.getRecommendedPermissionGroups(),
    );

  const { data: groupsData, isLoading: isGroupsLoading } = useQuery(
    [shopId, 'permissionGroups'],
    () => api.getPermissionGroups(shopId),
  );

  let usersRowData: RegisterUserRowData[] = [];

  if (!isUsersLoading && !isGroupsLoading && usersData && groupsData) {
    usersRowData = getRegisterUserRowData({
      groupsData,
      usersData,
    });
  }

  return {
    isLoading: isUsersLoading || isRecommendedGroupsLoading || isGroupsLoading,
    permissionGroups: groupsData ?? [],
    permissions: permissionsInfo?.permissions ?? [],
    recommendedPermissionGroups:
      permissionsInfo?.recommendedPermissionGroups ?? [],
    users: usersData ?? [],
    usersRowData,
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useRegisterUsers;
