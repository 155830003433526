import { CalendarDate } from '@internationalized/date';

import { RangeValue } from 'crust';

export enum ReportStatus {
  Pending = 'pending',
  Queued = 'queued',
  Processing = 'processing',
  Failure = 'failure',
  Success = 'success',
}

// https://github.com/slicelife/artefact-document-service/blob/main/app/v1/reports/models.py#L15
enum ArtefactServiceReportKind {
  SalesMixReport = 'sales_mix_report',
  OfflineOrderReport = 'offline_order_report',
  OnlineOrderReport = 'online_order_report',
  SalesSummaryReport = 'sales_summary_report',
  StandalonePaymentsReport = 'standalone_payments_report',
}

// https://github.com/slicelife/artefact-document-service/blob/15ad26d5e4c0ae063d2e87835cab97de95f21c49/app/v1/reports/domain.py#L9
type ArtefactServiceReport = {
  id: string;
  shopUuid: string;
  fromDate: string;
  toDate: string;
  report: ArtefactServiceReportKind;
  status: ReportStatus;
  createdAt: string;
  updatedAt: string;
  downloadUrl: string;
  orderTypes?: string[];
};

export type SalesMixReport = {
  report: ArtefactServiceReportKind.SalesMixReport;
} & ArtefactServiceReport;

export type SalesSummaryReport = {
  report: ArtefactServiceReportKind.SalesSummaryReport;
} & ArtefactServiceReport;

export type StandalonePaymentsReport = {
  report: ArtefactServiceReportKind.StandalonePaymentsReport;
} & ArtefactServiceReport;

export type OrdersReport = {
  report:
    | ArtefactServiceReportKind.OfflineOrderReport
    | ArtefactServiceReportKind.OnlineOrderReport;
} & ArtefactServiceReport;

type AdminReport = {
  createdAt: string;
  // The file name is only set once the report job starts.
  // https://github.com/slicelife/myr-admin/blob/9c91e32ab247873b5c894b83f8a5ee55535480fa/lib/basic_tax_report_generator.rb#L24
  fileName: string | null;
  fromDate: string;
  id: number;
  // The url is only returned if the pdf exists, regardless of status.
  // https://github.com/slicelife/myr-admin/blob/9c91e32ab247873b5c894b83f8a5ee55535480fa/app/models/basic_tax_report.rb#L90
  pdfDownloadUrl: string | null;
  shopId: number;
  status: ReportStatus;
  toDate: string;
  total: string;
  updatedAt: string;
};

export type TaxReport = AdminReport;

export type OrderActivityReport = {
  adjustmentsCount: number;
  adjustmentsTotal: string;
  cashCreditCommissionFeesTotal: string;
  chargebackAmount: string;
  commissionFeesTotal: string;
  courtesyCredits: string;
  creditCardSalesTax: string;
  creditCardSalesTips: string;
  deliveryFeesTotal: string;
  deliveryServiceFees: string;
  directMypizzaFees: string;
  estimatedPhoneSales: string;
  feesTotal: string;
  marketingCoOpAmount: string;
  merchantCashAdvanceAmount: string;
  mypizzaFees: string;
  mypizzaPhoneFees: string;
  mypizzaProcessingFees: string;
  netSales: string;
  netTotal: string;
  nonTaxableSales: string;
  offlineCashTotal: string;
  offlineCreditTotal: string;
  offlineDeliveryFees: string;
  offlineDeliveryQty: number;
  offlineDeliveryTotal: string;
  offlineDiscountQty: number;
  offlineDiscountTotal: string;
  offlineForHereQty: number;
  offlineForHereTotal: string;
  offlineNetTotal: string;
  offlineOtherTotal: string;
  offlinePickupQty: number;
  offlinePickupTotal: string;
  offlineQty: number;
  offlineRefundedQty: number;
  offlineRefundedTotal: string;
  offlineTaxes: string;
  offlineTips: string;
  offlineTotal: string;
  onlineAdjustmentsTotal: string;
  onlineDeliveryQty: number;
  onlineDeliveryTotal: string;
  onlinePickupQty: number;
  onlinePickupTotal: string;
  onlineQty: number;
  onlineSalesTaxWithholding: string;
  onlineSliceFees: string;
  onlineTaxes: string;
  onlineTips: string;
  onlineTotal: string;
  onlyOnlineOrders: boolean;
  orderAndFeesTotal: string;
  orderDeliveryFees: string;
  pastDue: string;
  payoutAmount: string;
  pdfDownloadUrl: string | null;
  promoCodesAmount: string;
  serviceFeeTaxes: string;
  serviceFeeTaxesAmount: string;
  shopTotalCreditSales: string;
  shopTotalSalesTaxesAmount: string;
  sliceCredits: string;
  sliceDirectSaasFees: string;
  totalAdjustmentAmount: string;
  totalAdjustmentExpenses: string;
  totalCash: number;
  totalCashFees: string;
  totalCashSales: string;
  totalCredit: number;
  totalCreditFees: string;
  totalCreditSales: string;
  totalDirectCashFees: string;
  totalDirectCashOrders: string;
  totalDirectCashSales: string;
  totalDirectCreditFees: string;
  totalDirectCreditOrders: string;
  totalDirectCreditProcessingFees: string;
  totalDirectCreditSales: string;
  totalDirectPaySales: string;
  totalMarketplaceCashFees: string;
  totalMarketplaceCashOrders: string;
  totalMarketplaceCashSales: string;
  totalMarketplaceCreditFees: string;
  totalMarketplaceCreditOrders: string;
  totalMarketplaceCreditProcessingFees: string;
  totalMarketplaceCreditSales: string;
  totalPhone: number;
  totalSales: string;
  totalSalesTaxesAmount: string;
} & AdminReport;

export type BaseReportFormValues = {
  start: string;
  finish: string;
};

export type SalesSummaryReportFormValues = {
  dates: RangeValue<CalendarDate>;
  order_types?: 'online' | 'offline' | 'full';
};

export type BaseCreateReportRequestParams = {
  start: string;
  finish: string;
};

export type CreateSalesSummaryRequestParams = {
  order_types?: 'online' | 'offline' | 'full';
} & BaseCreateReportRequestParams;

export type CreateSalesMixReportRequestParams = BaseCreateReportRequestParams;

export type CreateSalesMixReportResponse = Pick<SalesMixReport, 'id'>;

export type CreateRegisterSalesSummaryReportRequestParams =
  BaseCreateReportRequestParams;

export type CreateStandalonePaymentsReportRequestParams =
  BaseCreateReportRequestParams;

export type CreateSalesSummaryReportResponse = Pick<SalesSummaryReport, 'id'>;

export type CreateStandalonePaymentsReportResponse = Pick<
  SalesSummaryReport,
  'id'
>;

export type OrdersReportOrderType = 'online' | 'register';

export type OrdersReportFormValues = {
  orderType: OrdersReportOrderType;
  dates: RangeValue<CalendarDate>;
};

export type CreateOrdersReportRequestParams = {
  finish: string;
  start: string;
};

export type CreateOrdersReportResponse = Pick<OrdersReport, 'id'>;

export type CreateOrderActivityReportResponse = Pick<OrderActivityReport, 'id'>;

export type CreateOrderActivityReportRequestParams = {
  end: string;
  onlyOnlineOrders: boolean;
  start: string;
};

export type CreateTaxReportRequestParams = {
  start: string;
  end: string;
};

export type GetReportsRequestParams = {
  page: number;
  perPage: number;
  sort?: string | undefined;
};

type Report = AdminReport | ArtefactServiceReport;

type GetReportsResponsePagination = {
  currentPage: number;
  pages: number;
  perPage: number;
  total: number;
};

type GetReportsResponseMeta<R extends Report> = {
  pagination: R extends AdminReport
    ? Omit<GetReportsResponsePagination, 'currentPage' | 'perPage'>
    : GetReportsResponsePagination;
};

export type GetReportsResponse<R extends Report> = {
  data: R[];
  meta: GetReportsResponseMeta<R>;
};

export type GetOrderActivityReportsResponse =
  GetReportsResponse<OrderActivityReport>;
export type GetSalesMixReportsResponse = GetReportsResponse<SalesMixReport>;
export type GetTaxReportsResponse = GetReportsResponse<TaxReport>;
export type GetOrdersReportsResponse = GetReportsResponse<OrdersReport>;
export type GetSalesSummaryReportsResponse =
  GetReportsResponse<SalesSummaryReport>;
export type GetStandalonePaymentsReportsResponse =
  GetReportsResponse<StandalonePaymentsReport>;

export enum ReportAnalyticsType {
  OrderActivity = 'order activity report',
  SalesMix = 'sales mix report',
  Tax = 'tax report',
  Order = 'orders report',
  RegisterSalesSummary = 'register sales summary report',
  StandalonePayments = 'standalone payments report',
}

type BaseReportAnalyticsData = {
  type: ReportAnalyticsType;
  startDate: string;
  endDate: string;
};

type SalesMixReportAnalyticsData = {
  type: ReportAnalyticsType.SalesMix;
} & BaseReportAnalyticsData;

type TaxReportAnalyticsData = {
  type: ReportAnalyticsType.Tax;
} & BaseReportAnalyticsData;

type OrderActivityReportAnalyticsData = {
  type: ReportAnalyticsType.OrderActivity;
  excludeRegisterTransactionsChecked: boolean;
} & BaseReportAnalyticsData;

type OrdersReportAnalyticsData = {
  shopId: number;
  type: ReportAnalyticsType.Order;
  ordersReportDataSource: OrdersReportOrderType;
} & BaseReportAnalyticsData;

type SalesSummaryReportAnalyticsData = {
  type: ReportAnalyticsType.RegisterSalesSummary;
} & BaseReportAnalyticsData;

type StandalonePaymentsReportAnalyticsData = {
  type: ReportAnalyticsType.StandalonePayments;
} & BaseReportAnalyticsData;

export type CreateReportAnalyticsData =
  | SalesMixReportAnalyticsData
  | TaxReportAnalyticsData
  | OrderActivityReportAnalyticsData
  | OrdersReportAnalyticsData
  | SalesSummaryReportAnalyticsData
  | StandalonePaymentsReportAnalyticsData;

type BaseDownloadReportAnalyticsData = {
  shopId: number;
  type: ReportAnalyticsType;
  createdDate: string;
  fileType: string;
};

export type DownloadOrderReportAnalyticsData = {
  type: ReportAnalyticsType.Order;
  ordersReportDataSource: OrdersReportOrderType;
} & BaseDownloadReportAnalyticsData;

export type DownloadRegisterSalesSummaryReportAnalyticsData = {
  type: ReportAnalyticsType.RegisterSalesSummary;
} & BaseDownloadReportAnalyticsData;

export type DownloadStandalonePaymentsReportAnalyticsData = {
  type: ReportAnalyticsType.StandalonePayments;
} & BaseDownloadReportAnalyticsData;

export type DownloadReportAnalyticsData =
  | DownloadOrderReportAnalyticsData
  | DownloadRegisterSalesSummaryReportAnalyticsData
  | DownloadStandalonePaymentsReportAnalyticsData;

export type OrderReportsLocationState = {
  orderType?: OrdersReportOrderType;
  startDate?: string;
  endDate?: string;
};
