import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import { Switch } from 'crust';

import { getShopQueryKey } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

import PermissionsToggleTooltip from './tooltip';

import styles from './styles.module.scss';

type Props = {
  isEnabled: boolean;
  shopId: string;
};

const PermissionsToggle = ({ isEnabled, shopId }: Props) => {
  const { trackClickedRegisterPermissionsOn } = useAnalytics();

  /*
    The message to remind users to download changes on their iPad should only
    appear if the user just recently turned permissions off. If the user comes
    back to Owner's Portal in another session and the permissions are already
    turned off, don't display the message.
  */
  const isOffMessageVisible = sessionStorage.getItem(
    'isPermissionsOffMessageVisible',
  );

  const setIsOffMessageVisible = (value: boolean) =>
    sessionStorage.setItem(
      'isPermissionsOffMessageVisible',
      Boolean(value).toString(),
    );

  const api = useApi();
  const queryClient = useQueryClient();

  const { mutate: setPermissionsEnabled } = useMutation(
    (nextIsEnabled: boolean) =>
      api.updatePermissionsToggle(shopId, nextIsEnabled),
    {
      onMutate: async (nextIsEnabled: boolean) => {
        if (!nextIsEnabled && !isOffMessageVisible) {
          setIsOffMessageVisible(true);
        }

        const queryKey = getShopQueryKey(shopId);

        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        // Optimistically update to the new value
        queryClient.setQueryData<Shop>(queryKey, (shop) =>
          shop
            ? { ...shop, registerUserPermissions: nextIsEnabled }
            : undefined,
        );
      },
      onError: (_error, nextIsEnabled) => {
        const correctValue = !nextIsEnabled;

        queryClient.setQueryData<Shop>(getShopQueryKey(shopId), (shop) =>
          shop
            ? {
                ...shop,
                registerUserPermissions: correctValue,
              }
            : undefined,
        );

        toast.error('An unexpected error occurred. Please try again later.');
      },
      onSettled: () => {
        queryClient.invalidateQueries(getShopQueryKey(shopId));
      },
    },
  );

  return (
    <div className={cx(styles.permissionsToggleContainer)}>
      <div
        className={styles.permissionsToggle}
        data-chameleon-target="Register Permissions Toggle"
      >
        <Switch
          isSelected={isEnabled}
          label="Enable Register Permissions"
          onChange={(isChecked) => {
            if (isChecked) {
              trackClickedRegisterPermissionsOn(shopId);
            }
            setPermissionsEnabled(isChecked);
          }}
        />
        <PermissionsToggleTooltip text="Add extra security to the Register by enabling permission groups. Permission groups allow you to restrict access to actions of your choosing for each of your employees on the Register." />
      </div>
      {!isEnabled && isOffMessageVisible && (
        <div className={styles.downloadChangesReminderArea}>
          Please select the {`'`}Download Changes from Slice{`'`} in your
          Register to remove these permissions. Not doing so will result in
          these permissions staying on.
        </div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PermissionsToggle;
