import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';

import useApi from 'hooks/use-api';
import {
  GetOrdersReportsResponse,
  GetReportsRequestParams,
} from 'types/financials';
import { toSnakeCase } from 'utilities/strings';

type UseOrdersReportsQueryOptions = Omit<
  UseQueryOptions<GetOrdersReportsResponse>,
  'queryFn' | 'queryKey'
>;

export const getOrdersReportsQueryKey = (
  shopId: number,
  params?: GetReportsRequestParams,
): QueryKey => [shopId, 'ordersReports', ...(params ? [params] : [])];

export const useOrdersReportsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
  options?: UseOrdersReportsQueryOptions,
): UseQueryResult<GetOrdersReportsResponse> => {
  const api = useApi();
  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    // Does not use leading "+".
    sort: `${sorting.desc ? '-' : ''}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getOrdersReportsQueryKey(shopId, params),
    queryFn: () => api.getOrdersReports(shopId, params),
    keepPreviousData: true,
    ...options,
  });
};
