import { ElementRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import { useId } from 'react-aria';
import {
  Checkbox as AriaCheckbox,
  CheckboxGroup as AriaCheckboxGroup,
  CheckboxGroupProps as AriaCheckboxGroupProps,
  CheckboxProps as AriaCheckboxProps,
} from 'react-aria-components';
import { Merge } from 'type-fest';

import { FieldChildren, type FieldProps, useField } from '../field/field';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './checkbox.css';

export type CheckboxGroupProps = Merge<
  AriaCheckboxGroupProps,
  FieldProps & {
    orientation?: 'horizontal' | 'vertical';
  }
>;

export const CheckboxGroup = forwardRef(function CheckboxGroup(
  {
    children,
    className,
    description,
    error,
    label,
    orientation = 'horizontal',
    ...props
  }: CheckboxGroupProps,
  ref: ForwardedRef<ElementRef<typeof AriaCheckboxGroup>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'checkbox-group',
  });

  return (
    <AriaCheckboxGroup
      data-orientation={orientation}
      ref={ref}
      {...fieldProps}
      {...props}
    >
      <FieldChildren {...fieldChildrenProps}>
        <div className={getFieldClassName('checkboxes')}>{children}</div>
      </FieldChildren>
    </AriaCheckboxGroup>
  );
});

export type CheckboxProps = Merge<
  Omit<AriaCheckboxProps, 'children'>,
  {
    description?: ReactNode;
    label?: ReactNode;
  }
>;

const getCheckboxClassName = getCrustClassName.bind(null, 'checkbox');

export const Checkbox = forwardRef(function Checkbox(
  { label, className, description, id, ...props }: CheckboxProps,
  ref: ForwardedRef<ElementRef<typeof AriaCheckbox>>,
) {
  /* The aria-description attribute does not have adequate support, yet, so
   * we'll use an extra element with the aria-describedby attribute to associate
   * the description with the input. */
  const descriptionId = useId(id ? `${id}-description` : undefined);
  const isIndeterminate = props.isIndeterminate;

  /* The indicator SVG is currently hard-coded and represents the default
   * circle. A future feature will allow the SVG to be customized. */
  return (
    <AriaCheckbox
      aria-describedby={description ? descriptionId : undefined}
      className={mergeAriaClassName(getCheckboxClassName(), className)}
      id={id}
      ref={ref}
      {...props}
    >
      <span aria-hidden="true" className={getCheckboxClassName('input')}>
        <svg
          className={getCheckboxClassName('indicator')}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox={isIndeterminate ? '0 0 10 2' : '0 0 10 8'}
        >
          {isIndeterminate ? (
            <path
              fill="currentColor"
              d="M.5 1c0-.552285.447715-1 1-1h7c.55229 0 1 .447715 1 1 0 .55228-.44771 1-1 1h-7c-.552285 0-1-.44772-1-1Z"
            />
          ) : (
            <path
              fill="currentColor"
              d="M3.47013 5.18555 8.60905 0 10 1.40357 4.86108 6.58913l.00724.0073L3.47737 8 0 4.49107 1.39095 3.0875l2.07918 2.09805Z"
            />
          )}
        </svg>
      </span>
      {label && <span className={getCheckboxClassName('label')}>{label}</span>}
      {description && (
        /* We render the description twice because the outer element of
         * AriaCheckbox is a <label/> and the inadequate support for
         * aria-description. We want the description text to be part of the
         * clickable area of the radio, but announced as the input description
         * and not as part of the input label. Note that elements do not need to
         * be visible for use with aria-describedby. */
        <>
          <span
            aria-hidden="true"
            className={getCheckboxClassName('description')}
          >
            {description}
          </span>
          <span hidden id={descriptionId}>
            {description}
          </span>
        </>
      )}
    </AriaCheckbox>
  );
});
