import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

export const getMenuQueryKey = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
): QueryKey => {
  const key = [Number(shopId), 'menu'];
  if (channel) {
    key.push(channel);
  }
  return key;
};

export const useMenuQuery = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
  options?: UseQueryOptions<MenuResponseBody>,
): UseQueryResult<MenuResponseBody> => {
  const api = useApi();

  return useQuery(
    getMenuQueryKey(shopId, channel),
    async () => {
      const response = await api.getMenu(Number(shopId), channel);
      return sortRelationships(response);
    },
    {
      staleTime: 60000,
      ...options,
    },
  );
};
