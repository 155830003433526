import ConsumerMenuPageHeader from 'components/menu/consumer-page-header';
import { MenuProducts } from 'components/menu/products';
import { BulkEditProvider } from 'components/menu/products/context';
import { MenuProducts as MenuProductsV0 } from 'components/menu/products-v0';
import { BulkEditProvider as BulkEditProviderV0 } from 'components/menu/products-v0/context';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useMenuProductsQuery } from 'hooks/menu/use-menu-products-query';
import { useMenuQuery } from 'hooks/menu/use-menu-query';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { viewMyMenuOnlineLinkLocations } from 'utilities/constants';
import VideoTutorials from 'utilities/video-tutorials';

export const MenuProductsPage = () => {
  const shopId = useCurrentShopId();
  const {
    isEnabled: isMenuTableRedesignEnabled,
    isLoading: isMenuTableRedesignLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.MenuTableRedesign);

  // We have two versions of menu endpoint right now and
  // these conditions will prevent from calling both endpoints
  const shouldFetchLegacyMenu =
    !isMenuTableRedesignLoading && !isMenuTableRedesignEnabled;
  const shouldFetchNewMenu =
    !isMenuTableRedesignLoading && isMenuTableRedesignEnabled;

  const {
    data: legacyMenuData,
    isError: isLegacyMenuError,
    isLoading: isLegacyMenuLoading,
  } = useMenuQuery(shopId, undefined, {
    enabled: shouldFetchLegacyMenu,
  });

  const {
    data: menuData,
    isError: isMenuError,
    isLoading: isMenuLoading,
  } = useMenuProductsQuery(shopId, undefined, {
    enabled: shouldFetchNewMenu,
  });

  // This code prevents from having `isLoading` state for the
  // endpoint that has `enabled` option set to false, because
  // we want to fetch only one endpoint based on the feature flag
  const isLegacyMenuActuallyLoading =
    shouldFetchLegacyMenu && isLegacyMenuLoading;
  const isMenuActuallyLoading = shouldFetchNewMenu && isMenuLoading;

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (
    isLegacyMenuActuallyLoading ||
    isMenuActuallyLoading ||
    isShopLoading ||
    isMenuTableRedesignLoading
  ) {
    content = <Loading />;
  } else if (isLegacyMenuError || isMenuError || isShopError) {
    content = <UnexpectedError />;
  } else if (isMenuTableRedesignEnabled && !isMenuLoading) {
    // Remove `!isMenuLoading` check here when new table design will be a permanent feature
    // Currently, we add this check only to make TypeScript happy, because it won't understand
    // that menu data exists from the `isMenuActuallyLoading` variable
    content = (
      <BulkEditProvider>
        <MenuProducts menu={menuData} shop={shop} />
      </BulkEditProvider>
    );
  } else if (!isLegacyMenuLoading) {
    // Remove `!isLegacyMenuLoading` check here when new table design will be a permanent feature
    // Currently, we add this check only to make TypeScript happy, because it won't understand
    // that menu data exists from the `isLegacyMenuActuallyLoading` variable
    content = (
      <BulkEditProviderV0>
        <MenuProductsV0 menu={legacyMenuData} shop={shop} />
      </BulkEditProviderV0>
    );
  }

  return (
    <Page
      name="menu"
      customPageHeader={
        <ConsumerMenuPageHeader
          shopId={String(shopId)}
          viewMenuOnlineLinkLocation={
            viewMyMenuOnlineLinkLocations.menuProductsPage
          }
          helpVideo={{
            modalHeader: 'How to add or edit a menu item',
            videoSrc: VideoTutorials.AddOrEditMenuItem,
            videoTitle: 'How to add or edit a menu item',
            analyticsPage: 'Item',
            analyticsVideoType: 'Menu Item',
            analyticsVideoLocation: 'Item Header',
          }}
        />
      }
      isHeaderShown
    >
      {content}
    </Page>
  );
};
