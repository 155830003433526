import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterPermissionGroup } from 'types/register-users';

export const getRegisterPermissionGroupsQueryKey = (
  shopId: string,
): QueryKey => [shopId, 'permissionGroups'];

export const useRegisterPermissionGroupsQuery = (
  shopId: string,
  options?: UseQueryOptions<RegisterPermissionGroup[]>,
): UseQueryResult<RegisterPermissionGroup[]> => {
  const api = useApi();

  return useQuery(
    getRegisterPermissionGroupsQueryKey(shopId),
    () => api.getPermissionGroups(shopId),
    { ...options },
  );
};
